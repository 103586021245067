import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Content } from '@app/models';
import { MjsJob } from "@models/mjs-job.model";

@Injectable()
export class MjsJobPreviewService {

  constructor(private http: HttpClient) { }

  get(nPostingTargetId: string): Observable<MjsJob> {
    return this.http.get<MjsJob>(`${environment.apiUrl}/api/v2/talentlink-preview?_format=json&nPostingTargetId=${nPostingTargetId}`);
  }

}
