import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { sortValues } from '@modules/search/search-params';
import {
  MjsSearchSortOptions,
  MjsSearchSortType
} from '@modules/search/models/mjs-search-params.model';

@Component({
  selector: 'mjs-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SortComponent implements OnInit {
  
  @Input() selected: MjsSearchSortType;
  
  form: FormGroup;
  options: MjsSearchSortOptions;

  constructor(private router: Router,
    private route: ActivatedRoute) {
    this.options = sortValues;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      sort: new FormControl<String>(this.selected ?? 'pd')
    });

    this.form.controls['sort'].valueChanges.subscribe((sort: MjsSearchSortType) => {
      this.router.navigate([this.router.url.split('?')[0]], {
        relativeTo: this.route,
        queryParams: { sort },
        queryParamsHandling: 'merge'
      });
    });
  }

  get f() { return this.form.controls }

}
