import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

let Quill: any = null;

@Injectable({
  providedIn: 'root'
})
export class QuillService {

  editorModules = { };

  editorStyle = {
    resize: 'vertical',
    'overflow-y': 'auto'
  };

  constructor(@Inject(PLATFORM_ID) protected platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      Quill = require('quill');
    }
  }
  
}
