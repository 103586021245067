<mjs-page-header
  [content]="content">
</mjs-page-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul>
          <li>
            <a
              routerLink="/user/alerts"
              routerLinkActive="active">
              My Searches
            </a>
          </li>
          <li>
            <a
              routerLink="/user/alert"
              routerLinkActive="active">
              Create Alert
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body | safe"></div>
      <mjs-loader *ngIf="loading"></mjs-loader>
<!--      <p><strong>Saved searches are currently unavailable while we perform some essential maintenance. Please check back later.</strong></p>-->
      <ng-container *ngIf="!loading">
        <p *ngIf="data && data.list && data.list.length === 0">You have no saved searches.</p>
        <div class="sticky-table-container" *ngIf="data && data.list && data.list.length > 0" >
          <div class="table-container">
            <table mat-table [dataSource]="data.list">
              <caption class="sr-only">Saved searches list</caption>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> <span [innerHtml]="element.name"></span> </td>
              </ng-container>
              <ng-container matColumnDef="send">
                <th mat-header-cell *matHeaderCellDef> Email Alert? </th>
                <td mat-cell *matCellDef="let element"> <span [innerHtml]="element.send"></span> </td>
              </ng-container>
              <ng-container matColumnDef="next_send">
                <th mat-header-cell *matHeaderCellDef> View Matches </th>
                <td mat-cell *matCellDef="let element">
                  <a class="btn btn-sm btn-outline-primary" [routerLink]="'/search'" [queryParams]="{token: element.token}">View</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let element">
                  <button class="btn btn-sm btn-outline-danger" *ngIf="!element.confirm" (click)="element.confirm = true">Delete</button>
                  <button class="btn btn-sm btn-primary" (click)="element.confirm = false" [hidden]="!element.confirm">Cancel</button>
                  <button class="btn btn-sm btn-danger" *ngIf="element.confirm" (click)="delete(element)">Confirm</button>
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="edit" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="text-center">  </th>
                <td mat-cell *matCellDef="let element" class="text-center"> <a class="btn btn-outline-primary btn-sm mt-2 sr-only" routerLink="/user/alert" [queryParams]="{ token: element.token }">Edit</a> </td>
              </ng-container> -->
              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            </table>
          </div>
          <div class="sticky-actions">
            <div class="btn-group" *ngFor="let element of data.list">
              <a class="btn btn-outline-primary btn-sm" routerLink="/user/alert" [queryParams]="{ token: element.token }">Edit</a>
            </div>
          </div>
        </div>
        <mjs-pager
          *ngIf="data && data.pages > 1"
          [page]="data.page"
          [pages]="data.pages"
          [total]="data.total">
        </mjs-pager>
      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-menu
        *ngIf="content && content.menu"
        [menu]="content.menu"
        [recruiter]="recruiter"
        [user]="'1'"
        [classname]="'sidebar-user-menu'">
      </mjs-menu>

      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
