import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentResolver } from '@app/services/content.resolver';
import { EducationHubHomeComponent } from './components/education-hub-home/education-hub-home.component';

const routes: Routes = [{
  path: '',
  component: EducationHubHomeComponent,
  resolve: { content: ContentResolver },
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EducationHubRoutingModule { }
