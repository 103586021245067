<mjs-page-header
  [content]="content">
</mjs-page-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul>
          <li>
            <a
              routerLink="/user/alerts"
              routerLinkActive="active">
              My Searches
            </a>
          </li>
          <li>
            <a
              routerLink="/user/alert"
              routerLinkActive="active">
              Create Alert
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body | safe"></div>
      <mjs-loader *ngIf="loading"></mjs-loader>
<!--      <p><strong>Saved searches are currently unavailable while we perform some essential maintenance. Please check back later.</strong></p>-->
      <ng-container *ngIf="!loading">
        <div class="saved-search-form border rounded" *ngIf="loaded">
          <div class="saved-search-top-form">
            <div class="form-group">
              <label for="name" class="form-label font-weight-bold">Name</label>
              <input
                [type]="'text'"
                [id]="name"
                [(ngModel)]="name"
                #ctrl="ngModel"
                class="form-control"
                placeholder="Saved Search Name"
              >
            </div>
            <fieldset>
              <legend><span class="sr-only">Select how often we email you</span></legend>
              <div class="form-group row radio-group">
                <div class="col-md-4">
                  <input type="radio" id="send_never" name="send" value="0" [(ngModel)]="send" checked="checked"><label for="send_never">Email Never</label>
                </div>
                <div class="col-md-4">
                  <input type="radio" id="send_daily" name="send" value="1" [(ngModel)]="send"><label for="send_daily">Email Daily</label>
                </div>
                <div class="col-md-4">
                  <input type="radio" id="send_weekly" name="send" value="7" [(ngModel)]="send"><label for="send_weekly">Email Weekly</label>
                </div>
              </div>
            </fieldset>
            <div class="row">
              <div class="col-md-6">
                <mjs-keywords
                  [keywords]="params.keywords.value"
                  [auto]="auto"
                  (unlockFacets)="unlock()"
                  [auto]="auto"
                  (valueChange)="updateKeywords($event)">
                </mjs-keywords>
              </div>
              <div class="col-md-6">
                <mjs-location
                  [latlng]="params.latlng.value"
                  [location]="params.location.value"
                  [distance]="params.distance.value"
                  [auto]="auto"
                  [showDistance]="showDistance"
                  (unlockFacets)="unlock()"
                  (valueChange)="updateLocation($event)">
                </mjs-location>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let elem of form | keyvalue | orderBy: 'value.weight'">
            <ng-container *ngIf="elem.value.type == 'checkbox-group'">
              <mjs-checkboxes
                [data]="elem.value"
                [params]="params"
                [defaultParams]="defaultParams"
                (valueChange)="onCheckboxChange($event)"
                (unlockFacets)="unlockFacets()"
                [static]="true">
              </mjs-checkboxes>
            </ng-container>
            <ng-container *ngIf="elem.value.type == 'boolean'">
              <mjs-boolean
                [data]="elem.value"
                [params]="params"
                [defaultParams]="defaultParams"
                (valueChange)="onBooleanChange($event)"
                (unlockFacets)="unlockFacets()">
              </mjs-boolean>
            </ng-container>
          </ng-container>
          <div class="consented">
            <input type="checkbox" id="consented" [(ngModel)]="consented">
            <label for="consented">By ticking the Daily or Weekly option I consent to receiving job alerts from myjobscotland based on the job alert criteria I have selected.</label>
          </div>
        </div>
        <button class="btn btn-primary mt-3 float-right" (click)="submit()">Save</button>
      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-menu
        *ngIf="content && content.menu"
        [menu]="content.menu"
        [recruiter]="recruiter"
        [user]="'1'"
        [classname]="'sidebar-user-menu'">
      </mjs-menu>

      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
