import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/modules/user/services/auth.service';  

@Component({
  selector: 'app-password-expiration-alert',
  template: `
    <div *ngIf="passwordExpired" class="alert alert-warning">
      Your password has expired. Please update your password.
    </div>
  `
})
export class PasswordExpirationAlertComponent implements OnInit, OnDestroy {
  passwordExpired: boolean = false;
  private subscription: Subscription;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.subscription = this.authService.getPasswordExpirationStatus().subscribe(expirationStatus => {
      this.passwordExpired = expirationStatus;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
