import { Params } from '@angular/router';
import { SavedSearch } from '@app/models/saved-search.model';

const savedSearchToSearchParams = (savedSearch: SavedSearch): Params => {
  const queryParams: Params = {};
  
  if (savedSearch.query_params) {
    for (let key in savedSearch.query_params) {
      switch (key) {
        case 'org':
        case 'parent_org':
        case 'cat':
        case 'parent_cat':
        case 'p_type':
        case 'c_type':
        case 'salary':
        case 'org_type':
        case 'employee_sector':
        case 'work_from_home':
          if (savedSearch.query_params[key] && savedSearch.query_params[key].length > 0) {
            queryParams[key] = savedSearch.query_params[key].join(',');
          }
          break;
        default:
          if (savedSearch.query_params[key] && savedSearch.query_params[key].length > 0) {
            queryParams[key] = savedSearch.query_params[key];
          }
      }
    }
  }
  
  return queryParams;
}

export { savedSearchToSearchParams }