import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class JobService {

  constructor(private http: HttpClient) {}

  list(org: number, page: number) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/jobs?org=${org}&page=${page}`)
  }

  create(data: any) {
    return this.http.post(`${environment.apiUrl}/api/v1/recruiter/job`, data);
  }

  patch(data: any, id: number) {
    return this.http.patch(`${environment.apiUrl}/api/v1/recruiter/job?id=${id}`, data);
  }

  get(org: number, id: number, prefix_fields?: boolean) {
    let url = `${environment.apiUrl}/api/v1/recruiter/job?org=${org}&id=${id}`;

    if (prefix_fields) {
      url = `${url}&prefix_fields=true`;
    }

    return this.http.get(url);
  }

  delete(org, id) {
    return this.http.delete(`${environment.apiUrl}/api/v1/recruiter/job?org=${org}&id=${id}`);
  }

}
