import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { MjsJobService } from '../../services/mjs-job.service';
import { MjsJobExtensionTokensService } from '../../services/mjs-job-extension-tokens.service';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'mjs-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  providers: [MjsJobService, MjsJobExtensionTokensService],
  encapsulation: ViewEncapsulation.None
})
export class JobsComponent implements OnInit, OnDestroy {

  params: any;
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;
  data: any;
  page:number = 1;
  ie11: boolean = false;
  formGroup: FormGroup;
  open: '-1' | '0' | '1';
  columns: string[] = [
    'id',
    'title',
    'published',
    'allow_applications',
    'search_status',
    'clicks',
    'applications',
    'category',
    'start_date',
    'end_date',
  ];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private logger: NGXLogger,
    private jobService: MjsJobService,
    private mjsJobExtensionTokenService: MjsJobExtensionTokensService) {

    this.formGroup = new FormGroup({
      open: new FormControl<'-1'|'0'|'1'>('-1'),
    });

  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
      this.page = parseInt(queryParams.get('page'), 10);
      const open = queryParams.get('open');
      let open_value = '1';

      if (open && ['-1', '0', '1'].includes(open)) {
        open_value = open;
      }

      this.formGroup.controls['open'].patchValue(open_value, {
        emitEvent: false
      });

      if (isPlatformBrowser(this.platform)) {
        this.search();
      }
    });

    this.formGroup.valueChanges.subscribe(values => {
      const queryParams = {}

      if (this?.page > 1) {
        queryParams['page'] = this.page;
      }

      if (['-1', '0', '1'].includes(values.open)){
        queryParams['open'] = values.open;
      }

      this.router.navigate([this.router.url.split('?')[0]], {
        queryParams
      });
    });
  }

  search() {
    this.loading = true;

    const { open } = this.formGroup.value;

    this.jobService.list(this.content.org.id, this.page, open)
      .pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (data: any) => {
        this.data = data;
        data.list = data.list.map(item => {
          // item['categories'] = item.categories.replaceAll('<span>', '<span class="btn btn-outline-dark btn-sm mr-1 py-0">');
          return item;
        });
        this.loading = false;
      },
      error: _ => { }
    });
  }

  toggleElementActions(element) {
    for (let i = 0; i < this.data.list.length; i += 1) {
      if (element.creation_date === this.data.list[i].creation_date) {
        this.data.list[i].active = !this.data.list[i].active;
      }
      else {
        this.data.list[i].active = false;
      }
    }
  }

  showExtensionAction(job_advert) {
    if (this.content.org.mjs_job_extension_tokens.length === 0) {
      return false;
    }

    const jobCreationDate = new Date(job_advert.creation_date);
    const now = new Date();
    const maxWeeksOld = parseInt(this.content.org.mjs_job_extension_token_max_age, 10);
    const maxAge = 604800000 * maxWeeksOld;
    const timeDiffInMs = now.getTime() - jobCreationDate.getTime();

    // If job is older than 1 week and less than the max weeks old.
    if (timeDiffInMs > 604800000 && timeDiffInMs < maxAge) {
      return true;
    }

    return false;
  }

  extendJob(job_advert) {
    this.logger.debug('job extension attempt', job_advert);
    const usedToken = this.content.org.mjs_job_extension_tokens[0];

    this.mjsJobExtensionTokenService
      .patch(this.content.org.mjs_job_extension_tokens[0], { job_advert: job_advert.actions })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: res => {
          this.logger.debug('job extension success', res);
          this.toast.success('The extension token has been applied to the job. You can now edit the job.');
          this.router.navigate([`${this.content.org.path}/jobs/${job_advert.actions}`])
        },
        error: err => {
          this.logger.debug('job extension failure', err);
          this.toast.error(err.error && err.error.error ? err.error.error : 'There was a problem applying the extension token to this job, please try again or contact support.');
        }
      });
  }

  exportJobs() {
    this.loading = true;
    const { open } = this.formGroup.value;
    this.jobService.export({
      org: this.content.org.id,
      open
    }).subscribe({
      next: data => {
        this.loading = false;
        this.downloadFile(data, 'recruiter-jobs-export');
      },
      error: e => {
        this.loading = false;
        this.toast.error('Sorry, there was a problem exporting to CSV. Please try again or contact Cosla.');
      }
    })
  }

  downloadFile(data, filename='data') {
    let csvData = this.ConvertToCSV(data, ['job_number','title','category','sub_category','organisation','position_type','start_date','end_date','clicks','applications']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    // let row = 'S.No,';
    let row = '';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      // let line = (i+1)+'';
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];

        line += (parseInt(index, 10) == 0 ? '' : ',') + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
