import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'salary'
})
export class SalaryPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    const {
      comp_override,
      comp_min,
      comp_max,
      comp_period,
      comp_post
    } = value;

    if (comp_override) {
      if (args) {
        return comp_override;
      }

      return this.ellipsis(comp_override);
    }

    let output = `${new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(comp_min).replace(/(\.|,)00$/g, '')}`;

    if (comp_min !== comp_max) {
      output += ` - ${new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(comp_max).replace(/(\.|,)00$/g, '')} per ${comp_period}`;
    } else {
      output += ` per ${comp_period}`;
    }

    if (comp_post) {
      output += ` ${comp_post}`;
    }

    if (args) {
      return output.toLowerCase();
    }

    return this.ellipsis(output.toLowerCase());
  }

  ellipsis(input): string {
    if (input.length >= 40) {
      return `${input.substring(0, 40)}...`;
    }

    return input;
  }

}
