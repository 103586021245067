<mjs-page-header *ngIf="!content || !content.job" [content]="content"></mjs-page-header>
<div class="container" *ngIf="!content || !content.job">
  <div class="row">
    <div class="col">
      <ul class="categories-list-display" *ngIf="list && list.length > 0">
        <li *ngFor="let item of list">
          <a [routerLink]="item.path">
            <span class="category-title" [innerHtml]="item.title"></span>
            <span class="category-job-count">{{ item.job_count }}</span>
          </a>
        </li>
      </ul>
    </div>
    <aside class="col sidebar col-sm-12" *ngIf="!content.full_width">
      <mjs-menu *ngIf="content && content.menu" [menu]="content.menu"
        [class]="'sidebar-menu'">
      </mjs-menu>
      <mjs-featured-jobs-widget></mjs-featured-jobs-widget>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </aside>
  </div>
</div>