<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="this.content && this.content.org">
          <li>
            <a
              [routerLink]="this.content.org.path + '/orders'"
              routerLinkActive="active">
              Orders
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/purchase'"
              routerLinkActive="active">
              Purchase
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-tokens'"
              routerLinkActive="active">
              Job Tokens
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-extension-tokens'"
              routerLinkActive="active">
              Job Extension Tokens
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>

      <ng-container *ngIf="!loading && this.content && this.content.org">

        <!-- <p>
          <strong>
            You currently have {{ content.org.tokens > 0 ? content.org.tokens : 'no' }} job token{{ content.org.tokens !== 1 ? 's' : '' }}.
          </strong>
        </p> -->

        <p class="mb-2">
          <strong>
            You currently have {{ content.org.mjs_job_tokens > 0 ? content.org.mjs_job_tokens : 'no' }} available job token{{ content.org.mjs_job_tokens !== 1 ? 's' : '' }}.
          </strong>
        </p>

        <div [innerHtml]="content.body" *ngIf="content.body"></div>

        <div *ngIf="data && data.list.length > 0">
          <div class="table-container">
            <table mat-table [dataSource]="data.list">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>

              <ng-container matColumnDef="job_advert">
                <th mat-header-cell *matHeaderCellDef> Job </th>
                <td mat-cell *matCellDef="let element">
                  <a [href]="element.job_advert_url" *ngIf="element.job_advert_url"
                    [innerHtml]="element.job_advert">
                  </a>
                  <span *ngIf="!element.job_advert">Unused</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.created|date:'d/M/yy h:mma'}} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> <span class="badge" [ngClass]="{
                  'badge-warning': !element.status,
                  'badge-success': element.status
                }">{{element.job_advert ? 'Used' : element.status ? 'Available' : 'Unavailable' }}</span> </td>
              </ng-container>

              <ng-container matColumnDef="order_id">
                <th mat-header-cell *matHeaderCellDef> Order </th>
                <td mat-cell *matCellDef="let element"> 
                  <a class="btn btn-sm btn-outline-primary" *ngIf="element.order_id && element.order_link" [routerLink]="element.order_link">View Order</a>  
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>

            </table>
          </div>
        </div>
        <mjs-pager
          *ngIf="data && data.pages > 1"
          [page]="data.page"
          [pages]="data.pages"
          [total]="data.total">
        </mjs-pager>

      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
