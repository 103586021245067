<mjs-page-header [content]="content" [size]="'small'">
</mjs-page-header>
<div class="search-header">
  <div class="secondary">
    <div class="container">
      <div class="row">
        <div class="col">
          <p role="alert" class="summary" [innerHTML]="summaryDisplay() | safe"></p>
        </div>
        <div class="col d-flex justify-content-end organisation-search-form">
          <form [formGroup]="form">
            <div class="form-group">
              <label class="sr-only" for="query">Search keywords</label>
              <input
                class="form-control"
                formControlName="query"
                id="query"
                name="control"
                placeholder="Search..."
                type="text"
              >
            </div>
            <div class="form-group">
              <label for="search-sort" class="sr-only">Sort search results</label>
              <select
                id="search-sort"
                (change)="sortChange($event.target['value'])"
                class="form-control"
                [value]="form.value.sort"
                formControlName="sort">
                <option value="count_desc">Vacancies (High - Low)</option>
                <option value="count_asc">Vacancies (Low - High)</option>
                <option value="name_asc">Name A - Z</option>
                <option value="name_desc">Name Z - A</option>
              </select>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col sidebar" *ngIf="!defaultType">
        <form
        class="organisation-filter"
        [formGroup]="type"
        *ngIf="filters">
        <fieldset>
          <legend><span class="sr-only">Filter organisations by </span>Organisation Type</legend>
          <div
            class="scroll"
            [ngClass]="{
              open: true,
              expanded: true
            }">
            <ng-container *ngFor="let item of this.filters | keyvalue">
              <div *ngIf="item.key !== '-'">
                <input
                  [id]="item.key"
                  type="checkbox"
                  [formControlName]="item.key.toString()"
                  class="sr-only"
                >
                <label
                  [for]="item.key">
                  {{ item.value }}
                </label>
              </div>
            </ng-container>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <ul
        class="organisations-list"
        [ngClass]="{ 'search': filters }"
        *ngIf="results.list.length > 0 && !loading">
        <ng-template
          ngFor let-item
          [ngForOf]="results.list"
          let-i="index">
          <li>
            <a [routerLink]="results.list[i].path">
              <div class="small-org-image">
                <img
                  [alt]="results.list[i].title"
                  defaultImage="/assets/img/defaults/default-icon.svg"
                  [errorImage]="results.list[i].logo"
                  [lazyLoad]="webp.defaultImage(results.list[i].logo)"
                >
              </div>
              <h3 [innerHtml]="results.list[i].title"></h3>
              <span>{{ results.list[i].job_count }}</span>
            </a>
          </li>
        </ng-template>
      </ul>
      <mjs-pager
        *ngIf="results.pages > 0 && !loading"
        [page]="results.page"
        [pages]="results.pages"
        [total]="results.total">
      </mjs-pager>
    </div>
  </div>
</div>

