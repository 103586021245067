<mjs-loader *ngIf="loading"></mjs-loader>
<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  *ngIf="data && !loading"
  class="entity-form">
  <ng-template ngFor let-element [ngForOf]="this.data.fields">
    <mjs-text
      *ngIf="element.type === 'string_textfield'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
      [hideValue]="element.hideValue"
    ></mjs-text>
    <mjs-email
      *ngIf="element.type === 'string_email'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-email>
    <mjs-url
      *ngIf="element.type === 'link_default'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-url>
    <mjs-password
      *ngIf="element.type === 'pass'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-password>
    <ng-container *ngIf="browser">
      <mjs-textarea
        *ngIf="element.type === 'text_textfield' || element.type === 'text_textarea_with_summary'"
        [ngClass]="element.field_name"
        [parentForm]="form"
        [element]="element"
        [submitted]="submitted"
        [errors]="f[element.field_name].errors"
        [fileUrl]="'/file/upload/' + entity + '/' + bundle + '/field_header?_format=json'"
      ></mjs-textarea>
    </ng-container>
    <mjs-number
      *ngIf="element.type === 'number'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-number>
    <mjs-select
      *ngIf="element.type === 'options_select' || element.type === 'entity_reference_autocomplete'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-select>
    <mjs-checkbox
      *ngIf="element.type === 'boolean_checkbox'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-checkbox>
    <mjs-image
      *ngIf="element.type === 'image_image'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
      [uploadUrl]="'/file/upload/' + entity + '/' + bundle + '/' + element.field_name"
      [entityType]="entity"
      [entityBundle]="bundle"
    ></mjs-image>
    <mjs-date
      *ngIf="element.type === 'datetime'"
      [ngClass]="element.field_name"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-date>
    <mjs-checkboxes
      [ngClass]="element.field_name"
      *ngIf="element.type === 'checkbox_group'"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
    ></mjs-checkboxes>
    <mjs-file
      [ngClass]="element.field_name"
      *ngIf="element.type === 'file_generic'"
      [parentForm]="form"
      [element]="element"
      [submitted]="submitted"
      [errors]="f[element.field_name].errors"
      [entityType]="entity"
      [entityBundle]="bundle"
    ></mjs-file>
  </ng-template>
  <div class="form-group my-0 border-0 text-right">
    <button class="btn btn-lg btn-primary pull-right">{{ submitLabel || 'Submit' }}</button>
  </div>
</form>
