import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mjs-recruiter-menu',
  templateUrl: './recruiter-menu.component.html',
  styleUrls: ['./recruiter-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecruiterMenuComponent {
  @Input('profile') profile: any;
}
