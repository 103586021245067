import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class FormService {

  constructor(private http: HttpClient) {}

  get(entity, bundle, edit_entity_id, edit_entity_type) {
    let url = `${environment.apiUrl}/purest/${entity}/${bundle}?`;
    const params = [];

    if (parseInt(edit_entity_id, 10)) {
      params.push(`edit_entity_id=${edit_entity_id}`);
    }
    
    if (edit_entity_type) {
      params.push(`edit_entity_type=${edit_entity_type}`);
    }

    if (params.length > 0) {
      url = url + params.join('&');
    }

    return this.http.get<any>(url);
  }

}
