import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '@app/shared/shared.module';
import { OrganisationsRoutingModule } from './organisations-routing.module';
import { OrganisationsComponent } from './organisations.component';
import { OrganisationParamsService } from './organisation-params.service';
import { OrganisationsService } from './organisations.service';

@NgModule({
  declarations: [OrganisationsComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    OrganisationsRoutingModule,
    ReactiveFormsModule,
    LazyLoadImageModule
  ],
  providers: [
    OrganisationParamsService,
    OrganisationsService
  ]
})
export class OrganisationsModule { }
