import { MjsSearchParams } from "../models/mjs-search-params.model";

export const prepareSavedSearchParams = (params: MjsSearchParams, defaultParams) => {
  const output = {};

  for (let key in params) {
    // If a default value exists for a parameter then use that.
    if (defaultParams[key]) {
      switch (params[key]['type']) {
        case 'numberArray':
        case 'stringArray':
          output[key] = [defaultParams[key]];
          break;
        default:
          output[key] = defaultParams[key];
      }
      continue;
    }

    if (params[key].value.length == 0) {
      continue;
    }

    output[key] = params[key].value;
  }

  return output;
}
