<article *ngIf="content && content.job" role="main">
  <header class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>{{ content.job.title }}</h1>
        </div>
      </div>
    </div>
  </header>
  <div class="job-actions">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <dl class="single-job-meta">
            <div>
              <dt><span>Ref no: </span></dt>
              <dd><span>{{ content.job.job_number || content.id }}</span></dd>
            </div>
            <div>
              <dt><span>Published: </span></dt>
              <dd><span>{{ content.start_date | date:'d/M/yyyy' }}</span></dd>
            </div>
            <div>
              <dt><span>Closes: </span></dt>
              <dd><span>{{ content.end_date | date:'d/M/yyyy' }}</span></dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-clean">
    <div class="container">
      <div class="row">
        <div class="col">
          <dl class="job-details">
            <div *ngIf="content.job.address">
              <dt><span>Location: </span></dt>
              <dd><span>{{ content.job.address }}{{ content.job.postcode ? ', ' + content.job.postcode : ''}}</span></dd>
            </div>
            <div>
              <dt><span>{{ content.job.comp_period == 'Year' ? 'Salary' : 'Rate' }}: </span></dt>
              <dd><span>
                {{ content.job | salary: 'full' }}
              </span></dd>
            </div>
            <div *ngIf="content.job.contract_type">
              <dt><span>Contract Type: </span></dt>
              <dd><span>{{ content.job.c_type.name }}</span></dd>
            </div>
            <div *ngIf="content.job.p_type">
              <dt><span>Position Type: </span></dt>
              <dd><span>{{ content.job.p_type.name }}</span></dd>
            </div>
            <div *ngIf="content.job.work_time_count">
              <dt><span>Hours: </span></dt>
              <dd><span>{{ content.job.work_time_count }} {{ content.job.work_time_unit.toLowerCase() }} per {{ content.job.work_time_period.toLowerCase()  }}</span></dd>
            </div>
            <div *ngIf="content.job.work_from_home > 0">
              <dt><span>Work From Home: </span></dt>
              <dd>
                <span class="text-capitalize">
                  <ng-container [ngSwitch]="content.job.work_from_home">
                    <!-- <ng-container *ngSwitchCase="0">Not available</ng-container> -->
                    <ng-container *ngSwitchCase="1">Yes</ng-container>
                    <ng-container *ngSwitchCase="2">Hybrid</ng-container>
                  </ng-container>
                </span>
              </dd>
            </div>
          </dl>
          <div class="apply d-block d-md-none">
            <button
              *ngIf="!content.apply_button.external && content.apply_button.active"
              class="btn btn-primary btn-block apply-button text-left"
              (click)="apply()"
              [disabled]="!content.apply_button.active && !content.apply_button.external">
              {{ content.apply_button.btn }}
            </button>
            <p *ngIf="content.apply_button.message">
              {{ content.apply_button.message }}
            </p>
          </div>
          <div class="job-description"
            *ngIf="content && content.body"
            [innerHTML]="content.body | safe">
          </div>
          <div *ngIf="content.files" class="job-files">
            <h2>Job Attachments</h2>
            <a *ngFor="let item of content.files"
              [href]="item.url"
              class="job-attacment-download btn d-block w-100 text-left text-primary"
              [attr.data-job]="content.job.job_number || content.id"
              [attr.data-filename]="item.filename"
              angulartics2On="click"
              angularticsAction="download"
              [angularticsLabel]="'Job Advert ID: ' + content.id + ' Document: ' + item.filename"
              angularticsCategory="Download">
              <svg aria-hidden="true" focusable="false" width="22" height="22" fill="currentColor"
                class="svg-icon mjs-file-text position-absolute start-0 top-50 translate-middle-y ms-2">
                <use xlink:href="/assets/icons/symbol-defs.svg#mjs-file-text"></use>
              </svg>

              <span class="sr-only">Download job attachment </span>{{ filename(item) }} <abbr class="filesize">[{{ item.filesize }}]</abbr>

            </a>
          </div>
          <div style="margin-bottom: 30px;" *ngIf="content.job.coords">
            <button
              *ngIf="!map"
              (click)="showMap()"
              class="btn btn-primary btn-outline">
              Show on map
            </button>
            <ng-container *ngIf="isBrowser">
              <mjs-apple-map
                *ngIf="map && content.job && content.job.coords"
                [list]="[mapObject()]">
              </mjs-apple-map>
            </ng-container>
          </div>
        </div>
        <div class="col sidebar">
          <a
            class="job-organisation-logo"
            [routerLink]="content.job.org.path"
            *ngIf="!mobile && content.job.org">
            <div class="small-org-image">
              <img
                defaultImage="/assets/img/defaults/default-icon.svg"
                [errorImage]="content.job.org.logo"
                [lazyLoad]="webp.defaultImage(content.job.org.logo)"
                [alt]="content.job.org.title"
              />
            </div>
            <h3>{{ content.job.org.title }}</h3>
          </a>
          <ul class="accreditations" *ngIf="content.job && content.job.org.accreditations">
            <li *ngFor="let item of content.job.org.accreditations">
              <img
                defaultImage="/assets/img/defaults/default-icon.svg"
                [errorImage]="item.image[0]"
                [lazyLoad]="webp.defaultImage(item.image[0])"
                [alt]="item.name"
              />
            </li>
          </ul>
          <div class="apply">
            <button
              class="btn btn-primary btn-block apply-button text-left"
              (click)="apply()"
              [disabled]="!content.apply_button.active">
              {{ content.apply_button.btn }}
            </button>
            <p *ngIf="content.apply_button.message" class="mt-1">
              {{ content.apply_button.message }}
            </p>
          </div>
          <mjs-social-share
            [title]="content.meta.title"
            [link]="content.meta.canonical_url"
            [image]="content.meta.image_src"
            [summary]="content.meta.description">
          </mjs-social-share>
          <button class="btn btn-outline-primary btn-block text-left fav"
            (click)="favourite(content.id)"
            [ngClass]="{'liked': content.user_like}">
            {{ content.user_like ? 'Unf' : 'F' }}avourite <span class="sr-only" [innerHtml]="content.job.title + ' - ' + (content.job.job_number || content.id)"></span>
            <svg aria-hidden="true" focusable="false" class="float-right" height="18" viewBox="0 0 24 24" width="18">
              <path *ngIf="!content.user_like" d="M12 15.422l3.75 2.25-0.984-4.266 3.328-2.906-4.406-0.375-1.688-4.031-1.688 4.031-4.406 0.375 3.328 2.906-0.984 4.266zM21.984 9.234l-5.438 4.734 1.641 7.031-6.188-3.75-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609z"></path>
              <path *ngIf="content.user_like" d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"></path>
            </svg>
          </button>
          <div class="job-categories">
            <a class="text-white" [routerLink]="content.job.cat.parent[0].path" [innerHtml]="content.job.cat.parent[0].title"></a>
            <a class="text-white" [routerLink]="content.job.cat.path" [innerHtml]="content.job.cat.title"></a>
          </div>
          <mjs-advert [type]="'doublempu'"></mjs-advert>
        </div>
      </div>
    </div>
  </div>
</article>

