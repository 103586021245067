<mjs-page-header
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body"></div>
      <p *ngIf="list && list.length === 0 && !loading">You currently have no favourite jobs.</p>
      <ul class="mjs-application-list" *ngIf="list && list.length > 0 && !loading">
        <ng-container *ngFor="let item of list; let i = index">
          <li class="bg-white">
            <div class="favourite-header px-3 py-2">
              <h4 class="mb-0"><a [href]="item.path">{{ item.title }}</a></h4>
              <p class="mb-0" [innerHtml]="item.organisation"></p>
            </div>
            <div class="row no-gutters favourite-header">
              <div class="col-12">
                <dl class="row no-gutters">
                  <div class="col-4 d-flex flex-column px-3 py-2 border-right">
                    <dt class="label">Job ID </dt>
                    <dd class="value"><a [href]="item.path">{{ item.job_number || item.id }}</a></dd>
                  </div>
                  <div class="col-4 d-flex flex-column px-3 py-2 border-right">
                    <dt class="label">Status </dt>
                    <dd class="value">{{ item.status }}</dd>
                  </div>
                  <div class="col-4 d-flex flex-column px-3 py-2">
                    <dt class="label">Closing </dt>
                    <dd class="value">{{ item.end_date | date:'dd/MM/yyyy' }}</dd>
                  </div>
                </dl>
              </div>
              <div class="col-12 bg-light border-top d-flex flex-column justify-content-center px-3 py-2">
                <div class="d-flex flex-row justify-content-end">
                  <ng-container *ngIf="item.apply_button">
                    <a
                      class="btn btn-sm btn-outline-primary"
                      [href]="item.apply_button.path"
                      target="_blank"
                      rel="noopener noreferer"
                      *ngIf="item.apply_button.external && item.apply_button.path">
                      {{ item.apply_button.btnText }}
                      <span class="sr-only">{{ item.title }}</span>
                      <span class="sr-only">(Link opens in a new window)</span>
                    </a>
                    <button
                      class="btn btn-sm btn-outline-primary"
                      *ngIf="!item.apply_button.external && item.apply_button.path"
                      (click)="apply(item)">
                      {{ item.apply_button.btnText }} <span class="sr-only">{{ item.title }}</span>
                  </button>
                  </ng-container>
                  <button (click)="deleteFavourite(item.id, i)"
                    class="btn btn-sm btn-outline-danger ml-2">
                    Delete <span class="sr-only">{{ item.title }} from favourites</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </main>
    <div class="col sidebar">
      <mjs-menu
        *ngIf="content && content.menu"
        [menu]="content.menu"
        [user]="'1'"
        [recruiter]="recruiter"
        [classname]="'sidebar-user-menu'">
      </mjs-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
