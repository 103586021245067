import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class LumesseProfileService {

  constructor(private http: HttpClient) {}

  get() {
    return this.http.get(`${environment.apiUrl}/api/v1/user/lumesse-profile`);
  }

  patch(data: any) {
    return this.http.patch(`${environment.apiUrl}/api/v1/user/lumesse-profile`, {
    ...data
    })
  }

}
