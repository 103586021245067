import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SearchFormService } from '@modules/search/services/search-form.service';
import { MjsSearchFilter } from '@modules/search/models/mjs-search-filters.model';

@Injectable()
export class SearchFormResolver implements Resolve<{ [index: string]: MjsSearchFilter }> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private search: SearchFormService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    // const CONTENT_KEY = makeStateKey<any>(`filters`);

    // if (this.transferState.hasKey(CONTENT_KEY)) {
    //   const filters = this.transferState.get<any>(CONTENT_KEY, null);
    //   this.transferState.remove(CONTENT_KEY);
    //   return of(filters);
    // }

    return this.search.getForm()
      // .pipe(
      //   tap(filters => {
      //     if (isPlatformServer(this.platformId)) {
      //       // this.transferState.set(CONTENT_KEY, filters);
      //     }

      //     return of(filters);
      //   })
      // );
  }

}
