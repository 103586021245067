import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Renderer2,
  PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { Subscription, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { OAuthService } from 'angular-oauth2-oidc';
import { Auth } from '@app/models';
import { FavouritesService } from '@app/modules/user/services/favourites.service';
import { ClickService } from '@app/services/click.service';
import { ApplyClickService } from '@app/services/apply-click.service';
import { environment } from '@env/environment';
import { WebpService } from '@app/services';

declare var window: any;
declare var lumesseAnalytics: any;

@Component({
  selector: 'mjs-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  providers: [FavouritesService, ClickService, WebpService, ApplyClickService],
  encapsulation: ViewEncapsulation.None
})
export class JobComponent implements OnInit, OnDestroy {

  currentAuth: Auth;
  currentAuthSubscription: Subscription;

  content: any;
  map: boolean;
  applyButton: any;
  isBrowser: boolean;
  featured: boolean;
  partnership: boolean;
  emailOnlyAccount: boolean;
  mobile: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2,
    private analytics: Angulartics2GoogleAnalytics,
    private oauthService: OAuthService,
    private toast: ToastrService,
    private favourites: FavouritesService,
    private router: Router,
    private route: ActivatedRoute,
    private clickService: ClickService,
    private applyClickService: ApplyClickService,
    public webp: WebpService) {
  }

  ngOnInit(): any {
    this.route.data.subscribe(res => {
      this.content = res['content'];
      
      if (isPlatformBrowser(this.platform) && this.oauthService.hasValidAccessToken()) {
        this.favourites.get(this.content.id).subscribe((res: any[]) => {
          if (res.length > 0) {
            this.content.user_like = true;
          }
        })
      }

      if (!this.content.job) {
        this.router.navigate(['/404']);
      } else {
        if (this.content.job.source === 'myjobscotland') {
          if(typeof lumesseAnalytics != 'undefined'){
            lumesseAnalytics.pushEvent('AdvertViewed', {
              sourceBoard: 'myjobscotland',
              advertTitle: this.content.job.title,
              advertId: `${this.content.job.lumesse_id}`,
              atsLanguageCode: 'GB',
              clientId: environment.lumesse_client_id
            });
          }
        }
      }
    });

    this.renderer.addClass(this.doc.body, 'single-job');

    if (isPlatformBrowser(this.platform)) {
      this.isBrowser = true;
    }

    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
        const jobAction = paramMap.get('job_action');
        this.featured = paramMap.get('featured') ? true : false;
        this.partnership = paramMap.get('partnership') ? true : false;
        this.emailOnlyAccount = paramMap.get('eoa') ? true : false;

        if (this.content.job) {
          let eventStr = 'Job View';

          if (this.partnership) {
            eventStr = `Partnership ${eventStr}`;
          } else if (this.featured) {
            eventStr = `Featured ${eventStr}`;
          }

          if (isPlatformBrowser(this.platform)) {
            this.analytics.eventTrack(`view`, {
              category: eventStr,
              label: this.content.job.job_number || this.content.id,
              noninteraction: true
            });

            if (this.emailOnlyAccount) {
              // GA4 event.
              window.gtag('event', 'Email Only Account Job View', {
                event_category: this.content.job.job_number || this.content.id,
              });

              this.analytics.eventTrack('view', {
                category: 'Email Only Account Job View',
                label: this.content.job.job_number || this.content.id,
                noninteraction: true
              });
            }

            this.clickService.click(this.content.id, this.featured)
              .pipe(takeUntil(this.onDestroy$)).subscribe();
          }
        }

        if (jobAction) {
          switch (jobAction) {
            case 'favourite':
              if (isPlatformBrowser(this.platform)) {
                this.favourite(this.content.id);
              }
              break;
          }
        }
      });
  }

  salaryDisplay = (): string => {
    const {
      comp_override,
      comp_min,
      comp_max,
      comp_period,
      comp_post
    } = this.content.job;

    if (comp_override) {
      return comp_override;
    }

    let output = `£${new Intl.NumberFormat('en-GB', { }).format(comp_min)}`;

    if (comp_min !== comp_max) {
      output += ` - £${new Intl.NumberFormat('en-GB', { }).format(comp_max)} per ${comp_period}`;
    }

    if (comp_post) {
      output += ` ${comp_post}`;
    }

    return output.toLowerCase();
  }

  apply(): any {
    let path = this.content.path.split('/').reverse()[0];

    this.applyClickService.click(this.content.id, this.featured)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();

    this.analytics.eventTrack('click', {
      category: 'Apply',
      label: this.content.job.job_number || this.content.id,
      noninteraction: true
    });

    if (this.emailOnlyAccount) {
      // GA4 event.
      window.gtag('event', 'Apply by Email Only Account', {
        label: this.content.job.job_number || this.content.id,
      });
      
      this.analytics.eventTrack('click', {
        category: 'Apply by Email Only Account',
        label: this.content.job.job_number || this.content.id,
        noninteraction: true
      });
    }

    let url = '';

    if (this.content.apply_button.external) {
      url = this.content.apply_button.url;
    } else {
      if (window.lumesse) {
        return window.location.href = `/rac?jobId=${environment.lumesse_technical_id}-${this.content.job.lumesse_id}`;
      } else {
        const queryParams: any = {
          jobId: `${environment.lumesse_technical_id}-${this.content.job.lumesse_id}`
        };

        return this.router.navigate(['/rac'], { queryParams });
      }
    }

    if (this.oauthService.hasValidAccessToken() && !this.content.apply_button.external) {
      url += `?token=${this.oauthService.getAccessToken()}&ngsw-bypass`;
    }

    if (url) {
      window.location.href = url;
    }
  }

  showMap(): void {
    // GA4 event.
    window.gtag('event', 'Job Map View', {
      event_category: this.content.job.job_number || this.content.id,
    });

    this.analytics.eventTrack('map_view', {
      category: `Map View`,
      label: this.content.job.job_number || this.content.id,
      noninteraction: true
    });
    
    this.map = true;
  }

  favourite(id: number): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.favourites.patch(id).subscribe({
        next: (data: any) => {
          this.content.user_like = !this.content.user_like;

          this.analytics.eventTrack(`${!this.content.user_like ? 'un' : ''}favourite`, {
            category: `${!this.content.user_like ? 'Unf' : 'F'}avourite`,
            label: this.content.job.job_number || this.content.id,
            noninteraction: true
          });

          // GA4 event.
          window.gtag('event', `${!this.content.user_like ? 'Unf' : 'F'}avourite`, {
            label: this.content.job.job_number || this.content.id,
          });

          this.toast.success(data.message)
        },
        error: err => {
          this.signInMessage();
          catchError(err);
        }
      });
    } else {
      this.signInMessage();
    }
  }

  signInMessage() {
    this.toast.error(`<a href="/user/login?redirect=${this.content.path}%3fjob_action=favourite">Sign in</a> or <a href="/user/register">create an account</a> to save jobs to your favourites.`);
  }

  downloadFile(file): void {
    this.analytics.eventTrack('download', {
      category: 'Download',
      label: `Job Advert ID: ${this.content.id} Document: ${file.filename}`,
      noninteraction: true
    });

    // GA4 event.
    window.gtag('event', 'Download', {
      label: this.content.job.job_number || this.content.id,
    });

    window.open(file.url);
  }

  filename(file) {
    const parts = file.filename.split('.');
    parts.pop();
    return parts.join();
  }

  mapObject(): any {
    return <any>{
      end_date: this.content.end_date,
      id: this.content.id,
      c_type: this.content.job.c_type.name,
      title: this.content.title,
      start_date: this.content.start_date,
      comp_period: this.content.job.comp_period,
      coords: this.content.job.coords,
      featured: this.content.job.featured,
      comp_min: this.content.job.comp_min,
      comp_max: this.content.job.comp_max,
      org_logo: this.content.job.org.logo,
      org_name: this.content.job.org.name,
      org_path: this.content.job.org.path,
      address: this.content.job.address,
      p_type: this.content.job.p_type.name,
      path: this.content.path,
      user_like: this.content.user_like,
      creation_date: this.content.job.created,
      field_area: this.content.job.area,
      comp_override: this.content.job.comp_override,
      comp_post: this.content.job.comp_post,
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.doc.body, 'single-job');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
