import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RacComponent } from './rac.component';
import { LoggedInGuard } from '@app/guards/logged-in.guard';
import { ContentResolver } from '@app/services/content.resolver';

const RAC_ROUTES: Routes = [{
  path: '',
  component: RacComponent,
  canActivate: [LoggedInGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(RAC_ROUTES)],
  exports: [RouterModule]
})
export class RacRoutingModule { }
