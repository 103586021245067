<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="order-meta" *ngIf="order">
  <div class="container">
    <div class="row">
      <div class="col">
        <ul>
          <li>
            <span>Created</span>
            {{ order.created | date: 'd/M/yy HH:mm'}}
          </li>
          <li *ngIf="order.created !== order.changed">
            <span>Updated</span>
            {{ order.changed | date: 'd/M/yy HH:mm'}}
          </li>
          <li>
            <span>Status</span>
            <em class="badge badge-success" [ngClass]="{ 'badge-success': order.status, 'badge-warning': !order.status }">{{ order.status ? 'Complete' : 'Pending Payment' }}</em>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="this.content && this.content.org">
          <li>
            <a
              [routerLink]="this.content.org.path + '/orders'"
              routerLinkActive="active">
              Orders
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/purchase'"
              routerLinkActive="active">
              Purchase
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-tokens'"
              routerLinkActive="active">
              Job Tokens
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-extension-tokens'"
              routerLinkActive="active">
              Job Extension Tokens
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <!-- <p>Purchasing tokens is not possible due to a system upgrade, please bear with us.</p> -->
      <mjs-loader *ngIf="loading" [message]="loadingMessage"></mjs-loader>

      <div class="table-container order-items">
        <table class="mat-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="order.mjs_job_tokens && order.mjs_job_tokens.quantity">
              <td>Job Token</td>
              <td>{{ order.mjs_job_tokens.quantity }}</td>
              <td>{{ displayPrice(order.mjs_job_tokens.unit_price) }}</td>
            </tr>
            <tr *ngIf="order.mjs_job_extension_tokens && order.mjs_job_extension_tokens.quantity">
              <td>Job Extension Token</td>
              <td>{{ order.mjs_job_extension_tokens.quantity }}</td>
              <td>{{ displayPrice(order.mjs_job_extension_tokens.unit_price) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-container order-totals">
        <table class="mat-table">
          <thead></thead>
          <tbody>
            <tr>
              <th scope="row">Sub Total</th>
              <td>{{ displayPrice(order.sub_total) }}</td>
            </tr>
            <tr>
              <th scope="row">VAT</th>
              <td>{{ displayPrice(order.vat) }}</td>
            </tr>
            <tr>
              <th scope="row">Total</th>
              <td>{{ displayPrice(order.total) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="order && !order.status" class="order-actions" [hidden]="loading">
        <button [hidden]="cardPayment" class="btn btn-outline-primary float-left px-5" (click)="deleteOrder()">Cancel Order</button>
        <ng-container *ngIf="payPalConfig">
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </ng-container>
      </div>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
