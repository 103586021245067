<div class="form-group custom-control custom-checkbox" *ngIf="element" [formGroup]="parentForm"> 
  <label>{{ element.label }}</label>
  <span class="checkbox-label off-label" *ngIf="element.settings.off_label">{{ element.settings.off_label }}</span>
  <input
    class="form-check-input"
    type="checkbox"
    [id]="element.field_name"
    [formControlName]="element.field_name">
  <label
    [for]="element.field_name"
    class="form-check-label">
    <div class="sr-only">{{ element.label }}</div>
    <span></span>
  </label>
  <span class="checkbox-label on-label" *ngIf="element.settings.on_label">{{ element.settings.on_label }}</span>
  <div *ngIf="submitted && errors" class="invalid-feedback" role="alert">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div>
</div>