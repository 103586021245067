<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
<div class="row">
  <main id="main-content" class="col legacy-paypal-buy-component">
<!--    <p><strong>Sorry, the purchasing of tokens is currently unavailable. Please try again later.</strong></p>-->
    <p class="text-center" *ngIf="loadingMessage"><strong [innerHtml]="loadingMessage"></strong></p>
    <mjs-loader *ngIf="loading"></mjs-loader>
    <div [hidden]="loading">
      <ng-container *ngIf="payPalConfig">
        <div class="table-container order-items">
          <table class="mat-mdc-table mdc-data-table__table cdk-table" role="table">
            <thead role="rowgroup">
            <tr role="row" class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row ng-star-inserted">
              <th role="columnheader" class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-name mat-column-name ng-star-inserted"> Product </th>
              <th role="columnheader" class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-quantity mat-column-quantity ng-star-inserted"> Quantity </th>
              <th role="columnheader" class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-unit_amount mat-column-unit_amount ng-star-inserted"> Price </th>
            </tr>
            </thead>
            <tbody role="rowgroup" class="mdc-data-table__content">
            <tr role="row" class="mat-mdc-row mdc-data-table__row cdk-row ng-star-inserted">
              <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-name mat-column-name ng-star-inserted" role="cell"><span>Job Token</span></td>
              <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-quantity mat-column-quantity ng-star-inserted" role="cell"><span>{{ quantity }}</span></td>
              <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-unit_amount mat-column-unit_amount ng-star-inserted" role="cell"><span> {{ itemPrice() }}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-container order-totals" *ngIf="paypalOrder">
          <table class="mat-mdc-table mdc-data-table__table cdk-table" role="table">
            <thead role="rowgroup" class="visually-hidden d-none">
              <tr role="row" class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
                <th role="columnheader" class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-name mat-column-name"> Type </th>
                <th role="columnheader" class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell cdk-column-value mat-column-value"> Price </th>
              </tr>
            </thead>
            <tbody role="rowgroup" class="mdc-data-table__content">
            <tr role="row" class="mat-mdc-row mdc-data-table__row cdk-row">
              <th scope="row"><span>Sub Total</span></th>
              <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-value mat-column-value" role="cell"><span> £{{ paypalOrder.sub_total }}</span></td>
            </tr>
            <tr role="row" class="mat-mdc-row mdc-data-table__row cdk-row">
              <th scope="row"><span>VAT [20%]</span></th>
              <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-value mat-column-value" role="cell"><span> £{{ paypalOrder.tax }}</span></td>
            </tr>
            <tr role="row" class="mat-mdc-row mdc-data-table__row cdk-row">
              <th scope="row"><span>Total</span></th>
              <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk-column-value mat-column-value" role="cell"><span> £{{ paypalOrder.total }}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
<!--        <div *ngIf="!paypalOrder" class="alert alert-info mt-3 mb-3 text-center">-->
<!--          <p class="m-0"><strong>Please select a payment method below.</strong></p>-->
<!--        </div>-->
        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
      </ng-container>
    </div>
    <ng-container *ngIf="!loading">
      <div [hidden]="payPalConfig || paypalOrder">
        <div [innerHtml]="content.body" *ngIf="content.body"></div>
        <mjs-entity-form
          [formData]="formData"
          [params]="params"
          (valueChanges)="valueChanges($event)"
          (submitForm)="submit($event)"
        ></mjs-entity-form>
      </div>
    </ng-container>
  </main>
  <div class="col sidebar">
    <mjs-recruiter-menu
      [profile]="content"
    ></mjs-recruiter-menu>
    <mjs-advert [type]="'doublempu'"></mjs-advert>
  </div>
</div>
