<form *ngIf="init" [formGroup]="form">
  <div class="position-relative w-100 mb-2 overflow-hidden mjs-boolean-filter bg-white"
    *ngFor="let item of data.options | keyvalue">
    <input [id]="data.name + '__' + item.value['id']"
           class="position-absolute"
           type="checkbox"
           [formControlName]="data.name + '__' + item.value['id']"
    >
    <label [for]="data.name + '__' + item.value['id']"
           class="mjs-boolean-button btn btn-outline-primary d-block w-100 text-left bg-transparent position-relative">
      {{ item.value['name'] }}
    </label>
  </div>
</form>
