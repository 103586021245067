import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecruiterResolver } from '@app/services/recruiter.resolver';
import { RecruiterComponent } from './recruiter.component';
import { RecruiterGuard } from '@app/guards/recruiter.guard';
import {
  CreateComponent,
  ClaimComponent,
  EditComponent,
  JobsComponent,
  UsersComponent,
  UserComponent,
  JobComponent,
  TokensComponent,
  TokenComponent,
  BuyComponent
} from './components';

import { MjsOrdersComponent } from './components/mjs-orders/mjs-orders.component';
import { MjsJobTokensComponent } from './components/mjs-job-tokens/mjs-job-tokens.component';
import { MjsJobExtensionTokensComponent } from './components/mjs-job-extension-tokens/mjs-job-extension-tokens.component';
import { MjsOrderComponent } from './components/mjs-order/mjs-order.component';
import { MjsPurchaseComponent } from './components/mjs-purchase/mjs-purchase.component';
import { NotFoundComponent } from '@app/shared';
import {MjsPositionsComponent} from "@modules/recruiter/components/positions/mjs-positions.component";
import {MjsPositionComponent} from "@modules/recruiter/components/position/mjs-position.component";

const RECRUITER_ROUTES: Routes = [{
  path: '',
  component: RecruiterComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver }
},{
  path: 'create-organisation',
  component: CreateComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: 'claim-organisation',
  component: ClaimComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation',
  component: EditComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  data: {
    entityType: 'taxonomy_term',
    entityBundle: 'organisation'
  },
  runGuardsAndResolvers: 'always'
},{
  path: ':organisation/jobs',
  component: JobsComponent,
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/jobs/:job',
  component: JobComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/users',
  component: UsersComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/users/:user',
  component: UserComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/pages',
  component: RecruiterComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
}, {
  path: ':organisation/pages/:page',
  component: EditComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  data: {
    entityType: 'node',
    entityBundle: 'organisation'
  },
  runGuardsAndResolvers: 'always'
}, {
  path: ':organisation/positions',
  component: MjsPositionsComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
}, {
  path: ':organisation/positions/:position',
  component: MjsPositionComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  data: {
    entityType: 'mjs_position',
    entityBundle: 'default'
  },
  runGuardsAndResolvers: 'always'
}, {
  path: ':organisation/job-tokens',
  component: TokensComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/job-tokens/:token_id',
  component: TokenComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/purchase-tokens',
  component: BuyComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/purchase',
  component: MjsPurchaseComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/orders',
  component: MjsOrdersComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/orders/:order',
  component: MjsOrderComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/mjs-job-tokens',
  component: MjsJobTokensComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':organisation/mjs-job-extension-tokens',
  component: MjsJobExtensionTokensComponent,
  canActivate: [RecruiterGuard],
  resolve: { content: RecruiterResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
}];

@NgModule({
  imports: [RouterModule.forChild(RECRUITER_ROUTES)],
  exports: [RouterModule]
})
export class RecruiterRoutingModule { }
