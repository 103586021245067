import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { SearchesService } from '@app/modules/user/services';

@Component({
  selector: 'mjs-searches',
  templateUrl: './searches.component.html',
  styleUrls: ['./searches.component.scss'],
  providers: [SearchesService],
  encapsulation: ViewEncapsulation.None
})
export class SearchesComponent implements OnInit, OnDestroy {

  content: any;
  data: any;
  submitted: boolean;
  loading: boolean;
  columns = ['name', 'send', 'delete', 'next_send'];
  profile: any;
  recruiter: boolean;
  page: number;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private searchesService: SearchesService,
    private oauthService: OAuthService) {
    this.page = 1;
  }

  ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    // this.loading = true;

    if (isPlatformBrowser(this.platform)) {
      const profile = <any>this.oauthService.getIdentityClaims();

      if (profile.admin_menu) {
        this.recruiter = true;
      }

      // this.getSavedSearches();
      this.init();
    }
  }

  init() {
    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((queryParams: ParamMap) => {
        const tokenAction = queryParams.get('token_action');
        const token = queryParams.get('token');
        this.page = parseInt(queryParams.get('page'), 10) || 1;

        if (isPlatformBrowser(this.platform)) {
          this.getSavedSearches();
        }

        if (token && tokenAction) {
          let index = -1;

          for (let i = 0; i < this.data.list.length; i += 1) {
            if (token === this.data.list[i].token) {
              index = i;
            }
          }

          if (index > -1) {
            switch (tokenAction) {
              case 'delete':
                this.delete(this.data.list[index]);
                break;
            }
          }
        }
      });
  }

  getSavedSearches(): void {
    this.loading = true;
    this.searchesService
      .get(this.page)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: data => {
          this.loading = false;
          this.data = data;
          // this.init();
        },
        error: err => {
          this.loading = false;
          this.toast.error(err);
        }
      });
  }

  delete(element) {
    element.confirm = false;

    this.searchesService
      .delete(element.token)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: data => {
          this.toast.success('Your search has been deleted');
          this.getSavedSearches();
        },
        error: err => {
          this.loading = false;
          this.toast.error(err.error && err.error.message ? err.error.message : 'Soemthing went wrong');
        }
      });
  }

  confirm(element): void {
    element.confirm = true;
  }

  cancel(element): void {
    element.confirm = false;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
