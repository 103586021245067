<ul class="case-studies row list-style-none">
  <li *ngFor="let item of list" class="col-md-6 link-style-none">
    <a [routerLink]="item.path">
      <figure>
        <img [src]="item.field_header.styles.header.url" alt="">
      </figure>
      <h3><span>{{ item.title }}</span></h3>
    </a>
  </li>
</ul>
