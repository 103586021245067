import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Content } from '@app/models';

@Component({
  selector: 'mjs-recruiter',
  templateUrl: './recruiter.component.html',
  styleUrls: ['./recruiter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecruiterComponent implements OnInit {
  
  content: Content;
  profile: any;
  loading: boolean;

  constructor(private route: ActivatedRoute) {
    this.loading = false;
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });
  }

}
