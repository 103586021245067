<div class="search-header">
  <div class="secondary">
    <div [ngClass]="{ 'container': !map, 'container-fluid': map }">
      <div class="row">
        <div class="col d-flex flex-row justify-content-between no-clear">
          <p role="status" class="summary" [innerHTML]="summaryDisplay() | safe"></p>
          <div class="d-flex" *ngIf="isBrowser">
            <div class="search-options d-flex flex-row" *ngIf="!isMobile" [attr.aria-hidden]="isMobile ? 'true' : 'false'">
              <mjs-keywords
                [keywords]="params.keywords.value"
                [auto]="auto"
                (unlockFacets)="unlock()">
              </mjs-keywords>
              <mjs-location
                [latlng]="params.latlng.value"
                [location]="params.location.value"
                [distance]="params.distance.value ? params.distance.value.toString() : ''"
                [auto]="auto"
                (unlockFacets)="unlock()">
              </mjs-location>
              <mjs-sort [selected]="params.sort.value"></mjs-sort>
            </div>
            <button class="search-map-toggle btn btn-outline-primary btn-sm" (click)="switchMap()">
              <ng-container *ngIf="map">
                <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                List
              </ng-container>
              <ng-container *ngIf="!map">
                <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon><line x1="8" y1="2" x2="8" y2="18"></line><line x1="16" y1="6" x2="16" y2="22"></line></svg>
                Map
              </ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

