<div class="p-3 mx-auto">
  <h3 class="mt-0 text-left">Log in</h3>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="text-left">
    <div class="form-group">
      <label for="sidebar-login-user" class="font-weight-bold form-label">Email address</label>
      <input
        placeholder="Email address"
        type="email"
        formControlName="username"
        id="sidebar-login-user"
        [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
        class="form-control"
      >
      <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
          <div *ngIf="f['username'].errors['required']">Email is required</div>
          <div *ngIf="f['username'].errors['email']">Email must be a valid email address</div>
      </div>
    </div>
    <div class="form-group">
      <label for="sidebar-login-pass" class="font-weight-bold form-label">Password</label>
      <input
        placeholder="Password"
        type="password"
        formControlName="password"
        id="sidebar-login-pass"
        [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
        class="form-control"
      >
      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
          <div *ngIf="f['password'].errors['required']">Password is required</div>
      </div>
    </div>
    <div class="form-group my-0">
      <button class="btn btn-primary btn-block">Log in</button>
    </div>
  </form>
  <p class="text-center my-2">or</p>
  <a routerLink="/user/register" [queryParams]="{'redirect': redirectParam}" class="btn btn-outline-primary btn-block">Create account</a>
  <p class="text-center mt-3 mb-2"><a routerLink="/user/reset">Reset password</a></p>
  <div class="advertiser-register-link">
    <p class="text-center my-2 font-weight-bold"><a class="text-pink" routerLink="/i-am-advertiser">Advertiser Information</a></p>
  </div>
</div>

