import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LumesseProfileService } from '@app/modules/user/services';
import { OAuthService } from 'angular-oauth2-oidc';
import { LumesseService } from "@app/services/lumesse.service";

@Component({
  selector: 'mjs-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [LumesseProfileService],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit, OnDestroy {

  content: any;
  profile: any;
  lumesse_profile: any;
  params: any;
  loading: boolean;
  recruiter: boolean;
  non_lumesse: boolean;
  lumesseStatus: any;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private lumesse: LumesseProfileService,
    private toast: ToastrService,
    private oauthService: OAuthService,
    private lumesseStatusService: LumesseService) {}

  public ngOnInit(): void {
    this.loading = true;
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    if (isPlatformBrowser(this.platform)) {
      this.lumesseStatusService.currentValue.subscribe(res => this.lumesseStatus = res);
      const profile = <any>this.oauthService.getIdentityClaims();

      if (profile.admin_menu) {
        this.recruiter = true;
      }

      this.lumesse.get().pipe(takeUntil(this.onDestroy$)).subscribe(
        (data: any) => {
          if (data) {
            this.profile = data;
          }
          else {
            this.non_lumesse = true;
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
          this.toast.error(err && err.error && err.error.message ? err.error.message : 'Sorry something went wrong');
        }
      );
    }
  }

  get lumesseDisabled(): string {
    if (!this.lumesseStatus || this.lumesseStatus.lumesse_disable) {
      return 'Your profile is currently unavailable due to problems communicating with the lumesse server.';
    }

    return '';
  }

  public submit = (values: any): void => {
    this.loading = true;

    // Please forgive the below. The objects that the lumesse soap apis return
    // hurt my soul and I have to get this working right now.
    for (let i = 0; i < values.structure.fields.length; i += 1) {
      if (values.structure.fields[i].type === 'options_select') {
        let realValue = values.structure.fields[i].settings.allowed_values[values.params[values.structure.fields[i].field_name]];

        let childKey = `${values.structure.fields[i].field_name}__child`;
        if (values.structure.fields[i].settings.child_values && values.structure.fields[i].settings.child_values[values.params[values.structure.fields[i].field_name]]) {
          realValue = `${realValue},${values.structure.fields[i].settings.child_values[values.params[values.structure.fields[i].field_name]][values.params[childKey]]}`;
          delete values.params[childKey];
          values.params[values.structure.fields[i].field_name] = realValue;
        }
      }
    }
    this.lumesse.patch(values.params)
      .pipe(takeUntil(this.onDestroy$)).subscribe(
      (data: any) => {
        this.oauthService.refreshToken().then(res => {
          this.oauthService.loadUserProfile().then(res => {
            this.toast.success('Your personal information has been updated');
            this.loading = false;
          })
        });
      },
      err => {
        this.loading = false;
        this.toast.error(err.error.join(', '));
      }
    )
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
