<mjs-page-header
  [content]="content">
</mjs-page-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul>
          <li>
            <a
              routerLink="/user/applications"
              routerLinkActive="active">
              Submitted Applications
              <span class="sr-only">Applications</span>
            </a>
          </li>
          <li>
            <a
              routerLink="/user/incomplete-expired-applications"
              routerLinkActive="active">
              Incomplete / Expired
              <span class="sr-only">Applications</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <p *ngIf="!loading && lumesseDisabled"><strong [innerHtml]="lumesseDisabled"></strong></p>
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body"></div>
<!--      <div *ngIf="statuses">-->
<!--        <form [formGroup]="formGroup">-->
<!--          <div class="row justify-content-end">-->
<!--            <div class="col col-12 col-sm-4">-->
<!--              <div class="form-group">-->
<!--                <label class="font-weight-bold" for="open">Status</label>-->
<!--                <select id="open"-->
<!--                        class="form-control"-->
<!--                        formControlName="status">-->
<!--                  <option value="">&#45;&#45; Any &#45;&#45;</option>-->
<!--                  <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </form>-->
<!--      </div>-->
      <ul
        class="mjs-application-list"
        *ngIf="!incomplete && filteredApplications && filteredApplications.length > 0">
        <li class="bg-white" *ngFor="let item of filteredApplications">
          <div class="favourite-header px-3 py-2">
            <h4 class="mb-0"><a [routerLink]="item.url">{{ item.jobAdTitle }}</a></h4>
            <p class="mb-0" [innerHtml]="item.organisation"></p>
          </div>
          <div class="row no-gutters favourite-header">
            <div class="col-12">
              <dl class="row no-gutters">
                <div class="col-4 d-flex flex-column px-3 py-2 border-right">
                  <dt class="label">Job ID </dt>
                  <dd class="value">{{ item.jobNumber }}</dd>
                </div>
                <div class="col-4 d-flex flex-column px-3 py-2 border-right">
                  <dt class="label">Status</dt>
                  <dd class="value">{{ displayStatus(item) }}</dd>
                </div>
                <div class="col-4 d-flex flex-column px-3 py-2">
                  <dt class="label">Updated</dt>
                  <dd class="value">
                    <time *ngIf="item.updateDate" [attr.datetime]="item.updateDate">{{ item.updateDate | date: 'dd/MM/yyyy h:mm a' : 'UTC' }}</time>
                    <span *ngIf="!item.updateDate">-</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div class="col-12 bg-light border-top d-flex flex-column justify-content-center px-3 py-2">
            <div class="d-flex flex-row justify-content-end">
              <button
                *ngIf="item.status !== 'Withdrawn'"
                (click)="deleteApplication(item)"
                class="btn btn-sm btn-outline-danger ml-1">
                Withdraw <span class="sr-only" [innerHtml]="'application for ' + item.jobAdTitle + ' - ' + item.jobNumber"></span>
              </button>
              <button
                *ngIf="item.status == 'Withdrawn'"
                class="btn btn-sm btn-outline-primary ml-1"
                disabled>
                Withdrawn
              </button>
              <button
                class="btn btn-sm btn-outline-primary ml-1"
                data-docs="1767446"
                (click)="viewDocuments(item)"
                *ngIf="item.documents.length > 0 && item.documents[0].id">
                Documents <span class="sr-only" [innerHtml]="'Related documents for application for ' + item.jobAdTitle + ' - ' + item.jobNumber"></span>
              </button>
            </div>
          </div>
          <div class="document-list px-3 py-2" *ngIf="item.viewDocs">
            <ul>
              <li *ngFor="let item of item.documents" class="mb-1">
                <button
                  download="HGH07470-Ed-JPS.pdf"
                  (click)="viewDocument(item)">
                  <ng-container>
                    <svg aria-hidden="true" focusable="false" height="100%" viewBox="0 0 1024 1024" width="100%">
                      <path d="M917.806 229.076c-22.212-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.888 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.984 17.78 50.678 41.878 81.374 72.572zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.326 32 32 32h224v624zM736 832h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32zM736 704h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32zM736 576h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z" stroke="inherit"></path>
                    </svg>
                    <span class="sr-only">Download job attachment </span>{{ item.name || item.description }}
                  </ng-container>
                </button>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <ul
        class="mjs-application-list"
        *ngIf="incomplete && applications && applications.saved_applications && applications.saved_applications.length > 0">
        <li class="bg-white" *ngFor="let item of applications.saved_applications">
          <div class="favourite-header px-3 py-2">
            <h4 class="mb-0"><a [routerLink]="item.url">{{ item.jobAdTitle }}</a></h4>
            <p class="mb-0" [innerHtml]="item.organisation"></p>
          </div>
          <div class="row no-gutters favourite-header">
            <div class="col-12">
              <dl class="row no-gutters">
                <div class="col-4 d-flex flex-column px-3 py-2 border-right">
                  <dt class="label">Job ID</dt>
                  <dd class="value"><a [href]="item.url">{{ item.jobNumber }}</a></dd>
                </div>
                <div class="col-4 d-flex flex-column px-3 py-2 border-right">
                  <dt class="label">Job Status</dt>
                  <dd class="value">{{ item.postingStatus }}</dd>
                </div>
                <div class="col-4 d-flex flex-column px-3 py-2">
                  <dt class="label">Last Saved</dt>
                  <dd class="value">{{ item.lastSaved | date: 'dd/MM/yyyy h:mm a' : 'UTC' }}</dd>
                </div>
              </dl>
            </div>
          </div>
          <div class="col-12 bg-light border-top d-flex flex-column justify-content-center px-3 py-2">
            <div class="d-flex flex-row justify-content-end">
              <button
                *ngIf="item.postingStatus === 'Unposted'"
                class="btn btn-sm btn-outline-primary ml-1"
                (click)="unpostedAlert()">
                Information
              </button>
              <a
                *ngIf="showResume(item)"
                [href]="resumePath(item)"
                target="_self"
                class="btn btn-sm btn-outline-primary ml-1">
                Continue <span class="sr-only" [innerHtml]="'application for ' + item.jobAdTitle + ' - ' + item.jobNumber"></span>
              </a>
              <button
                *ngIf="item.postingStatus === 'Unposted' && item.postingStatus === 'Available'"
                class="btn btn-sm btn-outline-primary ml-1">
                Unavailable
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-danger ml-1"
                (click)="deleteApplication(item)">
                Delete <span class="sr-only" [innerHtml]="'application for ' + item.jobAdTitle + ' - ' + item.jobNumber"></span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </main>
    <div class="col sidebar">
      <mjs-menu
        *ngIf="content && content.menu"
        [menu]="content.menu"
        [recruiter]="recruiter"
        [user]="'1'"
        [classname]="'sidebar-user-menu'">
      </mjs-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>

