import { Component, ViewEncapsulation } from '@angular/core';
import { Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { MjsPositionsService } from "@modules/recruiter/services/mjs-positions.service";
import { UploadCSVDialogData } from "@modules/recruiter/components/positions/mjs-positions.component";

@Component({
  selector: 'mjs-upload-positions-csvdialog',
  templateUrl: './upload-positions-csvdialog.component.html',
  styleUrls: ['./upload-positions-csvdialog.component.scss'],
  providers: [MjsPositionsService],
  encapsulation: ViewEncapsulation.None
})
export class UploadPositionsCSVDialogComponent implements OnInit {

  formGroup: FormGroup;
  loading: boolean;
  submitted: boolean;
  error: string;
  file: any;
  mimetype: any;
  filedata: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: UploadCSVDialogData,
    public dialogRef: MatDialogRef<UploadPositionsCSVDialogComponent>,
    public dialog: MatDialog,
    private toast: ToastrService,
    private mjsPositionsService: MjsPositionsService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      file: new FormControl<any>('', [Validators.required]),
    });
  }

  get f() { return this.formGroup['controls'] }

  onChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.filedata = reader.result;
        this.file = event.target.files[0];
        this.mimetype = this.file.type;
      }
    }
  }

  submit(): void {
    this.submitted = true;

    if (this.formGroup.status == 'INVALID') {
      return;
    }

    this.loading = true;

    this.mjsPositionsService.uploadCsv({
      uri: 'positions-csv',
      filename: this.file.name,
      filemime: this.mimetype,
      data: this.filedata,
    }, this.data.org).subscribe({
      next: res => {
        this.dialogRef.close();
        if (res['error']) {
          this.toast.error(res['error']);
        }
        if (res['message']) {
          this.toast.success(res['message']);
        }
        this.loading = false;
      },
      error: err => {
        this.toast.error(err['error']['error'] || 'Sorry, something went wrong');
        this.loading= false;
      }
    });
  }

}
