import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mjs-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;
}
