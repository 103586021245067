<form [formGroup]="formGroup" (submit)="submit()">
  <div mat-dialog-title class="shadow-sm mb-0 p-3 d-block overflow-hidden" style="min-height:auto;">
    <h2 class="mjs-heading mb-0">Upload CSV</h2>
  </div>
  <div mat-dialog-content class="pt-3 px-3">
    <mjs-loader *ngIf="loading"></mjs-loader>
    <div [hidden]="loading">
      <p class="mb-3"><a href="/assets/files/test-positions.csv" target="_blank">Click here</a> to download an example CSV file. Your CSV file must match the format exactly in order to work.</p>
      <div class="input-group mb-3">
        <div class="custom-file">
          <input (change)="onChange($event)"
            type="file"
            class="custom-file-input"
            id="inputGroupFile01"
            formControlName="file"
            aria-describedby="inputGroupFileAddon01"
            [ngClass]="{
              'is-invalid': submitted && f['file'].errors
            }"
          >
          <label class="custom-file-label" for="inputGroupFile01">{{ file && file.name ? file.name : 'Choose CSV file' }}</label>
          <div class="text-danger pt-1 mb-3" *ngIf="submitted && f['file'].errors">CSV file is required</div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex shadow border-top justify-content-between bg-light">
    <button [disabled]="loading" type="button" class="btn btn-sm btn-dark float-left" matDialogClose>Cancel</button>
    <button [disabled]="loading" type="submit" class="btn btn-sm btn-primary float-right">Submit</button>
  </div>
</form>
