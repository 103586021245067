import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { MjsPositionsService } from "@modules/recruiter/services/mjs-positions.service";
import { UploadPositionsCSVDialogComponent } from "@modules/recruiter/components/upload-positions-csvdialog/upload-positions-csvdialog.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "@env/environment";
import {MjsSearchParams} from "@modules/search/models";
import {FormControl, FormGroup} from "@angular/forms";

export interface UploadCSVDialogData {
  org: number;
}

@Component({
  selector: 'mjs-jobs',
  templateUrl: './mjs-positions.component.html',
  styleUrls: ['./mjs-positions.component.scss'],
  providers: [MjsPositionsService],
  encapsulation: ViewEncapsulation.None
})
export class MjsPositionsComponent implements OnInit, OnDestroy {

  params: any;
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;
  data: any;
  page:number = 1;
  keywords: string = '';
  ie11: boolean = false;
  columns: string[] = [
    'id',
    'title',
    'status',
    'end_date',
    'area',
    'postcode',
    'rate',
    'hours',
    'clicks',
  ];
  formGroup: FormGroup;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toast: ToastrService,
    private logger: NGXLogger,
    private mjsPositionsService: MjsPositionsService) {
    this.formGroup = new FormGroup<any>({
      keywords: new FormControl<string>(this.keywords, []),
    })
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];

      if (!this.content.org.positions) {
        this.toast.error('You do not have permission to access positions');
        this.router.navigate([this.content.org.path]);
      }
    });

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
      this.page = parseInt(queryParams.get('page'), 10);
      this.keywords = queryParams.get('keywords');

      this.formGroup.get('keywords').setValue(this.keywords);

      if (isPlatformBrowser(this.platform)) {
        this.search();
      }
    });
  }

  search() {
    this.loading = true;

    this.mjsPositionsService.list(this.content.org.id, this.page, this.keywords)
      .pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (data: any) => {
        this.data = data;
        this.loading = false;
      },
      error: _ => { }
    });
  }

  submit() {
    this.router.navigate([this.content.org.path + '/positions'], {
      queryParams: {
        keywords: this.formGroup.get('keywords').value,
      }
    });
  }

  toggleElementActions(element) {
    for (let i = 0; i < this.data.list.length; i += 1) {
      if (element.creation_date === this.data.list[i].creation_date) {
        this.data.list[i].active = !this.data.list[i].active;
      }
      else {
        this.data.list[i].active = false;
      }
    }
  }

  exportJobs() {
    this.loading = true;
    this.mjsPositionsService.export({
      org: this.content.org.id,
    }).subscribe({
      next: data => {
        this.loading = false;
        this.downloadFile(data, 'recruiter-jobs-export');
      },
      error: e => {
        this.loading = false;
        this.toast.error('Sorry, there was a problem exporting to CSV. Please try again or contact Cosla.');
      }
    })
  }

  downloadFile(data, filename='data') {
    let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  csvLink() {
    this.mjsPositionsService.csv(this.content.org.id).subscribe({
      next: res => {
        this.downloadFile(res, 'recruiter-positions.csv');
      },
      error: err => {
        this.toast.error('Sorry something went wrong.');
      }
    });
  }

  deletePosition(id) {
    if (confirm('Are you sure you want to delete this position? This action cannot be undone.')) {
      this.loading = true;
      this.mjsPositionsService.delete(id)
        .pipe(takeUntil(this.onDestroy$)).subscribe({
          next: data => {
            this.router.navigate([`${this.content.org.path}/positions`], {
              queryParams: {
                message: 'position_deleted'
              }
            })
          },
          error: err => {
            this.loading = false;
            this.toast.error(err.error.error)
          }
        },
      );
    }
  }

  uploadCsv() {
    const dialogRef = this.dialog.open(UploadPositionsCSVDialogComponent, {
      panelClass: 'recruiter-upload-positions-csv-dialog',
      data: {
        org: this.content.org.id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.search();
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
