import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CareerHubRoutingModule } from './career-hub-routing.module';
import { CareerHubComponent } from './career-hub.component';
import { SharedModule } from '@app/shared/shared.module';
import { CareerHubHomeComponent } from './components/career-hub-home/career-hub-home.component';
import { CareerHubPageComponent } from './components/career-hub-page/career-hub-page.component';
import { CareerHubArticleComponent } from './components/career-hub-article/career-hub-article.component';
import { CareerHubHeaderComponent } from './components/career-hub-header/career-hub-header.component';
import { JourneysResolver } from './services/journeys.resolver';
import { CareerHubService } from './services/career-hub.service';
import { CareerHubArticlesComponent } from './components/career-hub-articles/career-hub-articles.component';
import { PageGridComponent } from './components/page-grid/page-grid.component';
import { CareerHubSearchComponent } from './components/career-hub-search/career-hub-search.component';

@NgModule({
  declarations: [
    CareerHubComponent,
    CareerHubHomeComponent,
    CareerHubPageComponent,
    CareerHubArticleComponent,
    CareerHubHeaderComponent,
    CareerHubArticlesComponent,
    PageGridComponent,
    CareerHubSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    LazyLoadImageModule,
    CareerHubRoutingModule,
    SharedModule
  ],
  providers: [
    JourneysResolver,
    CareerHubService
  ]
})
export class CareerHubModule { }
