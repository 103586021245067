import { Injectable } from '@angular/core';

@Injectable()
export class DenormalizerService {

  denormalize(fields, values) {
    const output = {};

    for (let i = 0; i < fields.length; i += 1) {
      let key = fields[i].field_name;

      switch (fields[i].type) {
        case 'text_format':
          if (!values[key]) {
            output[key] = null;
          } else {
            output[key] = fields[i].cardinality === 1
              ? [{
                value: values[key],
                format: 'html'
              }]
              : values[key].map(item => {
                return {
                  value: item,
                  format: 'html'
                }
              });
          }
          break;
        case 'file':
        case 'image':
        case 'file_default':
          if (!values[key]) {
            output[key] = null;
          } else {
            output[key] = fields[i].cardinality === 1
                          ? [{target_id: values[key].fid}]
                          : values[key].map(item => {
                            return {target_id: item.fid}
                          });
          }
          break;

        case 'entity_reference':
        case 'entity_reference_autocomplete':
          if (!values[key]) {
            output[key] = null;
          } else {
            output[key] = fields[i].cardinality === 1
                          ? [{target_id: values[key]}]
                          : values[key].map(item => {
                            return {target_id: item}
                          });
          }
          break;

        case 'boolean':
        case 'boolean_checkbox':
        case 'checkbox':
          output[key] = fields[i].cardinality === 1
            ? values[key] ? [{ "value": 1 }] : [{ "value": 0 }]
            : values[key].map(item => item ? { "value": 1 } : { "value": 0 });
          break;

        case 'list_integer':
          output[key] = parseInt(values[key], 10);
          break;

        case 'number':
          if (fields[i].hasOwnProperty('field_type') && fields[i]['field_type'] == 'decimal') {
            output[key] = parseFloat(values[key]);
          } else {
            output[key] = parseInt(values[key], 10);
          }

          output[key] = `${output[key]}`;
          break;

        default:
          if (!values[key]) {
            if (fields[i].hasOwnProperty('field_type') && fields[i]['field_type'] === 'list_integer') {
              const listIntVal = parseInt(values[key], 10);
              output[key] = listIntVal;
            } else {
              output[key] = null;
            }
          } else {
            output[key] = fields[i].cardinality === 1
                          ? values[key]
                          : values[key].map(item => item);
          }
      }
    }

    return output;
  }

}
