<form [formGroup]="form">
  <div class="form-group">
    <label for="keywords" class="sr-only">Search keywords</label>
    <input
      type="text"
      id="keywords"
      class="form-control"
      name="keywords"
      formControlName="keywords"
      placeholder="Keywords e.g teacher"
      [value]="keywords"
      (keypress)="onReturn($event)"
      #keywordInput>
    <button
      class="clear-input"
      *ngIf="keywords"
      (click)="clearKeywords()"
      type="button">
      <span class="sr-only">Clear keywords search</span>
      <svg viewBox="0 0 32 32" width="100%" height="100%" aria-hidden="true">
        <path d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
      </svg>
    </button>
  </div>

</form>