<mjs-page-header
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <p *ngIf="!loading && lumesseDisabled"><strong [innerHtml]="lumesseDisabled"></strong></p>
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more && !non_lumesse"></mjs-read-more>
      <div *ngIf="non_lumesse">Your job profile can't be edited</div>
      <div *ngIf="content.body && !content.read_more && !loading && !non_lumesse" [innerHtml]="content.body | safe"></div>
      <mjs-entity-form
        *ngIf="profile && !lumesseDisabled"
        [hidden]="loading"
        [formData]="profile"
        [params]="params"
        (submitForm)="submit($event)"
      ></mjs-entity-form>
    </main>
    <div class="col sidebar">
      <mjs-menu
        *ngIf="content && content.menu"
        [menu]="content.menu"
        [recruiter]="recruiter"
        [user]="'1'"
        [classname]="'sidebar-user-menu'">
      </mjs-menu>

      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
