import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Navigation } from '@app/models';

@Injectable()
export class NavigationService {
  apiUrl: string;

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private http: HttpClient) { }

  getMenus(): Observable<Navigation> {
    const NAVIGATION_KEY = makeStateKey<Navigation>(`navigation`);

    if (this.transferState.hasKey(NAVIGATION_KEY)) {
      const navigation = this.transferState.get<Navigation>(NAVIGATION_KEY, null);
      this.transferState.remove(NAVIGATION_KEY);
      return of(navigation);
    }

    return this.http.get<Navigation>(`${environment.apiUrl}/api/v1/menus?_format=json`)
    .pipe(
      tap(navigation => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(NAVIGATION_KEY, navigation);
        }

        return of(navigation)
      })
    );
  }

}
