import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  Renderer2
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Content } from '@app/models';

@Component({
  selector: 'mjs-education-hub-home',
  templateUrl: './education-hub-home.component.html',
  styleUrls: ['./education-hub-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EducationHubHomeComponent implements OnInit {
  
  content: Content;
  council: any;

  constructor(private route: ActivatedRoute,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.route.data.subscribe(res => {
      this.content = res['content'];

      for (let i = 0; i < this.content.views.councils_map__rest.length; i += 1) {
        this.content.views.councils_map__rest[i].opacity = (Math.random() * (0.400 - 1.000) + 1).toFixed(4);
      }
    });

    this.renderer.addClass(this.doc.body, 'full-width');
    this.renderer.addClass(this.doc.body, 'gray');
  }

  public selectCouncil(index):void {
    let i;

    if (isNaN(parseInt(index))) {
      i = parseInt(index.target.value)
    }
    else {
      i = parseInt(index);
    }

    this.council = this.content.views.councils_map__rest[i];
  }

}
