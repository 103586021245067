import { Component, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CareerHubSearchService } from '../../services/career-hub-search.service';
import { Content } from '@app/models';
import { WebpService } from '@app/services';

interface SearchResult {
  path: string;
  title: string;
}

@Component({
  selector: 'mjs-career-hub-header',
  templateUrl: './career-hub-header.component.html',
  styleUrls: ['./career-hub-header.component.scss'],
  providers: [CareerHubSearchService],
  encapsulation: ViewEncapsulation.None
})
export class CareerHubHeaderComponent implements OnDestroy, OnDestroy {
  
  @Input() content: Content;
  @Input() searchPage: boolean = false;

  data: Content;
  form: UntypedFormGroup = new UntypedFormGroup({ });
  searchResults: SearchResult[];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private search: CareerHubSearchService,
    private router: Router,
    public webp: WebpService) {
    this.form.addControl('searchTerm', new UntypedFormControl(''));

    this.form.valueChanges.subscribe(val => {
      this.searchCareerHub(val.searchTerm);
    });

    this.data = this.content;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { content: { previousValue, currentValue } } = changes;

    if (!previousValue || previousValue.title !== currentValue.title) {
      this.data = currentValue;
    }
  }

  public searchCareerHub(val) {
    if (val.length > 3) {
      this.search.get(val)
        .pipe(takeUntil(this.onDestroy$)).subscribe(
        data => {
          this.searchResults = data;
        },
        error => {
          throwError(error);
        }
      );
    }
  }

  public selectSearchResult() {
    this.router.navigate([this.form.value.searchTerm]);

    this.form.controls['searchTerm'].setValue('');
  }

  public onSubmit() {
    this.router.navigate(['/career-hub/search'], {
      queryParams: {
        keywords: this.form.value.searchTerm
      }
    });
  }

  public ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
