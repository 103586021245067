import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mjsJobClosingDate'
})
export class MjsJobClosingDatePipe implements PipeTransform {

  /**
   * Display the closing Date minus 1 day to the end user.
   * The job closes at midnight on that day.
   *
   * @param date string
   */
  transform(date: string): any {
    let d;

    if (date.length === 10) {
      d = new Date(date.split('/').reverse().join('-'));
    } else {
      d = new Date(date.replace(' ', 'T'));
    }

    // d.setDate(d.getDate() - 1);

    return d;
  }

}
