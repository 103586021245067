import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Renderer2,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { throwError, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { OrganisationsService } from './organisations.service';
import { OrganisationsParams, OrganisationsResults, OrgCompact } from '@app/models';
import { WebpService } from '@app/services';

@Component({
  selector: 'mjs-organisations',
  inputs: ['list', 'page', 'pages', 'total', 'params: OrganisationsParams', 'results: OrganisationsResults'],
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss'],
  providers: [OrganisationsService],
  encapsulation: ViewEncapsulation.None,
})
export class OrganisationsComponent implements OnInit, OnDestroy {
  
  @Input('mobile') isMobile: boolean;
  
  showSearch: boolean = false;
  defaultType: string;
  type: UntypedFormGroup;
  form: UntypedFormGroup;
  content: any;
  loading: boolean;
  hideSearch: boolean;
  filters: any;
  params: OrganisationsParams = {
    query: '',
    sort: '',
    type: '',
    page: 1
  };
  results: OrganisationsResults = {
    list: [],
    page: 0,
    pages: 0,
    total: 0
  }

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private doc: Document,
    private route: ActivatedRoute,
    private orgService: OrganisationsService,
    public webp: WebpService) {
    
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({ });
    this.type = new UntypedFormGroup({ });

    this.route.data.subscribe(res => {
      this.content = res['content'];
      this.filters = res['filters'] || null;

      if (!this.filters) {
        this.renderer.addClass(this.doc.body, 'full-width');
        this.defaultType = this.content.path.replace('/', '');
      }

      this.renderer.addClass(this.doc.body, 'gray');

      this.showSearch = true;
    });

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$)).subscribe((queryParams: ParamMap) => {
      this.params.query = queryParams.get('query');
      this.params.sort = queryParams.get('sort') || 'count_desc';
      this.params.page = parseInt(queryParams.get('page'), 10) || 1;
      this.params.type = this.defaultType ? this.defaultType : queryParams.get('type');

      this.searchOrgs();
    });

    this.form.addControl('sort', new UntypedFormControl(this.params.sort));
    this.form.addControl('query', new UntypedFormControl(this.params.query));
    this.keywordsChange();

    if (this.showSearch) {
      if (this.filters) {
        for (let key in this.filters) {
          if (key !== '-') this.type.addControl(key, new UntypedFormControl(''));
        }
        this.valuesChanged();
      }

      if (this.params.type) {
        let types = this.params.type.split(',');

        for (let i = 0; i < types.length; i += 1) {
          if (this.type.controls[types[i]]) {
            this.type.controls[types[i]].setValue(true);
          }
        }
      }
    }
  }

  trackByFn(org: OrgCompact) {
    return org.path;
  }

  searchOrgs = (): void => {
    this.loading = true;

    this.orgService.get(this.params).pipe(takeUntil(this.onDestroy$)).subscribe(
      (res: any) => {
        this.loading = false;
        this.results = res;
      },
      err => {
        this.loading = false;
        throwError(err)
      }
    )
  }

  sortChange(value): void {
    this.router.navigate(['.'], {
      queryParams: { sort: value },
      queryParamsHandling: 'merge',
      relativeTo: this.route
    })
  }

  summaryDisplay(): string {
    if (this.loading) {
      return 'Searching...';
    }

    if (this.results) {
      if (this.results.total <= 40) {
        return `<span class="desktop-only">Showing </span>${(this.results.total == 1 ? '1 organisation' : this.results.total + ' organisations')}`;
      } else {
        return `<span class="desktop-only">Showing </span>${((this.results.page - 1) * 40 + 1) + ' - ' + Math.min( this.results.page * 40, this.results.total)} of ${this.results.total} organisations`;
      }
    }

    return '';
  }

  keywordsChange(): void {
    this.form.valueChanges.pipe(
      debounceTime(750),
      takeUntil(this.onDestroy$)
    ).subscribe(val => {
      this.params.query = val.query;

      this.router.navigate(['.'], {
        queryParams: { query: this.params.query },
        queryParamsHandling: 'merge',
        relativeTo: this.route
      })
    });
  }

  valuesChanged(): void {
    this.type.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(val => {
      let selected = [];

      for (let key in val) {
        if (val[key]) {
          selected.push(key);
        }
      }

      this.router.navigate([this.router.url.split('?')[0]], {
        queryParams: { type: selected.join(',') },
        queryParamsHandling: 'merge',
      })
    });
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(this.doc.body, 'gray');

    if (!this.showSearch) {
      this.renderer.removeClass(this.doc.body, 'full-width');
    }

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
