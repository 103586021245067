import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Renderer2,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { ResetService } from '@app/modules/user/services';
import { isPlatformBrowser } from '@angular/common';
import { ReCaptchaV3Service } from "@node_modules/ng-recaptcha";

declare var window: any;

@Component({
  selector: 'mjs-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  providers: [ResetService],
  encapsulation: ViewEncapsulation.None
})
export class ResetComponent implements OnInit, OnDestroy {

  content: any;
  submitted: boolean;
  form = new FormGroup({ });
  loading: boolean;
  confirmAccount: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private resetService: ResetService,
    private renderer: Renderer2,
    private analytics: Angulartics2GoogleAnalytics) { }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
      this.confirmAccount = this.content.title.toLowerCase() === 'confirm account';
    });
    this.form.addControl('email', new FormControl<String>(null, [Validators.email,Validators.required]));
    // this.form.addControl('recaptchaReactive', new FormControl<String>(null, Validators.required));
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();

    this.recaptchaV3Service
      .execute('create_alert')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (token) => {
          this.reset(this.form.controls['email'].value, token, this.confirmAccount);
        },
        error: err => {
          this.loading = false;
          this.toast.error('Recaptcha validation failed');
        }
      });
  }

  reset(email, recaptcha, confirm): void {
    this.resetService.post(email, recaptcha, confirm)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data: any) => {
          this.toast.success(data.message);
          this.loading = false;

          if (this.confirmAccount) {
            this.analytics.eventTrack('submit', {
              category: 'Confirm Account',
              label: 'Email only account user submitted email address to confirm account'
            });

            if (isPlatformBrowser(this.platformId)) {
              // GA4 event.
              window.gtag('event', 'Confirm Account', {
                label: 'Email only account user submitted email address to confirm account',
              });
            }
          }
        },
        error: err => {
          this.loading = false;
          this.toast.error(err.error.message);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
