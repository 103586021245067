const jobExtensionTokensForm = [{
  field_name: 'mjs_job_extension_tokens',
  label: 'Number of Job Extension Tokens',
  required: false,
  description: 'How many job extension tokens would you like to buy?',
  type: 'number'
}, {
  field_name: 'mjs_job_extension_tokens_duration',
  label: 'Duration of Job Extension Tokens',
  required: false,
  description: 'Please select the duration of the job extension tokens',
  type: 'options_select',
  value: 1,
  settings: {
    allowed_values: {
      1: '1 Week (£25 per token)',
      2: '2 Weeks (£50 per token)',
      3: '3 Weeks (£75 per token)',
      4: '4 Weeks (£100 per token)'
    }
  }
}];

export default jobExtensionTokensForm;