import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class CareerHubSearchService {

  constructor(private http: HttpClient) {}

  get(params: any) {
    let url = `${environment.apiUrl}/api/v1/career-hub-search?_format=json&`;
    let query = [];

    for (let key in params) {
      if (params[key]) {
        query.push(`${key}=${params[key]}`);
      }
    }

    if (query) {
      url += query.join('&');
    }

    return this.http.get<any>(url);
  }

}
