import { Component, OnInit, OnDestroy, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, asyncScheduler } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DenormalizerService } from '@modules/drupal-entity/services/denormalizer.service';
import { MjsPositionsService } from "@modules/recruiter/services/mjs-positions.service";

@Component({
  selector: 'mjs-recruiter-position',
  templateUrl: './mjs-position.component.html',
  styleUrls: ['./mjs-position.component.scss'],
  providers: [MjsPositionsService, DenormalizerService],
  encapsulation: ViewEncapsulation.None
})
export class MjsPositionComponent implements OnInit, OnDestroy {

  params: any = {};
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean = true;
  duplicating: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private mjsPositionService: MjsPositionsService,
    private renderer: Renderer2,
    private toast: ToastrService,
    private denoramlizer: DenormalizerService) {
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.loading = true;
        let position = res.get('position');

        if (position) {
          this.entity_id = parseInt(position, 10);
        } else {
          this.entity_id = null;
        }

        asyncScheduler.schedule(() => {
          this.loading = false;
        }, 750);
      });

    this.route.data
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.content = res['content'];

        if (!this.content.org.positions) {
          this.toast.error('You do not have permission to access positions');
          this.router.navigate([this.content.org.path]);
        }

        if (this.content.org && this.content.org.redirect) {
          this.toast.error(this.content.org.redirect.message);
          this.router.navigate([this.content.org.redirect.path]);
        }

        if (this.content && this.content.org && this.content.org.job_advert_id) {
          this.entity_id = this.content.org.job_advert_id;
        }
      });

    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
        let duplicate = parseInt(queryParams.get('duplicate'), 10);

        if (duplicate) {
          this.entity_id = duplicate;
          this.duplicating = true;
        }
      });
  }

  submit(data) {
    this.loading = true;
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();

    const params: any = this.denoramlizer.denormalize(data.structure, data.params);

    params.description[0].format = 'job';

    // Set default values for external advertiser.
    params.organisation = [{ target_id: this.content.org.id }];

    if (typeof params.end_date === 'string') {
      params.end_date = new Date(params.end_date);
    }

    params.end_date = this.ISODateString(params.end_date);

    if (this.entity_id && !this.duplicating) {
      this.mjsPositionService.patch(params, this.entity_id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (res: any) => {
            this.params = res.params;
            this.router.navigate([this.content.org.path + '/positions'], {
              queryParams: {
                message: 'recruiter_job_updated'
              }
            });
          },
          error: err => this.onError(data, err)
        });
    } else {
      this.mjsPositionService.post(params)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (res: any) => {
            return this.router.navigate([this.content.org.path + '/positions'], {
              queryParams: {
                message: 'recruiter_job_created'
              }
            })
          },
          error: err => this.onError(data, err)
        });
    }
  }

  ISODateString(d){
    function pad(n) {
      return n < 10 ? "0" + n : n;
    }

    function timezoneOffset(offset) {
      var sign;
      if (offset === 0) {
        return "Z";
      }
      sign = (offset > 0) ? "-" : "+";
      offset = Math.abs(offset);
      return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
    }

    return d.getFullYear() + "-" +
      pad(d.getMonth() + 1) + "-" +
      pad(d.getDate());
  }

  onError(data, err) {
    this.params = data.params;
    this.loading = false;
    this.toast.error(err.error.message)
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
