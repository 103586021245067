import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { WebpService } from '@app/services';
import { JobPreview } from '@app/models';
import { MjsJob } from "@models/mjs-job.model";

@Component({
  selector: 'mjs-featured-jobs',
  templateUrl: './featured-jobs.component.html',
  styleUrls: ['./featured-jobs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeaturedJobsComponent implements OnInit {

  @Input() list: MjsJob[];
  @Input() latest: MjsJob[];

  constructor(public webp: WebpService) {}

  public ngOnInit(): void {
    // If the featured jobs array has less than 8 then top
    // it up from the latest jobs list.
    if (this.list.length < 8 && this.latest.length > 0) {
      let e = 0;

      for (let e = 0; e < this.latest.length && this.list.length < 8; e += 1) {
        this.list.push(this.latest[e]);
      }
    }
  }

}
