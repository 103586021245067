<div class="map-container">
  <div id="map"></div>
  <div class="job-overlay" id="job-overlay" [hidden]="!currentMarker && !markerList">
    <div class="job-overlay-center" [ngClass]="{small: !currentMarker && markerList}">
      <mjs-job-listing
        *ngIf="currentMarker"
        [mjsJob]="currentMarker">
      </mjs-job-listing>
      <div *ngIf="!currentMarker && markerList" class="job-marker-list">
        <ul>
          <li *ngFor="let item of markerList" class="d-block">
            <button class="btn d-block w-100 text-left" (click)="selectMarker(item.data)" [innerHtml]="item.data.title"></button>
          </li>
        </ul>
      </div>
      <button type="button" aria-controls="job-overlay" aria-expanded="" (click)="closeMarker()">
        <span class="sr-only">Close job details</span>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
          <path d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
        </svg>   
      </button>
    </div>
  </div>
</div>
