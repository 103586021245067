<mjs-career-hub-header [content]="content" [searchPage]="true"></mjs-career-hub-header>
<div class="container">
  <div class="row">
    <div class="col col-md-3 pb-3">
      <button
        class="sidebar-menu-toggle d-block d-md-none w-100 text-left btn btn-outline-primary mb-4"
        [attr.aria-expanded]="!hideMenu ? 'true' : 'false'"
        (click)="toggleMenu()">
        Menu
        <span class="mjs-menu-toggle" role="presentation"><i></i><i></i><i></i></span>
      </button>
      <ng-container *ngIf="!hideMenu">
        <mjs-menu
          classname="nav flex-column nav-tabs-styled"
          liclassname="d-block"
          linkclassname="d-block text-dark py-2"
          [menu]="content.menu[0].children">
        </mjs-menu>
      </ng-container>
    </div>
    <div class="col col-md-7 career-hub-main">
      <form class="career-hub-search-form d-flex w-100 mb-2 ng-untouched ng-pristine ng-valid align-items-end" [formGroup]="form" (submit)="onSubmit()">
        <div class="form-group m-0 w-100">
          <label for="keywords">Keywords</label>
          <input
            type="text"
            formControlName="keywords"
            id="keywords"
            class="form-control"
          >
        </div>
        <div class="form-group custom-select m-0" *ngIf="content.views && content.views.career_hub_tags__rest_export_1">
          <label for="tag">Tag <span class="sr-only">- content is tagged to make it easier to search, please select a tag from the list below</span></label>
          <select
            type="select"
            formControlName="tag"
            id="tag"
            class="form-control custom-select"
          >
            <option [ngValue]="null">-- select --</option>
            <ng-container *ngFor="let option of content.views.career_hub_tags__rest_export_1">
              <option
                [value]="option.id">
                {{ option.name }}
              </option>
            </ng-container>
          </select>
        </div>
        <button type="submit" class="btn btn-primary">Search</button>
      </form>
      <p class="my-3" *ngIf="form.value.keywords && !results">No results found</p>
      <p class="my-3" *ngIf="!form.value.keywords">Enter keywords and submit to search</p>
      <ng-container *ngIf="results">
        <p role="status" class="my-3 bg-light px-3 py-2 border rounded"><strong>Showing {{ results.length }} result{{ results.length > 1 ? 's' : '' }}</strong></p>
        <ol class="career-hub-search-results list-unstyled">
          <li *ngFor="let item of results">
            <h2><a [routerLink]="item.path" [innerHtml]="item.title"></a></h2>
            <a [routerLink]="item.path" [innerHtml]="item.path"></a>
            <div *ngIf="item.summary" [innerHtml]="item.summary"></div>
          </li>
        </ol>
      </ng-container>
    </div>
  </div>
</div>
