<mjs-career-hub-header [content]="content"></mjs-career-hub-header>
<div class="container ">
  <div *ngIf="pagelinks" class="full-width">
    <mjs-page-grid [list]="pagelinks"></mjs-page-grid>
  </div>
  <div class="row d-flex flex-row-reverse" *ngIf="!pagelinks">
    <div class="career-hub-main col">
      <div class="career-hub-intro" [innerHtml]="content.intro" *ngIf="content.intro"></div>
      <div class="career-hub-content wysiwyg-content" [innerHtml]="content.html"></div>
      <mjs-council-map *ngIf="content.council_map" [type]="content.council_map"></mjs-council-map>
      <div class="career-hub-links" *ngIf="content.links">
        <h2>Further Reading</h2>
        <ul>
          <li *ngFor="let link of content.links">
            <a [href]="link.url" target="_blank" rel="noopener noreferer">
              {{ link.title || link.url }}
            </a>
          </li>
        </ul>
      </div>
      <div class="career-hub-tags" *ngIf="content.tags && content.tags.length > 0">
        <h2>Related Topics</h2>
        <ul class="ml-0">
          <li *ngFor="let tag of content.tags">
            <a routerLink="/career-hub/search" [queryParams]="{tag: tag.tid}" [innerHtml]="tag.name"></a>
          </li>
        </ul>
      </div>
      <div class="find-a-job">
        <h2>Find a Job You’ll Love</h2>
        <a routerLink="/search" class="btn btn-primary">Search Jobs</a>
      </div>
    </div>
    <div class="career-hub-sidebar pb-3 col">
      <button
        class="sidebar-menu-toggle d-block d-md-none w-100 text-left btn btn-outline-primary mb-4"
        [attr.aria-expanded]="!hideMenu ? 'true' : 'false'"
        (click)="toggleMenu()">
        Menu
        <span class="mjs-menu-toggle" role="presentation"><i></i><i></i><i></i></span>
      </button>
      <ng-container *ngIf="!hideMenu && content.menu && content.menu[0]">
        <mjs-menu
          classname="nav flex-column nav-tabs-styled"
          liclassname="d-block"
          linkclassname="d-block text-dark py-2"
          [menu]="content.menu[0].children">
        </mjs-menu>
      </ng-container>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
<mjs-post-navigation
  *ngIf="content.menu && content.path && !pagelinks"
  [menu]="content.menu"
  [url]="content.path">
</mjs-post-navigation>
