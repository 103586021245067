import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoriesService } from '@app/shared/services/categories.service';

@Component({
  selector: 'mjs-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers: [CategoriesService],
  encapsulation: ViewEncapsulation.None
})
export class CategoriesComponent implements OnInit {
  
  content: any;
  list: any;
  
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private doc: Document,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.renderer.addClass(this.doc.body, 'gray');
    
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.categoriesService.get().pipe(takeUntil(this.onDestroy$)).subscribe(
      data => {
        this.list = data
      }
    );
  }

  public ngOnDestroy(): void {
    this.renderer.addClass(this.doc.body, 'gray');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
