<ul
  *ngIf="profile && profile.admin_menu"
  class="recruiter-menu">
  <li class="depth-1"><a [routerLink]="'/my-organisations'" routerLinkActive="active">My Organisations</a></li>
  <li class="depth-2" *ngFor="let item of profile.admin_menu.organisations">
    <a [routerLink]="item.path" routerLinkActive="active">{{ item.title }}</a>
    <ul *ngIf="item.children">
      <li *ngFor="let child of item.children" class="depth-3">
        <a [routerLink]="child.path" routerLinkActive="active" [ngClass]="'icon-' + child.icon">
          {{ child.title }} <span *ngIf="child.count">{{ child.count }}</span>
        </a>
        <ul *ngIf="child.children">
          <li *ngFor="let childItem of child.children" class="depth-4">
            <a [routerLink]="childItem.path" routerLinkActive="active">
              {{ childItem.title }} <span *ngIf="childItem.count">{{ childItem.count }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
  <li class="depth-2" *ngFor="let item of profile.admin_menu.actions">
    <a [routerLink]="item.path" routerLinkActive="active">{{ item.title }}</a>
  </li>
</ul>
