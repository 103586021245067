<div class="council-map-wrap col col-md-8 p-0 mb-3">
  <div class="council-selector">
    <form (submit)="submit($event)">
      <div class="form-group mb-4">
        <label for="council-select"><strong>Select a council</strong></label>
        <div class="d-flex">
          <select id="council-select" class="form-control" (change)="council = $event.target?.['value']">
            <option value="">-- Select a council --</option>
            <option *ngFor="let council of list; let i = index" [value]="i" [innerHtml]="council.name">
            </option>
          </select>
          <button *ngIf="(council === 0 || council > 0)" class="btn btn-primary ms-1">Go</button>
            <button *ngIf="!(council === 0 || council > 0)" class="btn btn-primary ms-1" disabled>Go</button>
          </div>
      </div>
      <div class="form-text">Selecting a council will take you to a new tab.</div>
    </form>
  </div>
  <div
    *ngIf="list"
    class="councils-map-wrap border rounded shadow-sm p-2 mw-50 d-none d-md-block">
    <svg
      class="bg-light"
      version="1.1"
      id="councils-map"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 633.8 952.2"
      style="enable-background:new 0 0 633.8 952.2;"
      xml:space="preserve">
      <g transform="scale(1,1.7)">
        <path
          *ngFor="let council of list; let i = index"
          [attr.d]="council.svg_path"
          [attr.opacity]="council.opacity"
          (click)="selectCouncil(i)"
          fill="#308091"
        />
      </g>
    </svg>
  </div>
</div>
