import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { OrganisationFiltersService } from '@app/modules/organisations/organisation-filters.service';

@Injectable()
export class OrganisationFiltersResolver implements Resolve<any> {

  constructor(private content: OrganisationFiltersService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    return this.content.get();
  }

}
