<section class="category-list" *ngIf="list">
  <div class="container">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-between no-after">
        <h2 class="heading spec">
          <a routerLink="/categories">
            Job <span>Categories</span>
          </a>
        </h2>
        <button (click)="toggleList()">
          View {{ toggled ? 'less' : 'all' }} <span class="sr-only">categories below</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let item of list | slice : 0 : limit" class="col-md-3">
        <a [routerLink]="item.path" class="d-flex justify-content-between mb-2">
          <span class="text-truncate">{{ item.title }}</span> <span class="pull-right">({{ item.job_count || '0' }})</span>
        </a>
      </div>
    </div>
  </div>
</section>
