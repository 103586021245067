import {
  Component,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Menu } from '@app/models';

@Component({
  selector: 'mjs-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbsComponent implements OnChanges {
  
  @Input() menu: Menu;
  @Input() url: string;

  breadcrumbs: Menu = [];

  public ngOnChanges(changes: SimpleChanges): void {
    this.breadcrumbs = [];
    this.createBreadcrumbs();
  }

  private createBreadcrumbs() {
    let parts = this.url.split('/').reverse();
    parts.pop();
    parts.reverse();

    parts.forEach((node, index) => {
      this.findMenuItem(this.menu, node);
    });
  }

  private findMenuItem(menu: Menu, url: string) {
    return menu.forEach((node, index) => {
      if (node.url.split('/').reverse()[0] == url) {
        return this.breadcrumbs.push(node);
      } else if (node.children) {
        return this.findMenuItem(node.children, url);
      }
    });
  }
}
