<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="this.content && this.content.org">
          <li>
            <a
              [routerLink]="this.content.org.path + '/job-tokens'"
              routerLinkActive="active">
              Job Tokens
            </a>
          </li>
          <li>
            <a

              [routerLink]="this.content.org.path + '/purchase-tokens'"
              routerLinkActive="active">
              Purchase Tokens
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>

      <ng-container *ngIf="!loading && this.content && this.content.org">

        <p>
          <strong>
            You currently have {{ content.org.tokens > 0 ? content.org.tokens : 'no' }} job token{{ content.org.tokens !== 1 ? 's' : '' }}.
          </strong>
        </p>

        <div [innerHtml]="content.body" *ngIf="content.body"></div>

        <div class="sticky-table-container" *ngIf="data && data.list.length > 0">
          <div class="table-container">
            <table mat-table [dataSource]="data.list">

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id || '-'}} </td>
              </ng-container>

              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.created|date:'d/M/yy, h:mm'}} </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> <span class="badge" [ngClass]="{
                  'badge-warning': !element.status,
                  'badge-success': element.status
                }">{{element.status ? 'Complete' : 'Pending'}}</span> </td>
              </ng-container>

              <ng-container matColumnDef="tokens">
                <th mat-header-cell *matHeaderCellDef> Tokens </th>
                <td mat-cell *matCellDef="let element"> {{element.tokens}} </td>
              </ng-container>

              <ng-container matColumnDef="paid">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let element"> £{{element.paid || 0}} </td>
              </ng-container>

              <ng-container matColumnDef="ref">
                <th mat-header-cell *matHeaderCellDef> Job No/Ref </th>
                <td mat-cell *matCellDef="let element"> {{element.ref}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>

            </table>
            <div class="sticky-actions" *ngIf="showActions">
              <div class="btn-group" *ngFor="let element of data.list">
                <!-- (click)="toggleElementActions(element)" -->
                <button
                  [matMenuTriggerFor]="menu"
                  class="btn btn-outline-primary btn-sm dropdown-toggle"
                  type="button"
                  aria-haspopup="true"
                  [attr.aria-expanded]="element.active || 'false'">
                  Actions
                </button>
                <mat-menu yPosition="above" #menu="matMenu">
                  <a mat-menu-item [routerLink]="content.org.path + '/job-tokens/' + element.id">View</a>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <mjs-pager
          *ngIf="data && data.pages > 1"
          [page]="data.page"
          [pages]="data.pages"
          [total]="data.total">
        </mjs-pager>

      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
