import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mjs-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseStudiesComponent {
  @Input('list') list: any;
}
