import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class ApplicationsService {

  constructor(private http: HttpClient) {}

  get() {
    return this.http.get(`${environment.apiUrl}/api/v1/user/applications`);
  }

  delete(
    id: number,
    type: string,
    url: string
  ) {
    return this.http.request('DELETE', `${environment.apiUrl}/api/v1/user/applications`, {
      body: { id, type, url }
    })
  }

}
