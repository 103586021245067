import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ContextToken } from '@app/models';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ContextTokenService {

  constructor(private http: HttpClient) { }

  getToken(): Observable<ContextToken> {
    return this.http.get<ContextToken>(`${environment.apiUrl}/lumesse_rac_context_token`)
  }

}
