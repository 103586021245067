import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SitemapComponent } from './sitemap.component';

const SITEMAP_ROUTES: Routes = [{
  path: '',
  component: SitemapComponent,
}];

@NgModule({
  imports: [RouterModule.forChild(SITEMAP_ROUTES)],
  exports: [RouterModule]
})
export class SitemapRoutingModule { }
