<div
  *ngIf="browser && loaded"
  class="form-group wysiwyg field-{{ element.field_name }}"
  [formGroup]="parentForm"
  [ngClass]="{ required: element.required }">
  <quill-editor [sanitize]="true" [formControlName]="element.field_name" [formats]="formats">
    <div quill-editor-toolbar>
      <span class="ql-formats">
        <select class="ql-header">
          <option value="">Normal</option>
          <option value="2">Heading 2</option>
          <option value="3">Heading 3</option>
          <option value="4">Heading 4</option>
          <option value="5">Heading 5</option>
          <option value="6">Heading 6</option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <select class="ql-align">
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-link"></button>
      </span>
    </div>
  </quill-editor>
  <div *ngIf="submitted && errors" class="invalid-feedback" role="alert">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div>
</div>
