<mjs-page-header
  *ngIf="content"
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col sitemap">
      <ng-container *ngFor="let menu of sitemap">
        <div class="faq">
          <h3>
            <button
              type="button"
              (click)="menu.open = !menu.open"
              [innerHtml]="menu.title"
              [attr.aria-expanded]="menu.open || 'false'"
              [attr.aria-controls]="menu.id">
            </button>
            <svg aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path *ngIf="!menu.open" d="M18.984 12.984h-6v6h-1.969v-6h-6v-1.969h6v-6h1.969v6h6v1.969z"></path>
              <path *ngIf="menu.open" d="M18.984 12.984h-13.969v-1.969h13.969v1.969z"></path>
            </svg>
          </h3>
          <div [id]="menu.id" [hidden]="!menu.open">
            <mjs-menu
              [menu]="menu.menu"
              classname="m-0 list-style-none">
            </mjs-menu>
          </div>
        </div>
      </ng-container>
    </main>
    <aside class="col sidebar col-sm-12">
      <mjs-menu
        *ngIf="content && content.menu"
        [menu]="content.menu"
        [class]="'sidebar-menu'">
      </mjs-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </aside>
  </div>
</div>
