import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FacetService {

  private subject = new Subject<any>();

  constructor() { }

  changeValue(facets) {
    if (facets.facets.hasOwnProperty('parent_org')) {
      if (facets.facets.hasOwnProperty('org')) {
        for (let key in facets.facets.parent_org) {
          if (facets.facets.org.hasOwnProperty(key)) {
            facets.facets.org[key] = (parseInt(facets.facets.org[key], 10) + parseInt(facets.facets.parent_org[key], 10));
          } else {
            facets.facets.org[key] = facets.facets.parent_org[key];
          }
        }
      } else {
        facets.facets.org = facets.facets.parent_org;
      }

      delete facets.facets.parent_org;
    }

    if (facets.facets.hasOwnProperty('parent_cat')) {
      if (facets.facets.hasOwnProperty('cat')) {
        for (let key in facets.facets.parent_cat) {
          if (facets.facets.cat.hasOwnProperty(key)) {
            facets.facets.cat[key] = (parseInt(facets.facets.cat[key], 10) + parseInt(facets.facets.parent_cat[key], 10));
          } else {
            facets.facets.cat[key] = facets.facets.parent_cat[key];
          }
        }
      } else {
        facets.facets.cat = facets.facets.parent_cat;
      }

      delete facets.facets.parent_cat;
    }

    this.subject.next(facets);
  }

  getFacets(): Observable<any> {
    return this.subject.asObservable();
  }

}
