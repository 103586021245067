<ul class="{{ classname || null }}">
  <ng-template #recursiveList let-list>
    <li
      *ngFor="let item of list"
      [ngClass]="liclassname">
      <a
        *ngIf="item.url && !item.external && item.url !== '/search-organisations' && item.url.charAt(0) !== '#'"
        routerLink="{{ item.url }}"
        routerLinkActive="active"
        [ngClass]="linkclassname">
        <span
          *ngIf="item.icon"
          [innerHTML]="item.icon | safe">
        </span>
        <div class="d-inline-block pr-2" [innerHtml]="item.title"></div>
      </a>
      <a
        *ngIf="item.url && !item.external && item.url === '/search-organisations'"
        routerLink="{{ item.url }}"
        routerLinkActive="active"
        [queryParams]="{ type: 'councils' }"
        [ngClass]="linkclassname">
        <span
          *ngIf="item.icon"
          [innerHTML]="item.icon | safe">
        </span>
        <div class="d-inline-block pr-2" [innerHtml]="item.title"></div>
      </a>
      <a
        *ngIf="item.url && !item.external && item.url.charAt(0) === '#'"
        [routerLink]="'.'"
        [fragment]="item.url.substring(1)"
        routerLinkActive="active"
        [ngClass]="linkclassname">
        <span
          *ngIf="item.icon"
          [innerHTML]="item.icon | safe">
        </span>
        <div class="d-inline-block pr-2" [innerHtml]="item.title"></div>
      </a>
      <a
        *ngIf="item.url && item.external"
        [href]="item.url"
        target="_blank"
        rel="_noopener"
        [ngClass]="linkclassname">
        <span
          *ngIf="item.icon"
          [innerHTML]="item.icon | safe">
        </span>
        <div class="d-inline-block pr-2" [innerHtml]="item.title"></div>
        <span class="sr-only"> (Opens in new tab)</span>
      </a>
      <span *ngIf="!item.url"
      [ngClass]="linkclassname">
        <span
          *ngIf="item.icon"
          [innerHTML]="item.icon | safe">
        </span>
        <ng-container *ngIf="!item.icon_only">
          <div class="d-inline-block pr-2" [innerHtml]="item.title"></div>
        </ng-container>
      </span>
      <ul *ngIf="item.has_children">
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
      </ul>
    </li>
  </ng-template>
  <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: menu }"></ng-container>
  <li *ngIf="recruiter" [ngClass]="liclassname">
    <a routerLink="/my-organisations"
    [ngClass]="linkclassname">
      <span>
        <svg width="24" height="24" viewBox="0 0 24 24"><g fill="#444" transform="scale(0.0234375 0.0234375)"><path d="M726 682h170v214h-214v-130l-170-180-170 180v130h-214v-214h170l172-170v-136c-50-18-86-64-86-120 0-70 58-128 128-128s128 58 128 128c0 56-36 102-86 120v136z"/></g></svg>
      </span>
      My Organisations
    </a>
  </li>
  <li *ngIf="user == '1'"
  [ngClass]="liclassname">
    <a (click)="signOut()" [ngClass]="linkclassname" tabindex="0">
      <span>
        <svg viewBox="0 0 24 24" width="100%" height="100%" aria-hidden="true">
          <path d="M18.984 3c1.078 0 2.016 0.938 2.016 2.016v13.969c0 1.078-0.938 2.016-2.016 2.016h-13.969c-1.125 0-2.016-0.938-2.016-2.016v-3.984h2.016v3.984h13.969v-13.969h-13.969v3.984h-2.016v-3.984c0-1.078 0.891-2.016 2.016-2.016h13.969zM10.078 15.609l2.578-2.625h-9.656v-1.969h9.656l-2.578-2.625 1.406-1.406 5.016 5.016-5.016 5.016z"></path>
        </svg>
      </span>
      Sign Out
    </a>
  </li>

</ul>
