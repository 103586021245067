import {
  Component,
  Input,
  Inject,
  ViewEncapsulation,
  OnDestroy,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, asyncScheduler } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebpService } from '@app/services';
import { OrganisationHeader, MenuItem, Content } from '@app/models';

@Component({
  selector: 'mjs-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent implements OnDestroy {

  @Input() content: Content;
  @Input() align: string;
  @Input() size: string;
  @Input() toggleMap: any;
  @Input() titlePrefix: string;

  header: null|OrganisationHeader;
  nav: MenuItem[];
  isOrg: boolean;
  isCat: boolean;
  classString: string;
  classes: string[];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    public webp: WebpService) {
      this.isOrg = false;
      this.isCat = false;
      this.classes = [];
    }

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.header = null;
        this.nav = [];
        this.isOrg = false;
        this.isCat = false;
        this.classString = '';
        this.classes = [];
        this.content = null;
        asyncScheduler.schedule(() => this.setUp(data['job'] || data['content']));
      });
  }

  setUp(content: Content): void {
    this.content = content;
    this.isOrg = this.isOrganisation();
    this.isCat = this.content && this.content['type'] && this.content['type'].vid === 'job_category';

    if (this.size) {
      this.classes = this.classes.concat([this.size]);
    }

    if (this.isCat) {
      this.classes = this.classes.concat(['small', 'category']);
    }

    if (this.isOrg) {
      this.classes = this.classes.concat([
        'small',
        'organisation',
        'd-flex',
        'flex-column',
        'justify-content-end',
        'image-header'
      ]);

      this.header = this.getOrganisationHeader();
    }
    else {
      if (this.content.org_menu) {
        this.nav = this.content.org_menu;
      }
    }

    if (this.content.header) {
      this.classes.push('image-header');
    }

    if (this.content.align_content && this.content.align_content !== 'left') {
      this.classes.push(`text-${this.content.align_content}`);
    }

    this.classString = this.classes.join(' ');
  }

  isOrganisation(): boolean {
    if (this.content && ((this.content.type
      && this.content.type.vid === 'organisation') || this.content.org)) {
      return true;
    }

    return false;
  }

  getOrganisationHeader(): OrganisationHeader {
    let data: any;
    let title: string;

    if (this.content && this.content.org_menu) {
      data = this.content;
      if (data && data.parent && !data.parent[0].target_id) {
        title = data.parent[0].title;
      } else {
        title = data.title;
      }
    }

    if (this.content && this.content.org) {
      data = this.content.org;
      title = data.title;
    }

    if (this.content && this.content.job) {
      data = this.content.job.org;
      title = data.title;
    }

    this.nav = data.org_menu;

    return {
      header: {
        large: this.content.header
          ? this.content.header.styles.header.url
          : data.header ? data.header.styles.header.url: '',
        small: this.content.header
          ? this.content.header.styles.header_small.url
          : data.header ? data.header.styles.header_small.url : ''
      },
      header_align: this.content.header_align
        ? this.content.header_align
        : data.header_align ? data.header_align : 'center',
      logo: data.logo,
      title,
      menu: data.org_menu
    };
  }

  headerImage(): string {
    if (this.content.header) {
      return this.content.header.styles.header.url;
    }

    if (this.header && this.header.header) {
      return this.header.header.large;
    }

    return '';
  }

  categoryTitle(): string {
    if (this.content.parent && !this.content.parent[0].hasOwnProperty('target_id')) {
      return this.content.parent[0].title;
    }

    return this.content.title;
  }

  categorySubtitle(): string {
    if (!this.content.parent
      || this.content.parent[0].hasOwnProperty('target_id')) {
      return '';
    }

    return this.content.title;
  }

  mapTitle() {
    return '<span class="organisation-map-title">' + this.content.org.title + ' </span>';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
