<div class="form-group custom-control custom-checkboxes" *ngIf="element" [formGroup]="parentForm">
  <fieldset>      
    <legend>{{ element.label }}</legend>
    <ng-container *ngFor="let item of element.settings.allowed_values | keyvalue">
      <input
        class="form-check-input"
        type="checkbox"
        [id]="item.key"
        [name]="element.field_name"
        [formControlName]="element.field_name">
      <label
        [for]="item.key"
        class="form-check-label">
        {{ item.value }}
      </label>
    </ng-container>
  </fieldset>
  <div *ngIf="submitted && errors" class="invalid-feedback" role="alert">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div>
</div>