import {
  Component,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'mjs-menu',
  templateUrl: './menu.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent {

  @Input() menu: [];
  @Input() classname: string;
  @Input() liclassname: string;
  @Input() linkclassname: string;
  @Input() user: string;
  @Input() recruiter: boolean;
  
  @Output() logout: EventEmitter<any> = new EventEmitter();

  constructor(private oauthService: OAuthService) {}

  public signOut(): void {
    this.oauthService.logOut();
    window.location.href = '/user/login';
  }

}
