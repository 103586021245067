<div class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1>404 Page not found</h1>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col col-md-8 text-left">
      <p>Try either reclicking your link or check the spelling of your url in the address bar.</p>
    </div>
    <aside class="col sidebar col-sm-12">
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </aside>
  </div>
</div>