import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Renderer2,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivationEnd,
  ActivatedRoute
} from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { fromEvent, Subscription, throwError, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
import { NavigationService } from './services/navigation.service';
import { Navigation, User } from '@app/models';
import { ProfileService } from '@app/services/profile.service';
import { SeoService } from '@app/shared';
import { authConfig } from './auth.config';
import { environment } from '@env/environment';
import { LumesseStatusService } from "@app/services/lumesse-status.service";
import { LumesseService } from "@app/services/lumesse.service";

declare var lumesseAnalytics: any;

@Component({
  selector: 'mjs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NavigationService, SeoService, LumesseStatusService],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

  currentAuth: User;
  currentAuthSubscription: Subscription;

  isMobile: boolean;
  isScrolled: boolean;
  menus: any;
  content: any;
  deviceInfo: any;
  previousUrl: string;
  offline: boolean;

  private componentBeforeNavigation = null;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public breakpointObserver: BreakpointObserver,
    private analytics: Angulartics2GoogleAnalytics,
    private oauthService: OAuthService,
    private toast: ToastrService,
    private navigation: NavigationService,
    private profileService: ProfileService,
    private seoService: SeoService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private lumesseStatus: LumesseStatusService,
    private lumesse: LumesseService) {

    this.isMobile = false;
    this.isScrolled = false;
    this.renderer.addClass(this.doc.body, environment.is);
    this.analytics.startTracking();
    this.angulartics2GoogleTagManager.startTracking();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.oauthService.configure(authConfig);
      // Silent oauth refresh.
      this.oauthService.setupAutomaticSilentRefresh();

      this.oauthService.events.subscribe((e: any) => {
        switch (e.type) {
          case 'user_profile_loaded':
            this.profileService.set();
            break;
          case 'user_profile_load_error':
            this.oauthError('', e);
            break;
          case 'token_received':

            break;
          case 'token_error':
            if (e.reason
              && e.reason.error
              && e.reason.error.error
              && e.reason.error.error === 'invalid_credentials') {
              this.oauthError(e.reason.error.message, e);
            } else {
              if (!e?.reason?.error?.message) {
                this.oauthError('Your session has expired, please sign in to continue', e);
              }
            }
            break;
          case 'token_refreshed':

            break;
          case 'token_refresh_error':
          case 'silent_refresh_error':
          case 'token_validation_error':
          case 'session_terminated':
          case 'session_error':
          case 'session_changed':
            this.oauthError('Your session has expired, please sign in to continue', e)
            break;
          case 'token_expires':
            this.oauthService.refreshToken().then(res => {
              this.profileService.set();
            })
            .catch(err => {
              this.oauthError('', err);
            });
            break;
          case 'logout':
            this.profileService.delete();
            break;
        }
      });

      this.toast.overlayContainer = this.toastContainer;

      if(typeof lumesseAnalytics != 'undefined'){
        // Initialize lumesse engage analytics
        lumesseAnalytics.init({
          trackingUrl: 'https://journey-service.tb.lumesse.com/acq',
          analyticUrl: 'https://message-broker.tb.lumesse.com/message/emea5_advert_interactions',
          technicalIdentifier: environment.lumesse_technical_id
        });
      }

      // Subscribe to scroll events.
      let scrollTop = this.doc.documentElement.scrollTop || this.doc.body.scrollTop;
      this.isScrolled = scrollTop >= 168;

      fromEvent(window, 'scroll', { passive: true })
        .pipe(debounceTime(100), takeUntil(this.onDestroy$))
        .subscribe(evt => {
          let scrollTop = this.doc.documentElement.scrollTop || this.doc.body.scrollTop;
          this.isScrolled = scrollTop >= 168;

          if (this.isScrolled) {
            this.renderer.addClass(this.doc.body, 'scrolled');
          } else {
            this.renderer.removeClass(this.doc.body, 'scrolled');
          }
        });

      this.breakpointObserver
        .observe(['(min-width: 961px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isMobile = false;
            this.renderer.removeClass(this.doc.body, 'mobile');
          } else {
            this.isMobile = true;
            this.renderer.addClass(this.doc.body, 'mobile');
          }
        });
    }

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(this.doc.body, `page--${this.previousUrl.replace(/\//g, '-')}`);
        }

        let currentUrlSlug = event.url.slice(1).split('?')[0];

        if (currentUrlSlug) {
          this.renderer.addClass(this.doc.body, `page--${currentUrlSlug.replace(/\//g, '-')}`);
        }

        this.previousUrl = currentUrlSlug;
      }

      if (event instanceof ActivationEnd) {
        if (event.snapshot.routeConfig.path == 'offline') {
          this.renderer.addClass(this.doc.body, 'site-offline');
          this.offline = true;
        } else {
          this.renderer.removeClass(this.doc.body, 'site-offline');
          this.offline = false;
          this.lumesseStatus.get().subscribe({
            next: res => this.lumesse.update(res),
            error: err => {}
          });
        }

        this.seoService.addTags(event.snapshot.data['content'] || {});
      }

      if (event instanceof NavigationEnd && isPlatformBrowser(this.platform)) {
        let currentRoute = this.route;

        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }

        if (this.componentBeforeNavigation !== currentRoute.component) {
          const skipLink: HTMLElement = this.doc.querySelector('.mjs-skip-to-content');
          if (window) window.scrollTo(0, 0);

          if (skipLink) {
            skipLink.focus();
            skipLink.blur();
          }
        }

        this.componentBeforeNavigation = currentRoute.component;
      }
    });

    // Fetch site navigation menus.
    this.navigation.getMenus()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (navigation: Navigation) => {
          this.menus = navigation;

          if (this.offline) {
            this.offline = false;
            this.router.navigate(['/']);
          }
        },
        error: error => throwError(error)
      });
  }

  private oauthError(message?: string, error?: any) {
    this.oauthService.logOut(true);
    this.profileService.delete();

    if (message) {
      this.toast.error(message);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
