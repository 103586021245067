import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';
import { ContentComponent } from './content.component';

const CONTENT_ROUTES: Routes = [{
  path: '',
  component: ContentComponent
},{
  path: ':page/:subPage',
  component: ContentComponent
}];

@NgModule({
  imports: [RouterModule.forChild(CONTENT_ROUTES)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
