import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LumesseService {

  private lumesseSource = new BehaviorSubject<boolean>(false);
  currentValue = this.lumesseSource.asObservable();

  constructor() { }

  update(lumesse) {
    this.lumesseSource.next(lumesse);
  }

}
