import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebpService } from '@app/services';
import loadjs from "loadjs";
import {isPlatformBrowser} from "@node_modules/@angular/common";

declare var Freshbots: any;

@Component({
  selector: 'mjs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {

  content: any;
  banner: any;
  auto: boolean;
  distance: number;
  location: string;
  latlng: string;
  keywords: string;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private route: ActivatedRoute,
    public webp: WebpService) {
    this.auto = false;
    this.distance = 10;
    this.keywords = '';
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
      this.banner = res['banner'];

      if (isPlatformBrowser(this.platformId)) {
        this.loadFreshDesk();
      }
    });
  }

  public search(): void {
    this.router.navigate(['/search'], {
      queryParams: {
        keywords: this.keywords,
        latlng: this.latlng,
        location: this.location,
        distance: 10
      }
    });
  }

  public updateLocation(event: any): void {
    this.latlng = event.latlng;
    this.location = event.location;
  }

  public updateKeywords(event: any): void {
    this.keywords = event;
  }

  loadFreshDesk() {
    loadjs([
      `https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js`
    ], {
      before: (path, el) => {
        switch (path) {
          case `https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js`:
            el.setAttribute('data-self-init', 'false');
            el.setAttribute('data-init-type', 'opt');
            el.setAttribute('id', 'spd-busns-spt');
            el.setAttribute('async', 'async');
            el.setAttribute('data-client', '26fd47938488100260b10b4d1f870332580b71ee');
            el.setAttribute('data-bot-hash', '8d3a56676db1dfb9370cbc3b52c00d06e82695f8');
            el.setAttribute('data-env', 'prod');
            el.setAttribute('data-region', 'euc');
            break;
        }
      },
      success: () => {
        Freshbots.initiateWidget({
          autoInitChat: false,
          getClientParams: function () {
            return;
          }
        });
      },
      error: (err: any) => {}
    });
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId) && Freshbots) {
      Freshbots.deActivate();
    }
  }

}
