const defaultSettings = {
  required: true,
  type: 'string_textfield',
  settings: {
    min_length: 1,
    max_length: 255
  }
};

const tokenOrderForm = [{
  field_name: 'tokens',
  label: 'Number of Tokens',
  required: true,
  description: 'How many tokens would you like to buy?',
  type: 'number'
}, {
  field_name: 'first_name',
  label: 'Forename',
  ...defaultSettings
}, {
  field_name: 'last_name',
  label: 'Surname',
  ...defaultSettings
}, {
  field_name: 'address_line1',
  label: 'Billing Address Line 1',
  ...defaultSettings
}, {
  field_name: 'address_line2',
  label: 'Billing Address Line 2',
  ...defaultSettings,
  required: false
}, {
  field_name: 'locality',
  label: 'Billing Address City',
  ...defaultSettings
}, {
  field_name: 'postal_code',
  label: 'Billing Address Postcode',
  ...defaultSettings
}];

export default tokenOrderForm;
