import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
  Renderer2,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LocationsService } from '@app/shared/services/locations.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { of } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";

interface LocationValue {
  location: string;
  lat: string;
  lng: string;
}

@Component({
  selector: 'mjs-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationComponent implements OnInit, OnDestroy {

  @ViewChild('keywordInput', {static: true}) keywordInput: ElementRef;

  @Input() latlng: string;
  @Input() distance: string;
  @Input() location: string;
  @Input() auto: boolean = false;
  @Input() showDistance: boolean = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() unlockFacets: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  open: boolean;
  list: any;
  query: string;
  locationSelection: boolean;
  isSearching: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platform: String,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private locationsService: LocationsService) {
    this.form = new FormGroup({ });
    this.open = false;
    this.locationSelection = false;
  }

  public ngOnInit(): void {
    this.renderer.addClass(this.doc.body, 'search-header-active');
    const val = this.location ? {
      location: this.location,
      lat: this.latlng.split(',')[0],
      lng: this.latlng.split(',')[1]
    } : null;
    this.form.addControl('location', new FormControl<LocationValue|null>(val));

    if (this.auto || this.showDistance) {
      this.form.addControl('distance', new FormControl<Number>(parseInt(this.distance, 10) || 10));
    }

    this.form.controls['location'].valueChanges.pipe(
      map((value: any) => value)
      ,filter(res => res.length > 2)
      ,debounceTime(750)
      ,distinctUntilChanged()
      ).subscribe((text: string) => {
        this.isSearching = true;
        this.getLocations(text).subscribe((res)=>{
          this.isSearching = false;
          this.list = res;
        },(err)=>{
          this.isSearching = false;
        });
      });
  }

  displayFn(item: any): string {
    return item ? item.location : '';
  }

  getLocations(text: string): any {
    if (text === '') {
      return of([]);
    }

    return this.locationsService.get(text);
  }

  hasDistance(): boolean {
    return this.auto || this.showDistance;
  }

  onClickSuggestion(item: any): void {
    this.locationSelection = true;
    this.latlng = `${item.value.lat},${item.value.lng}`;
    this.location = item.value.location;
    this.navigate();
  }

  onChangeDistance(val: any) {
    this.distance = val;
    this.navigate();
  }

  navigate(): void {
    if (this.auto) {
      const params: any = {};
      params.latlng = this.latlng ?? '';
      params.location = this.latlng ? this.location : '';
      params.distance = this.latlng ? this.distance : '';
      params.page = 1;

      this.unlockFacets.emit();
      this.router.navigate([this.router.url.split('?')[0]], {
        queryParams: params,
        queryParamsHandling: 'merge'
      });
    } else {
      this.valueChange.emit({
        latlng: this.latlng,
        location: this.location,
        distance: Number(this.distance)
      });
    }
  }

  clearLocation(): void {
    this.latlng = '';
    this.distance = '';
    this.location = '';
    this.form.controls['location'].patchValue('');
    this.navigate();
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.doc.body, 'search-header-active');
  }
}
