import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class PaypalTransactionService {

  constructor(private http: HttpClient) {}

  post(data: any) {
    return this.http.post(`${environment.apiUrl}/api/v1/job-tokens/paypal-transaction`, data);
  }
  
}
