<mjs-recruiter-header *ngIf="content" [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <div class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <div [innerHtml]="content.body" *ngIf="content.body"></div>
      <ul class="organisations-list search my-orgs" *ngIf="content && content.admin_menu && content.admin_menu.organisations && content.title !== 'Pages'">
        <li *ngFor="let item of content.admin_menu.organisations">
          <a [routerLink]="item.path">
            <div class="small-org-image">
              <img [src]="item.logo" alt="">
            </div>
            <h3 [innerHtml]="item.title"></h3>
          </a>
        </li>
      </ul>
    </div>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
