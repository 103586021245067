import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '@app/shared/services/file.service';

@Component({
  selector: 'mjs-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  providers: [FileService],
  encapsulation: ViewEncapsulation.None
})
export class FileComponent implements OnDestroy {

  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;
  @Input() uploadUrl: string;
  @Input() entityType: string;
  @Input() entityBundle: string;

  loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private toast: ToastrService,
    private fileService: FileService) { }

  fileName(file) {
    let filename = decodeURI(file.split('/').reverse()[0]);

    return filename;
  }

  removeItem(index) {
    let value = [];
    if (this.element.preview[index]) {
      this.element.preview.splice(index, 1);
    }

    for (let i = 0; i < this.parentForm.controls[this.element.field_name].value.length; i += 1) {
      if (i !== index) {
        value.push(this.parentForm.controls[this.element.field_name].value[i].target_id);
      }
    }

    this.parentForm.controls[this.element.field_name].setValue(value);
  }

  onChange(event) {
    if (event.target.files && event.target.files.length) {
      this.loading = true;

      for (let i = 0; i < event.target.files.length; i += 1) {
        let file = event.target.files[i];
        let type = file.type;
        let name = file.name;
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.fileService.post(name, type, reader.result,
            this.element.settings.file_directory, this.entityType, this.entityBundle,
            this.element.field_name).pipe(takeUntil(this.onDestroy$)).subscribe({
              next: (res: any) => {
                if (!this.element.preview) {
                  this.element.preview = [];
                }

                this.element.preview.push(res.url);
                this.loading = false;
                let value = [];

                for (let i = 0; i < this.parentForm.controls[this.element.field_name].value.length; i += 1) {
                  if (typeof this.parentForm.controls[this.element.field_name].value[i] === 'object' && this.parentForm.controls[this.element.field_name].value[i].target_id) {
                    value.push(this.parentForm.controls[this.element.field_name].value[i].target_id.toString());
                  }

                  if (typeof this.parentForm.controls[this.element.field_name].value[i] === 'string') {
                    value.push(this.parentForm.controls[this.element.field_name].value[i].toString());
                  }
                }

                value.push(res.fid.toString());
                this.parentForm.controls[this.element.field_name].patchValue(value);
              },
              error: (err) => {
                this.toast.error(err.error.message);
                this.loading = false;
              }
            });
        };
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
