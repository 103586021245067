import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from '@app/guards/logged-in.guard';
import { LoggedOutGuard } from '@app/guards/logged-out.guard';
import { SearchFormResolver } from '@app/modules/search/services/search-form.resolver';
import {
    SearchesComponent,
    SavedSearchComponent,
    SettingsComponent,
    ProfileComponent,
    LikesComponent,
    ApplicationsComponent,
    RegisterComponent,
    ResetComponent,
    SignInComponent,
    ChangePasswordComponent
} from '@app/modules/user/components';
import { ContentResolver } from '@app/services/content.resolver';
import { NotFoundComponent } from '@app/shared';
import { CompleteRegistrationComponent } from './components/complete-registration/complete-registration.component';
import { PasswordUpdateGuard } from '@app/guards/password-update-guard';

const USER_ROUTES: Routes = [{
    path: '',
    redirectTo: '/applications',
    pathMatch: 'full'
}, {
    path: 'login',
    component: SignInComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver },
}, {
    path: 'activate',
    component: SignInComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'register',
    component: RegisterComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'register/organisation',
    component: RegisterComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'complete-registration',
    component: CompleteRegistrationComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'reset',
    component: ResetComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'confirm-account',
    component: ResetComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [LoggedOutGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'applications',
    component: ApplicationsComponent,
    canActivate: [LoggedInGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'incomplete-expired-applications',
    component: ApplicationsComponent,
    canActivate: [LoggedInGuard],
    resolve: { content: ContentResolver }
}, {
    path: 'favourites',
    component: LikesComponent,
    canActivate: [LoggedInGuard],
    data: { redirect: '/user/favourites' },
    resolve: { content: ContentResolver }
}, {
    path: 'personal',
    component: ProfileComponent,
    canActivate: [LoggedInGuard],
    data: { redirect: '/user/personal' },
    resolve: { content: ContentResolver }
}, {
    path: 'alerts',
    component: SearchesComponent,
    canActivate: [LoggedInGuard],
    data: { redirect: '/user/alerts' },
    resolve: { content: ContentResolver }
}, {
    path: 'alert',
    component: SavedSearchComponent,
    canActivate: [LoggedInGuard],
    data: { redirect: '/user/alert' },
    resolve: { filters: SearchFormResolver, content: ContentResolver },
}, {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [LoggedInGuard],
    canDeactivate: [PasswordUpdateGuard],
    data: { redirect: '/user/settings' },
    resolve: { content: ContentResolver }
}];

@NgModule({
    imports: [RouterModule.forChild(USER_ROUTES)],
    exports: [RouterModule]
})
export class UserRoutingModule { }
