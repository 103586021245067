import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class RequestAccessToManageService {

  constructor(private http: HttpClient) {}

  post(id) {
    return this.http.post(`${environment.apiUrl}/api/v2/recruiter/request-access/${id}?_format=json`, {})
  }

}
