import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: '[app-email]',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailComponent implements OnInit {
  
  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;

  public ngOnInit(): void {
    this.element.class = '';

    if (this.element.required) {
      this.element.class += ' required';
    }

    if (this.element.container_class) {
      this.element.class += ' ' + this.element.container_class;
    }
  }
}
