import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Renderer2
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IPayPalConfig } from "@modules/ngx-paypal/models/paypal-models";
import { OAuthService } from "angular-oauth2-oidc";
import { OrderService } from '../../services/order.service';
import { environment } from '@env/environment';
import tokenOrderForm from './token-order.form';

@Component({
  selector: 'mjs-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
  providers: [OrderService],
  encapsulation: ViewEncapsulation.None
})
export class BuyComponent implements OnInit, OnDestroy {

  params: any;
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;
  order: any;
  formData = tokenOrderForm;
  payPalConfig: IPayPalConfig;
  paypalOrder: any;
  loadingMessage: string;
  quantity: number;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private renderer: Renderer2,
              private toast: ToastrService,
              private orderService: OrderService,
              private oauth: OAuthService) {
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];

      if (this.content.org && this.content.org.redirect) {
        this.toast.error(this.content.org.redirect.message);
        this.router.navigate([this.content.org.redirect.path]);
      }
    });
  }

  public valueChanges(event): void {
    this.quantity = event.tokens;
    if (parseInt(event.tokens, 10) > 0) {
      const cost = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      }).format(this.content.org.token_cost * parseInt(event.tokens, 10));

      this.formData[0].description = `Cost: ${cost} + VAT (If applicable)`;
    } else {
      this.formData[0].description = `How many tokens would you like to buy?`;
    }
  }

  public itemPrice() {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(this.content.org.token_cost);
  }

  public submit(params: any): void {
    this.initConfig(params);
  }

  public displayPrice(price): string {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: price.currency_code
    }).format(price.value);
  }

  private initConfig(params): void {
    this.payPalConfig = {
      clientId: environment.paypal_client_id,
      currency: 'GBP',
      createOrderOnServer: async (data) => {
        this.loading = true;

        try {
          const response = await fetch(environment.apiUrl + '/api/v1/job-tokens/order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.oauth.getAccessToken()}`,
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              ...this.content.org,
              ...params.params
            })
          });

          const order = await response.json();

          if (!order.orderID) {
            throw new Error();
          }

          this.paypalOrder = order;
          this.loading = false;

          return order.orderID;
        } catch (error) {
          this.loading = false;
          this.loadingMessage = '';
          this.toast.error(error?.error || 'Sorry, we were unable to create your order. Please try again');
        }
      },
      authorizeOnServer: async (data, actions) => {
        this.loading = true;
        this.loadingMessage = 'Please wait while we process your payment';

        this.renderer.selectRootElement('main', true).scrollIntoView();

        try {
          const response = await fetch(environment.apiUrl + '/api/v1/job-tokens/order', {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.oauth.getAccessToken()}`,
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              ...this.content.org,
              ...params.params,
              orderID: data.orderID
            })
          });

          const res = await response.json();

          if (!res.id) {
            throw new Error();
          }

          this.router.navigate([`${this.content.org.path}/job-tokens`], {
            queryParams: {
              message: 'order_payment_successful'
            }
          });
        } catch(error) {
          this.loading = false;
          this.loadingMessage = '';
          this.toast.error(error?.error || 'Sorry, something went wrong. Please try again');
        }
      },
      onCancel: (data, actions) => {
        this.router.navigate([`${this.content.org.path}/job-tokens`]);
      },
      onError: err => {
        this.loading = false;
        this.loadingMessage = '';
      }
    };
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
