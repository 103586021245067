import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Renderer2,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common'
import { Subject, asyncScheduler } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ParamsService } from '@modules/recruiter/services/params.service';
import { OrganisationService } from '@modules/recruiter/services/organisation.service';
import { OrganisationPageService } from '@modules/recruiter/services/organisation-page.service';

@Component({
  selector: 'mjs-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [ParamsService, OrganisationService, OrganisationPageService],
  encapsulation: ViewEncapsulation.None
})
export class EditComponent implements OnInit, OnDestroy {

  entityType: string;
  entityBundle: string;
  entityMode: string;
  entityId: number;
  isBrowser: boolean;
  params: any;
  content: any;
  profile: any;
  loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platform: string,
    private toast: ToastrService,
    private paramsService: ParamsService,
    private organisationService: OrganisationService,
    private organisationPageService: OrganisationPageService) {

    this.isBrowser = isPlatformBrowser(this.platform);
  }

  public ngOnInit(): void {
    this.loading = true;
    this.route.data.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      if (isPlatformBrowser(this.platform)) {
        this.loading = true;
        this.entityType = data['entityType'];
        this.entityBundle = data['entityBundle'];
        this.content = data['content'];

        switch (this.entityType) {
          case 'taxonomy_term':
            this.entityMode = 'my_organisations_edit';
            this.entityId = this.content.org.id
            break;
          default:
            this.entityMode = 'organisation_page';
            this.entityId = this.route.snapshot.params['page'];
        }

        asyncScheduler.schedule(() => {
          this.loading = false;
        }, 500);
      }
    });
  }

  get renderForm(): boolean {
    if (!this.isBrowser) {
      return false;
    }

    if (this.loading) {
      return false;
    }

    if (this.entityId && this.entityType && this.entityBundle) {
      return true;
    }

    return false;
  }

  submit(data: any) {
    this.loading = true;
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();
    const body = this.paramsService.format(data.params, data.structure, 'organisation');

    if (this.entityType === 'taxonomy_term') {
      this.organisationService.patch(this.content.org.id, body)
        .pipe(takeUntil(this.onDestroy$)).subscribe({
          next: data => {
            this.loading = false;
            this.router.navigate([this.router.url]);
            this.toast.success('Your changes have been saved');
          },
          error: err => {
            this.loading = false;
            this.toast.error(err);
          }
        });
    } else if (this.entityType === 'node') {
      this.organisationPageService.patch(this.entityId, body)
        .pipe(takeUntil(this.onDestroy$)).subscribe({
          next: data => {
            this.loading = false;
            this.router.navigate([this.router.url]);
            this.toast.success('Your changes have been saved');
          },
          error: err => {
            this.loading = false;
            this.toast.error(err);
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
