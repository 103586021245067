<form [formGroup]="form"
  [ngClass]="{'d-flex': auto, 'has-distance position-relative': hasDistance()}"
  class="no-after justify-content-stretch location-field">
  <div class="no-after d-flex w-100">
    <div class="form-group no-after position-relative">
      <input type="text"
             class="form-control"
             placeholder="Postcode or town"
             aria-label="Location"
             matInput
             formControlName="location"
             [matAutocomplete]="auto"
             aria-label="Search by postcode or town"
      >
      <div role="status" class="location-loader" role="status" [hidden]="!isSearching">
        <span class="sr-only">Loading...</span>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <button class="clear-input" *ngIf="location" (click)="clearLocation()"
              type="button" [hidden]="isSearching">
        <span class="sr-only">Clear location search</span>
        <svg viewBox="0 0 32 32" width="100%" height="100%" aria-hidden="true">
          <path
            d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z">
          </path>
        </svg>
      </button>
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)='onClickSuggestion($event.option)'
        [aria-label]="'Search suggestions for location search by postcode or town'">
        <mat-option *ngFor="let item of list" [value]="item">
          {{ item.location + ' [' + item.postcode + ']' }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="form-group no-after" *ngIf="hasDistance()">
      <label for="search-distance" class="sr-only">Distance from location</label>
      <select
        id="search-distance"
        (change)="onChangeDistance($event.target?.['value'])"
        class="form-control"
        name="distance"
        formControlName="distance">
        <option value="5">5 mi</option>
        <option value="10">10 mi</option>
        <option value="20">20 mi</option>
        <option value="50">50 mi</option>
        <option value="100">100 mi</option>
      </select>
    </div>
  </div>
</form>
