<!-- <ng-container *ngIf="data">
  <ng-container *ngFor="let i of Array(Math.round(data.length / 4)); let _i = index">
    <div class="card-deck">
      <div
        class="card shadow-sm mb-4"
        *ngFor="let e of data.slice((_i * 4), ((_i * 4) + 4))">
        <img
          *ngIf="e.header"
          class="card-img-top"
          alt=""
          defaultImage="/assets/img/defaults/default-header.svg"
          [errorImage]="e.header"
          [lazyLoad]="webp.defaultImage(e.header)"
        />
        <div class="card-body">
          <h5 class="h5 card-title" [innerHtml]="e.title"></h5>
          <p
            *ngIf="e.subtitle"
            class="card-text"
            [innerHtml]="e.subtitle"
          ></p>
        </div>
        <div class="card-footer">
          <small class="text-muted">
            <a
              [routerLink]="e.path"
              class="stretched-link d-block text-left">
              Read more <span class="sr-only" [innerHtml]="'about ' + e.title"></span>
              <svg
                aria-hidden="true"
                focusable="false"
                class="bi bi-chevron-right float-right"
                width="16"
                height="28"
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </small>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container> -->
<div class="row">
  <div class="col col-12 col col-sm-6 col-md-4 col-lg-3 mb-4" *ngFor="let e of data">
    <div class="card career-hub-page-grid-item shadow-sm h-100">
      <a [routerLink]="e.path" class="d-block overflow-hidden">
        <img *ngIf="e.header" class="card-img-top" alt=""
             defaultImage="/assets/img/defaults/default-header.svg"
             [lazyLoad]="e.header_small || e.header"
        />
      </a>
      <div class="card-body px-3 py-2">
        <h5 class="h6 m-0 card-title">
          <a [routerLink]="e.path" class="stretched-link d-block text-decoration-none text-dark" [innerHtml]="e.title"></a>
        </h5>
        <!--        <p *ngIf="e.subtitle" class="card-text" [innerHtml]="e.subtitle"></p>-->
      </div>
<!--      <div class="card-footer">-->
<!--        <small class="text-muted">-->
<!--          <a [routerLink]="e.path" class="stretched-link d-block text-left">-->
<!--            Read more <span class="sr-only" [innerHtml]="'about ' + e.title"></span>-->
<!--            <svg aria-hidden="true" focusable="false"-->
<!--              class="bi bi-chevron-right float-right" width="16" height="28"-->
<!--              viewBox="0 0 20 20" fill="currentColor"-->
<!--              xmlns="http://www.w3.org/2000/svg">-->
<!--              <path fill-rule="evenodd"-->
<!--                d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"-->
<!--                clip-rule="evenodd"></path>-->
<!--            </svg>-->
<!--          </a>-->
<!--        </small>-->
<!--      </div>-->
    </div>
  </div>
</div>
