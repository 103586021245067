<div class="p-3">
  <h2 id="confirm-saved-search-heading" class="m-0">Check your alert</h2>
  <p class="border-bottom pb-2 mb-2">You have selected the following criteria:</p>
  <div class="mb-1 pb-2 border-bottom" *ngIf="dialogData['params']['keywords']">
    <p class="mb-0"><strong>Keywords</strong></p>
    <span class="badge badge-info mr-1 mb-1" [innerHtml]="dialogData['params']['keywords']"></span>
  </div>
  <div class="mb-1 pb-2 border-bottom" *ngIf="dialogData['params']['location']">
    <p class="mb-0"><strong>Location</strong></p>
    <span class="badge badge-info mr-1 mb-1" [innerHtml]="dialogData['params']['location']"></span>
  </div>
  <ng-container *ngFor="let elem of dialogData['filters'] | keyvalue | orderBy: 'value.weight'">
    <div class="mb-1 pb-2 border-bottom" *ngIf="this.checkboxes[elem.key] && this.checkboxes[elem.key].length">
      <p class="mb-0"><strong [innerHtml]="dialogData['filters'][elem.key]['label']"></strong></p>
      <ng-container *ngFor="let item of this.checkboxes[elem.key]">
        <span *ngIf="filters[elem.key][item]" class="badge badge-info mr-1 mb-1" [innerHtml]="filters[elem.key][item]['name']"></span>
      </ng-container>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions class="d-flex shadow border-top justify-content-between">
  <button type="button" class="btn btn-secondary" mat-dialog-close>Cancel</button>
  <button class="btn btn-primary" [mat-dialog-close]="true">Confirm</button>
</div>
