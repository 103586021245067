import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';
import { OrganisationsComponent } from './organisations.component';

const ORGANISATIONS_ROUTES: Routes = [{
  path: '',
  component: OrganisationsComponent
}];

@NgModule({
  imports: [RouterModule.forChild(ORGANISATIONS_ROUTES)],
  exports: [RouterModule]
})
export class OrganisationsRoutingModule { }
