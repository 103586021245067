import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class TokenService {

  constructor(private http: HttpClient) {}

  list(org:  number, page: number) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/tokens?_format=json&org=${org}&page=${page - 1}`);
  }

}
