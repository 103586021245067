import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  Inject,
  PLATFORM_ID,
  Renderer2,
  Input
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Observable,
  Subscription,
  Subject,
  fromEvent,
  asyncScheduler
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuItem } from '@app/models';

@Component({
  selector: 'mjs-fluid-menu',
  templateUrl: './fluid-menu.component.html',
  styleUrls: ['./fluid-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FluidMenuComponent implements OnInit, OnChanges {

  @Input() nav: MenuItem[];

  navItemsWidths: number[];
  hiddenNav: MenuItem[];
  visibleNav: MenuItem[];
  displayNone: boolean = false;

  private element: HTMLElement;
  private rendererMenu: HTMLUListElement;
  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2) {
    this.navItemsWidths = [];
    this.nav = [];
    this.hiddenNav = [];
    this.visibleNav = [];
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.element = this.renderer.selectRootElement('#fluid-menu', true);
      this.rendererMenu = this.renderer.selectRootElement('#rendered-menu', true);

      this.resizeObservable$ = fromEvent(window, 'resize');
      this.resizeSubscription$ = this.resizeObservable$
        .pipe(takeUntil(this.onDestroy$)).subscribe(() => this.calculateWidths());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    asyncScheduler.schedule(() => this.calculateWidths(), 250);
  }

  private calculateWidths(): void {
    if (isPlatformServer(this.platformId) || !this.nav || this.nav.length == 0) {
      return;
    }

    this.displayNone = false;

    this.navItemsWidths = [];
    const collection: HTMLCollection = this.rendererMenu.children;

    for (let i = 0; i < collection.length; i += 1) {
      this.navItemsWidths.push(collection.item(i).getBoundingClientRect().width);
    }

    this.sizeMenu();
  }

  private sizeMenu(): void {
    asyncScheduler.schedule(() => {
      let total = 0;
      this.visibleNav = [];
      this.hiddenNav = [];
      const availableWidth = this.element.getBoundingClientRect().width;

      for (let i = 0; i < this.nav.length; i += 1) {
        total += this.navItemsWidths[i];
        this[total < availableWidth ? 'visibleNav' : 'hiddenNav'].push(this.nav[i]);
      }

      this.displayNone = true;
    }, 250);
  }

}
