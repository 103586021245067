import { Component, OnInit, Input, ViewEncapsulation, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from "@angular/material/select";

interface Term {
  id: number;
  label: string;
  children?: any[];
  filteredChildren?: any[];
}

@Component({
  selector: '[app-select]',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnInit, AfterViewInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;

  @ViewChild('singleSelect') singleSelect: MatSelect;

  clearSearchInput: boolean = true;
  filterValuesCtrl: UntypedFormControl = new UntypedFormControl(null);
  filteredValues: ReplaySubject<Term[]> = new ReplaySubject<Term[]>(1);

  private _onDestroy = new Subject<void>();

  ngOnInit(): void {
    if (this.element.settings.allowed_values[0] && !this.element.settings.allowed_values[0].label) {
      const values = [];

      for (let i = 0; i < this.element.settings.allowed_values.length; i += 1) {
        values.push({
          label: this.element.settings.allowed_values[i],
          id: i,
          value: i,
        });
      }

      if (this.element.value) {
        this.element.value = this.element.value;
      }

      this.element.settings.allowed_values = values;
    }

    this.element.class = '';

    if (this.element.required) {
      this.element.class += 'required';
    }

    if (this.element.container_class) {
      this.element.class += ' ' + this.element.container_class;
    }

    // load the initial value list
    this.filteredValues.next([...this.element.settings.allowed_values].slice());

    // listen for search field value changes
    this.filterValuesCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterValues();
      });
  }

  ngAfterViewInit() {
    this.element.settings.allowed_values.forEach(item => {
      if (item.children) {
        item.filteredChildren = item.children.slice();
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange(e) { }

  private resetValues() {
    this.element.settings.allowed_values.forEach((item, index) => {
      if (item.children) {
        this.element.settings.allowed_values[index].filteredChildren = this.element.settings.allowed_values[index].children.slice();
      }
    });
  }

  /**
   * Sets the initial value after the filteredValues are loaded initially
   */
  private setInitialValue() {
    this.filteredValues
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Term, b: Term) => a.id === b.id;
      });
  }

  private filterValues() {
    if (!this.element.settings.allowed_values) {
      return;
    }

    // get the search keyword
    let search = this.filterValuesCtrl.value;

    if (!search) {
      this.resetValues();
      this.filteredValues.next(
        [...this.element.settings.allowed_values].slice(),
      );
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredValues.next(
      [...this.element.settings.allowed_values].filter((elem, i) => {
          if (elem.children) {
            elem.filteredChildren = [...elem.children.slice()];

            elem.filteredChildren = elem.filteredChildren.filter(
              child => child.label.toLowerCase().indexOf(search) > -1
            )

            return elem.filteredChildren.length !== 0;
          }

          return elem.label.toLowerCase().indexOf(search) > -1
        }
      )
    );
  }
}
