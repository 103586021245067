import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class SiteMessageService {

  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/site-message`);
  }
  
}
