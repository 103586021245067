<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <div [innerHtml]="content.body" *ngIf="content.body && !loading"></div>
      <ng-container *ngIf="!organisationSearchCompleted && !selected">
        <p class="mb-2"><strong>To begin, please enter the organisation name and we will check if it is already registered with myjobscotland.</strong></p>
        <form [formGroup]="organisationSearch">
          <div class="form-group">
            <label class="font-weight-bold" for="organisation_name">Search organisations</label>
            <input
              type="text"
              formControlName="organisation_name"
              id="organisation_name"
              [ngClass]="{ 'is-invalid': submitted && f['organisation_name'].errors }"
              class="form-control"
              autocomplete="off"
            >
            <div *ngIf="submitted && f['organisation_name'].errors" class="invalid-feedback">
              <p *ngIf="f['organisation_name'].errors['required']">Organisation name is required</p>
            </div>
            <div class="form-text"><p class="my-1">If you see your organisation listed below please select it and we'll send a request to have you approved as an admin. Alternatively, to register a new organisation click the button  below.</p>
            <p><button (click)="skipCheck()" class="btn btn-primary">Register new organisation</button></p>
            </div>
            <div class="description">
              <div class="claim-suggestions">
                <ul class="m-0 bg-white list-group" *ngIf="suggestions && suggestions.length > 0 && focused">
                  <li class="list-group-item p-0" *ngFor="let item of suggestions">
                    <button class="btn btn-link w-100 text-left" (click)="selectSuggestion(item)">
                      {{ item['title'] }}
                    </button>
                  </li>
                  <li class="list-group-item p-0">
                    <button (click)="skipCheck()" class="btn btn-link w-100 text-left">Register new organisation</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="selected">
        <p><strong>You have selected the following organisation. If you click confirm below, a request to manage this organisation will be sent on your behalf.</strong></p>
        <div class="mjs-selected-organisation" [routerLink]="selected.path">
          <div class="small-org-image float-left mr-2 mb-2">
            <img
              [alt]="selected.title"
              defaultImage="/assets/img/defaults/default-icon.svg"
              [errorImage]="selected.logo"
              [lazyLoad]="webp.defaultImage(selected.logo)"
            >
          </div>
          <h3 [innerHtml]="selected.title"></h3>
        </div>
        <form class="d-flex justify-content-between request-access-actions" [formGroup]="requestToManageForm" (submit)="submitRequestToManage()">
          <button type="button" class="btn btn-dark" (click)="cancel()">Cancel</button>
          <button type="submit" class="btn btn-primary">Request access</button>
        </form>
      </ng-container>
      <ng-container *ngIf="organisationSearchCompleted">
        <mjs-entity-form
          *ngIf="!loading"
          entity="taxonomy_term"
          bundle="organisation"
          mode="create_organisation"
          [params]="params"
          (submitForm)="submit($event)"
        ></mjs-entity-form>
      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content">
      </mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>