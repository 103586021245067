import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';
import { ContextTokenService } from './context-token.service';
import { User, ContextToken } from '@app/models';

@Injectable()
export class ContextTokenResolver implements Resolve<ContextToken|boolean> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private logger: NGXLogger,
    private oauthService: OAuthService,
    private tokenService: ContextTokenService) { }

  resolve() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    const profile = <User>this.oauthService.getIdentityClaims();

    return this.tokenService.getToken()
      .pipe(
        tap(content => {
          return of(content);
        }),
        catchError(error => {
          if (error.status === 403) {
            this.logger.debug(`Lumesse context token failed for user ${profile.mail}`);
            window.location.href = '/user/applications?message=application_authentication_error';
          }
          return of(error);
        })
      );
  }

}
