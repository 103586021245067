import {
  Component, Inject,
  OnInit, Renderer2,
  ViewEncapsulation
} from "@angular/core";
import {SearchesService} from "@modules/user/services";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {CreateAlertDialogData} from "@modules/search/components";

@Component({
  selector: 'mjs-confirm-saved-search',
  templateUrl: '../confirm-saved-search/confirm-saved-search.component.html',
  styleUrls: ['../confirm-saved-search/confirm-saved-search.component.scss'],
  providers: [SearchesService],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmSavedSearchComponent implements OnInit {

  filters: any = {};
  checkboxes: any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: CreateAlertDialogData,
    private renderer: Renderer2) {
  }
  ngOnInit() {
    for (let k in this.dialogData['filters']) {
      if (!this.filters[k]) this.filters[k] = {};

      for (let e in this.dialogData['filters'][k]['options']) {
        const option = this.dialogData['filters'][k]['options'][e];

        if (option['children']) {
          for (let a in option['children']) {
            if (!this.filters[k][a]) {
              this.filters[k][a] = option['children'][a];
            }
          }

          delete option['children'];
        }

        this.filters[k][e] = option;
      }
    }

    for (let k in this.dialogData['params']) {
      switch (k) {
        case 'parent_org':
        case 'parent_cat':
          let i = k.replace('parent_', '');
          if (!this.checkboxes.hasOwnProperty(i)) {
            this.checkboxes[i] = [];
          }

          this.checkboxes[i] = this.checkboxes[i].concat(this.dialogData['params'][k]);
          break;

        default:
          if (!this.checkboxes.hasOwnProperty(k)) {
            this.checkboxes[k] = [];
          }

          this.checkboxes[k] = this.checkboxes[k].concat(this.dialogData['params'][k]);
      }
    }

    for (let k in this.checkboxes) {
      if (this.checkboxes[k]) {
        this.checkboxes[k] = [...new Set(this.checkboxes[k])];
      }
    }
  }

}
