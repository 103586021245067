import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CategoryListItem } from '@app/shared/models';
import { CategoriesService } from '@app/shared/services/categories.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mjs-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  providers: [CategoriesService],
  encapsulation: ViewEncapsulation.None
})
export class CategoryListComponent implements OnInit, OnDestroy {

  list: CategoryListItem[];
  limit: number;
  toggled: boolean;
  
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private service: CategoriesService) {
    this.limit = 16;
  }

  public ngOnInit(): void {
    this.service.get().pipe(takeUntil(this.onDestroy$)).subscribe(
      data => this.list = data
    );
  }

  public toggleList(): void {
    this.limit = this.limit == 16 ? 100 : 16;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
