<mjs-page-header
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body | safe"></div>
      <app-password-expiration-alert></app-password-expiration-alert>
      <mjs-entity-form
        *ngIf="!loading"
        entity="user"
        [entity_id]="profile.id"
        bundle="user"
        mode="settings"
        [params]="params"
        (submitForm)="submit($event)"
      ></mjs-entity-form>
    </main>
    <div class="col sidebar">
      <mjs-menu
        *ngIf="content && content.menu"
        [menu]="content.menu"
        [recruiter]="recruiter"
        [user]="'1'"
        [classname]="'sidebar-user-menu'">
      </mjs-menu>
      
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>