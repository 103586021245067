import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of, EMPTY } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ContentService } from './content.service';
import { Content } from '@app/models';
import {MjsJobPreviewService} from "@app/services/mjs-job-preview.service";
import {MjsJob} from "@models/mjs-job.model";

@Injectable()
export class MjsJobPreviewResolver implements Resolve<MjsJob|boolean> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private transferState: TransferState,
              private router: Router,
              private mjsJobPreviewService: MjsJobPreviewService) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    const nPostingTargetId = route.queryParamMap.get('nPostingTargetId');

    if (nPostingTargetId) {
      return this.mjsJobPreviewService.get(nPostingTargetId)
        .pipe(
          tap(content => {
            if (content.hasOwnProperty('existing_job')) {
              this.router.navigate(['/' + content['existing_job']]);
            }

            return of(content);
          }),
          catchError(error => {
            // Redirect to 404 component which serves a 404 status code to the server.
            this.router.navigate(['/404'], {skipLocationChange: true});
            return EMPTY;
          })
        );

    } else {
      this.router.navigate(['/404'], {skipLocationChange: true});
    }

    return of(false);
  }

}
