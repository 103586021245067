import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnInit,
  Renderer2
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { ParamsService } from '../../services/params.service';
import { OrganisationService } from '../../services/organisation.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClaimOrganisationService} from "@modules/recruiter/services";
import {WebpService} from "@app/services";
import {
  RequestAccessToManageService
} from "@modules/recruiter/services/request-access-to-manage.service";

@Component({
  selector: 'mjs-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  providers: [ParamsService, OrganisationService, ClaimOrganisationService, RequestAccessToManageService],
  encapsulation: ViewEncapsulation.None
})
export class CreateComponent implements OnInit {

  params: any = {};
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;
  organisationSearch: FormGroup;
  organisationSearchCompleted: boolean;
  suggestions: [];
  selected: any;
  focused: boolean;
  submitted: boolean;
  requestToManageForm: FormGroup;
  requestToManageLoading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
    private organisationService: OrganisationService,
    private toast: ToastrService,
    private oauthService: OAuthService,
    private claimOrganisations: ClaimOrganisationService,
    public webp: WebpService,
    private requestAccess: RequestAccessToManageService) {

    this.organisationSearch = new FormGroup<any>({
      organisation_name: new FormControl<string>('', [Validators.required])
    });

    this.requestToManageForm = new FormGroup<any>({
      organisations: new FormControl<string>('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.organisationSearch.controls['organisation_name']
      .valueChanges
      .pipe(debounceTime(500))
      .subscribe(keywords => {
        this.claimOrganisations
          .get(keywords)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((res: any) => {
            this.suggestions = res;
            this.focused = true;
          });
      });

    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
        let org_type = queryParams.get('org_type');

        if (org_type) {
          this.params.field_organisation_type = org_type;
        } else { }
      });
  }

  skipCheck() {
    this.organisationSearchCompleted = true;
  }

  selectSuggestion(item): void {
    this.selected = item;
    this.organisationSearch.patchValue({autocomplete: item.title});
    this.focused = false;
    this.requestToManageForm.controls['organisation'].patchValue(item.tid);
  }

  get f() { return this.organisationSearch.controls; }

  cancel() {
    this.selected = null;
    this.requestToManageForm.controls['organisation'].setValue('');
  }

  public submit(data: any): void {
    this.loading = true;
    this.params = data.params;
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();
    const body = this.paramsService.format(data.params, data.structure, 'organisation');

    this.organisationService.post(body)
      .pipe(takeUntil(this.onDestroy$)).subscribe(
      (res: any) => {
        this.oauthService.refreshToken().then(data => {
          this.oauthService.loadUserProfile().then((data: any) => {
            this.toast.success('Your organisation has been created and will be reviewed by Cosla');
            this.router.navigate(['/my-organisations/' + res.path.split('/').reverse()[0]]);
          })
        })
      },
      err => {
        this.loading = false;
        this.toast.error(err.error || err.error.message || 'Sorry, something went wrong');
      }
    );
  }

  submitRequestToManage() {
    this.requestToManageLoading = true;
    this.requestAccess.post(this.selected.tid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (res) => {
          this.requestToManageLoading = false;
          this.toast.success(res['message']);
          this.selected = null;
          this.router.navigate(['/my-organisations']);
        },
        error: (err) => {
          this.requestToManageLoading = false;
          this.toast.error(err.error.message || err.error || 'Sorry, something went wrong');
        }
      });
  }
}

