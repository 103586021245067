import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class ClaimOrganisationService {

  constructor(private http: HttpClient) {}

  get(name: string) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/organisations?name=${name}&_format=json`);
  }

  post(data) {
    return this.http.post(`${environment.apiUrl}/api/v1/recruiter/claim?_format=json`, {
      ...data
    })
  }

}
