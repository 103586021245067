import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CouncilMapService } from '@shared/services/council-map.service';

@Component({
  selector: 'mjs-council-map',
  templateUrl: './council-map.component.html',
  styleUrls: ['./council-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CouncilMapService]
})
export class CouncilMapComponent implements OnInit {
  
  @Input() councils: any;
  @Input() type: 'standard' | 'website' | 'volunteering' | 'employment_benefits';

  list: any;
  council: any;

  constructor(private councilService: CouncilMapService,
    private router: Router) {
    if (!this.type) {
      this.type = 'standard';
    }
  }

  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnInit(): void {
    if (!this.councils) {
      this.councilService.get()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data: any) => {
          this.list = data;

          for (let i = 0; i < this.list.length; i += 1) {
            this.list[i].opacity = (Math.random() * (0.400 - 1.000) + 1).toFixed(4);
          }
      });
    }
  }

  public submit(event) {
    event.preventDefault();

    if (this.council === 0 || this.council > 0) {
      this.selectCouncil(this.council);
    }
  }

  public selectCouncil(index): any {
    switch (this.type) {
      case 'website':
        if (this.list[index].website) {
          let url = this.urlType(this.list[index].website);

          if (url.type == 'external') {
            return window.open(url.url,'_blank');
          }

          return window.location.href = url.url;
        }
        break;
      case 'volunteering':
        if (this.list[index].volunteering) {
          let url = this.urlType(this.list[index].volunteering);

          if (url.type == 'external') {
            return window.open(url.url,'_blank');
          }

          return window.location.href = url.url;
        }
        break;
      case 'employment_benefits':
        if (this.list[index].employment_benefits) {
          let url = this.urlType(this.list[index].employment_benefits);

          if (url.type == 'external') {
            return window.open(url.url,'_blank');
          }

          return window.location.href = url.url;
        }
        break;
    }

    this.router.navigate([this.list[index].path]);
  }

  public urlType(url):any {
    if (
      url.substring(0, 4).toLowerCase() === 'http' ||
      url.substring(0, 2).toLowerCase() === '//' ||
      url.charAt(3) === '.'
    ) {
      return {
        type: 'external',
        url: `//${url.replace('http://', '').replace('https://', '')}`
      };
    }

    return {
      type: 'internal',
      url
    }
  }

  public ngOnDestroy():void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
