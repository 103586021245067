import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'  
})
export class PasswordUpdateGuard implements CanDeactivate<unknown> {  

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: any
  ) { }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | UrlTree {  
    if (isPlatformServer(this.platform)) {
      return true;  
    }
    const profile: any = this.oauthService.getIdentityClaims();

    if (profile && profile.field_password_expiration) {
      // Redirect them back to the settings if the password needs to be updated
      return this.router.parseUrl('/user/settings');
    }
    return true;  
  }
}
