<section class="site-message" [hidden]="siteMessages.length === 0" aria-live="polite">
  <div *ngFor="let message of siteMessages; let i = index">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="position-relative">
            <svg viewBox="0 0 1024 1024" width="20"  height="20">
              <path *ngIf="message.type == 'warning' || message.type == 'alert'" [attr.fill]="message.type == 'alert' ? '#ff3b30' : '#ff9500'" d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM470 298h84v256h-84v-256zM470 640h84v86h-84v-86z"></path>
              <path *ngIf="!message.type || message.type == 'info'" fill="#308091" d="M470 384v-86h84v86h-84zM512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM470 726v-256h84v256h-84z"></path>
              <path *ngIf="message.type == 'success'" fill="#4cd964" d="M426 726l384-384-60-62-324 324-152-152-60 60zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
            </svg>
            <p [innerHtml]="message.message"></p>
            <button (click)="dismissMessage(i)" *ngIf="message.dismiss">
              <span class="sr-only">Dismiss this message</span>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" aria-hidden="true">
                <path d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
