<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <div [innerHtml]="content.body" *ngIf="content.body"></div>

      <form
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      *ngIf="!loading"
      class="entity-form">
        <div class="form-group required">
          <label for="autocomplete">Organisation Search <span class="required-flag">[Required]</span></label>
          <input
            [type]="'text'"
            formControlName="autocomplete"
            id="autocomplete"
            [ngClass]="{ 'is-invalid': submitted && f.autocomplete.errors }"
            class="form-control"
            (keyup)="onKeyUp($event)"
          >
          <div *ngIf="submitted && f.autocomplete.errors" class="invalid-feedback">
            <p *ngIf="f.autocomplete.errors.required">Organisation name is required</p>
          </div>
          <div class="description">
            <div class="claim-suggestions" *ngIf="suggestions && suggestions.length > 0 && focused">
              <ul>
                <li *ngFor="let item of suggestions">
                  <button (click)="selectSuggestion(item)">
                    {{ item['title'] }} <small>({{ item['tid'] }})</small>
                  </button>
                </li>
              </ul>
            </div>
            <p>Type to search registered organisations. Please note that only only unclaimed organisations will show here. If yours doesn't appear please contact your organisation who can manage access.</p></div>
        </div>
        <div class="form-group required">
          <label for="field_service_provider_number">Service Provider Number <span class="required-flag">[Required]</span></label>
          <input
            [type]="'text'"
            formControlName="field_service_provider_number"
            id="field_service_provider_number"
            [ngClass]="{ 'is-invalid': submitted && f.field_service_provider_number.errors }"
            class="form-control"
          >
          <div *ngIf="submitted && f.field_service_provider_number.errors" class="invalid-feedback">
            <p *ngIf="f.field_service_provider_number.errors.required">Service provider number is required</p>
          </div>
          <div class="description"><p>Please enter your organisation service provider number.</p></div>
        </div>
        <div class="form-group custom-select required">
          <label for="field_charity">Are you a registered charity? <span class="required-flag">[Required]</span></label>
          <select
            type="select"
            formControlName="field_charity"
            id="field_charity"
            [ngClass]="{ 'is-invalid': submitted && f.field_charity.errors }"
            class="form-control custom-select"
            required
          >
            <option [value]="undefined">-- select --</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          <div *ngIf="submitted && f.field_charity.errors" class="invalid-feedback">
            <p *ngIf="f.field_charity.errors.required">Charity field is required</p>
          </div>
        </div>
        <div class="form-group required">
          <label for="field_charity_number">Charity Number <span class="required-flag">[Required]</span></label>
          <input
            [type]="'text'"
            formControlName="field_charity_number"
            id="field_charity_number"
            [ngClass]="{ 'is-invalid': submitted && f.field_charity_number.errors }"
            class="form-control"
          >
          <!-- <div *ngIf="submitted && errors" class="invalid-feedback">
            <p *ngIf="errors.required">Service provider number is required</p>
          </div>
          <div class="description"><p>Please enter your organisation service provider number.</p></div> -->
        </div>

        <div class="form-group my-0 border-0">
          <button class="btn btn-lg btn-primary pull-right">Submit</button>
        </div>
      </form>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>