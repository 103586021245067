import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganisationsService } from '@app/modules/organisations/organisations.service';

@Injectable()
export class OrganisationsResolver implements Resolve<any> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private organisationsService: OrganisationsService) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    const CONTENT_KEY = makeStateKey<any>(`organisation_results`);

    if (this.transferState.hasKey(CONTENT_KEY)) {
      const organisations = this.transferState.get<any>(CONTENT_KEY, null);
      this.transferState.remove(CONTENT_KEY);
      return of(organisations);
    }
    else {
      return this.organisationsService.get(route.queryParams)
      .pipe(
        tap(organisations => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(CONTENT_KEY, organisations);
          }
        })
     );
    }
  }

}
