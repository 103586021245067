<div class="mjs-post-navigation mt-3 py-5">
  <div class="container">
    <div class="row">
      <div class="col col-6 text-left">
        <a
          *ngIf="prev"
          [routerLink]="prev.url"
          class="mjs-post-navigation-link prev d-flex">
          <svg
            aria-hidden="false"
            focusable="false"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#308091"
            class="mr-2"
            viewBox="0 0 24 24">
            <path d="M20.016 11.016v1.969h-12.188l5.578 5.625-1.406 1.406-8.016-8.016 8.016-8.016 1.406 1.406-5.578 5.625h12.188z"></path>
          </svg>
          <span [innerHtml]="prev.title"></span>
        </a>
      </div>
      <div class="col col-6 text-right">
        <a
          *ngIf="next"
          [routerLink]="next.url"
          class="mjs-post-navigation-link next d-flex justify-content-end">
          <span [innerHtml]="next.title"></span>
          <svg
            aria-hidden="false"
            focusable="false"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#308091"
            class="ml-2"
            viewBox="0 0 24 24">
            <path d="M12 3.984l8.016 8.016-8.016 8.016-1.406-1.406 5.578-5.625h-12.188v-1.969h12.188l-5.578-5.625z"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
