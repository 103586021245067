import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';
import { NavigationService } from '@app/services/navigation.service';

@Component({
  selector: 'mjs-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfflineComponent implements OnInit, OnDestroy {
  content: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(public activatedRoute: ActivatedRoute,
    private storage: SessionStorageService,
    private navigation: NavigationService) { }

  public ngOnInit(): void {
    this.content = this.storage.retrieve('offlineContent');

    if (!this.content) {
      this.navigation.getMenus().pipe(takeUntil(this.onDestroy$)).subscribe(
        (res: any) => {

        },
        error => {
          this.content = this.storage.retrieve('offlineContent');
          throwError(error)
        }
      );
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
