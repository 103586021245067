import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class FavouritesService {

  constructor(private http: HttpClient) {}

  get(id?: any) {
    return this.http.get(`${environment.apiUrl}/api/v1/user/favourites?_format=json${id ? '&job=' + id : ''}`);
  }

  patch(id) {
    return this.http.get(`${environment.apiUrl}/api/v1/favourite?id=${id}`);
  }

}
