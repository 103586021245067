import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobRoutingModule } from './job-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { JobComponent } from './job.component';
import { SearchModule } from '@app/modules/search/search.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
  declarations: [
    JobComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    JobRoutingModule,
    SearchModule,
    LazyLoadImageModule,
    Angulartics2Module
  ]
})
export class JobModule { }
