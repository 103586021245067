import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MjsOrdersService } from '../../services/mjs-orders.service';
import { environment } from '@env/environment';
import jobTokensForm from './mjs-job-tokens';
import jobExtensionTokensForm from './mjs-job-extension-tokens';
import billingDetails from './billing-details';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'mjs-mjs-purchase',
  templateUrl: './mjs-purchase.component.html',
  styleUrls: ['./mjs-purchase.component.scss'],
  providers: [MjsOrdersService],
  encapsulation: ViewEncapsulation.None 
})
export class MjsPurchaseComponent implements OnInit, OnDestroy {

  params: any;
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;
  iframeReady: boolean = false;
  order: any;
  method: string = environment.paypalMethod;
  formData: any;
  fragment: string;
  purchaseTitle: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private oauthService: OAuthService,
    private mjsOrdersService: MjsOrdersService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];

      if (this.content.org && this.content.org.redirect) {
        this.toast.error(this.content.org.redirect.message);
        this.router.navigate([this.content.org.redirect.path]);
      }
    });

    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;

      if (isPlatformBrowser(this.platformId)) {
        this.profile = this.oauthService.getIdentityClaims();

        billingDetails[0]['default_value'] = this.profile.field_forename;
        billingDetails[1]['default_value'] = this.profile.field_surname;
      }

      switch (this.fragment) {
        case 'mjs-job-tokens':
          this.purchaseTitle = ' Job Tokens';
          this.formData = [...jobTokensForm, ...billingDetails];
          break;
        case 'mjs-job-extension-tokens':
          this.purchaseTitle = ' Job Extension Tokens';
          this.formData = [...jobExtensionTokensForm, ...billingDetails];
          break;
        default:
          this.purchaseTitle = '';
          this.formData = null;
      }
    });
  }

  valueChanges(event): void {
    if (parseInt(event.mjs_job_extension_tokens, 10) > 0) {

    }

    if (parseInt(event.mjs_job_tokens, 10) > 0) {
      let tokenCost = this.content.org.token_cost;
      let discount;

      if (this.content.org.hasOwnProperty('token_bulk_cost') && this.content.org.token_bulk_cost) {
        discount = this.applyDiscount(this.content.org.token_bulk_cost, parseInt(event.mjs_job_tokens, 10));

        if (discount) {
          tokenCost = discount.price;
        }
      }

      const cost = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      }).format(tokenCost * parseInt(event.mjs_job_tokens, 10));

      this.formData[0].description = `Cost: ${cost} + VAT (If applicable)`;

      if (discount) {
        const discountDisplayRate = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP'
        }).format(tokenCost);

        this.formData[0].description = `${this.formData[0].description} - [Discounted rate of ${discountDisplayRate} per token]`
      }
    } else {
      this.formData[0].description = `How many tokens would you like to buy?`;
    }
  }

  private applyDiscount(bulk_token_cost, quantity) {
    for (let i = 0; i < bulk_token_cost.length; i += 1) {
      if (quantity >= bulk_token_cost[i].threshold) {
        return bulk_token_cost[i];
      }
    }

    return false;
  }

  public submit(params: any): void {
    this.loading = true;

    this.mjsOrdersService
      .post({
        ...this.content.org,
        ...params.params
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data: any) => {
          this.router.navigate([`${this.content.org.path}/orders/${data.id}`])
        },
        error: err => {
          this.toast.error(err.error.error);
          this.loading = false;
        }
      });
  }

  public displayPrice(price): string {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: price.currency_code
    }).format(price.value);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
