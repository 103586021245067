import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'mjsJobAssetUrl'
})
export class MjsJobAssetUrlPipe implements PipeTransform {

  transform(val: string): string {
    if (environment.production) {
      return val.replace('http://127.0.0.1:8888', '');
    }

    return val;
  }

}
