<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="this.content && this.content.org">
          <li>
            <a
              [routerLink]="this.content.org.path + '/positions'"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              Positions
            </a>
          </li>
          <li>
            <a

              [routerLink]="this.content.org.path + '/positions/add'"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              Add Position
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <div *ngIf="!loading">
        <mjs-drupal-entity
          entity="mjs_position"
          bundle="mjs_position"
          mode="default"
          [entity_id]="entity_id"
          [params]="params"
          (submitForm)="submit($event)"
          [duplicatingJob]="duplicating">
        </mjs-drupal-entity>
      </div>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
