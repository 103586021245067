import { environment } from '../environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  tokenEndpoint: environment.tokenEndpoint,
  userinfoEndpoint: environment.profileEndpoint,
  clientId: environment.client_id,
  scope: environment.scopes,
  dummyClientSecret: environment.client_secret,
  oidc: false,
  showDebugInformation: environment.oauthDebug,
  strictDiscoveryDocumentValidation: false,
  requireHttps: false,
  waitForTokenInMsec: 0
};
