import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Messages } from '@app/models';

@Injectable()
export class MessagesService {
  apiUrl: string;
  store: any[];

  private currentMessagesSubject: BehaviorSubject<any>;
  public currentMessages: Observable<any>;

  constructor(private http: HttpClient) {
    this.apiUrl = '';
    this.currentMessagesSubject = new BehaviorSubject<any>([]);
    this.currentMessages = this.currentMessagesSubject.asObservable();
    this.currentMessagesSubject.next(this.http.get(`${environment.apiUrl}/api/v1/mapped_messages`));
  }

  public get currentValue(): BehaviorSubject<Messages> {
    return this.currentMessagesSubject;
  }

  get(): Observable<Messages> {
    return this.http.get<Messages>(`${environment.apiUrl}/api/v1/mapped_messages`)
    .pipe(
      tap(messages => {
        this.currentMessagesSubject.next(messages);
        return of(messages)
      })
    )
  }

}
