import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { UserRoutingModule } from './user-routing.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatDialogModule } from "@angular/material/dialog";
import { OrderModule } from 'ngx-order-pipe';
import { SharedModule } from '@app/shared/shared.module';
import { ProfileService } from '@app/services/profile.service';
import { SavedSearchComponent } from './components/saved-search/saved-search.component';
import { SearchModule } from '@app/modules/search/search.module';
import { DrupalEntityModule } from '@app/modules/drupal-entity/drupal-entity.module';
import {
  SearchesComponent,
  SettingsComponent,
  ProfileComponent,
  LikesComponent,
  ApplicationsComponent,
  RegisterComponent,
  ResetComponent,
  SignInComponent,
  ChangePasswordComponent
} from './components';
import { CompleteRegistrationComponent } from './components/complete-registration/complete-registration.component';
import { PasswordExpirationAlertComponent } from './components/password-expiration/password-expiration.component';
import { ConfirmSavedSearchComponent } from "./components/confirm-saved-search/confirm-saved-search.component";

@NgModule({
  declarations: [
    SearchesComponent,
    SettingsComponent,
    ProfileComponent,
    LikesComponent,
    ApplicationsComponent,
    RegisterComponent,
    ResetComponent,
    SignInComponent,
    ChangePasswordComponent,
    SavedSearchComponent,
    CompleteRegistrationComponent,
    PasswordExpirationAlertComponent,
    ConfirmSavedSearchComponent
  ],
  imports: [
    UserRoutingModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // MatPasswordStrengthModule,
    MatTableModule,
    SearchModule,
    OrderModule,
    FormsModule,
    DrupalEntityModule,
    MatDialogModule
  ],
  providers: [
    ProfileService
  ]
})
export class UserModule { }
