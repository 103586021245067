import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class RegisterService {

  constructor(private http: HttpClient) {}

  post(params: any) {
    const body: any = {
      name: ''
    };

    for (const key in params) {
      if (key.indexOf('confirm__') > -1) {
        continue;
      }

      if (key == 'roles') {
        continue;
      }

      if (key !== 'recaptcha' && key !== 'redirect') {
        body[key] = {value: params[key]};

        if (key === 'mail') {
          body.name = params[key];
        }
      }
    }

    let url = `${environment.apiUrl}/api/v1/user/register?`;

    if (params.recaptcha) {
      url = `${url}recaptcha=${params.recaptcha}&`;
    }

    if (params.redirect) {
      url = `${url}redirect=${params.redirect}&`;
    }

    if (params.roles) {
      for (let i = 0; i < params.roles.length; i += 1) {
        if (params.roles[i].target_id === 'organisation') {
          url = `${url}role=organisation`;
        }
      }
    }

    return this.http.post(url, {
      ...body
    });
  }

}
