import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mjs-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SocialShareComponent {
  
  @Input() title: string;
  @Input() image: string;
  @Input() link: string;
  @Input() summary: string

  twitterUrl(): string {
    return `https://twitter.com/intent/tweet?url=${this.link}&text=${encodeURI(this.title)}&via=myjobscotland&hashtags=#myjobscotland`;
  }

  facebookUrl(): string {
    return `https://www.facebook.com/sharer.php?u=${this.link}`;
  }

  linkedinUrl(): string {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${this.link}&title=${encodeURI(this.title)}&summary=${encodeURI(this.summary)}&source=${''}`
  }

  emailUrl(): string {
    return `mailto:?subject=${encodeURI(this.title)}&body=I saw this job on myjobscotland and thought you'd be interested. ${this.link}`;
  }

}
