import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { RegisterService } from '@app/modules/user/services';
import { Auth } from '@app/models';
import { ReCaptchaV3Service } from "ng-recaptcha";
import { LumesseService } from "@app/services/lumesse.service";

@Component({
  selector: 'mjs-register',
  templateUrl: './register.component.html',
  providers: [RegisterService],
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {

  currentAuth: Auth;
  currentAuthSubscription: Subscription;

  content: any;
  params: any;
  loading: boolean;
  organisation: boolean;
  hash: boolean;
  recaptcha: true;
  redirect: string;
  lumesseStatus: any;
  orgData: [{"label":"Email","type":"string_email","theme":"input__email","description":"","field_name":"mail","required":true,"maxlength":255,"name":"mail","cardinality":1,"settings":{"max_length":255},"value":""},{"label":"Password","type":"pass","field_name":"pass","description":"","required":true,"confirm":true,"maxlength":255,"field_type":"pass","cardinality":-10,"settings":{"max_length":255}},{"uuid":"68194d08-156d-471f-b608-35fc7e98356d","langcode":"en","status":true,"dependencies":{"config":["field.storage.user.field_forename"],"module":["user"]},"id":"user.user.field_forename","field_name":"field_forename","entity_type":"user","bundle":"user","label":"Forename","description":"","required":true,"translatable":false,"default_value":[],"default_value_callback":"","settings":{"max_length":255,"is_ascii":false,"case_sensitive":false},"field_type":"string","weight":1,"type":"string_textfield","cardinality":1,"displayOptions":{"region":"hidden"}},{"uuid":"e27a17ce-cddf-45d8-bdd0-44a0ba99b83d","langcode":"en","status":true,"dependencies":{"config":["field.storage.user.field_surname"],"module":["user"]},"id":"user.user.field_surname","field_name":"field_surname","entity_type":"user","bundle":"user","label":"Surname","description":"","required":true,"translatable":false,"default_value":[],"default_value_callback":"","settings":{"max_length":255,"is_ascii":false,"case_sensitive":false},"field_type":"string","weight":2,"type":"string_textfield","cardinality":1,"displayOptions":{"region":"hidden"}}]

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private registerService: RegisterService,
    private toast: ToastrService,
    private logger: NGXLogger,
    private lumesse: LumesseService) {
    this.loading = false;
    this.renderer.addClass(this.doc.body, 'full-width');
    this.renderer.addClass(this.doc.body, 'gray');

    this.route.fragment.subscribe((fragment: string) => {
      if (!fragment) {
        this.hash = false;
      } else {
        this.hash = true;
      }

      this.organisation = fragment && fragment === 'register-organisation';
    })
  }

  ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.route.queryParams.subscribe(params => {
      this.redirect = params['redirect'];
    });

    if (isPlatformBrowser(this.platform)) {
      this.lumesse.currentValue.subscribe(res => this.lumesseStatus = res);
    }
  }

  get lumesseDisabled(): string {
    if (!this.lumesseStatus || this.lumesseStatus.lumesse_disable) {
      return 'Registration is currently unavailable due to problems communicating with the lumesse server.';
    }

    return '';
  }

  submit(params: any) {
    this.loading = true;

    params.params.roles = [{'target_id': 'rest_user'}];

    if (this.organisation) {
      params.params.roles.push({'target_id': 'organisation'});
    }

    params.params.redirect = this.redirect;

    this.recaptchaV3Service
      .execute('create_alert')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (token) => {
          params.params['recaptcha'] = token;
          this.register(params);
        },
        error: err => {
          this.params = params.params;
          this.loading = false;
          this.toast.error('Recaptcha validation failed');
        }
      });
  }

  private register(params: any) {
    this.registerService
      .post(params.params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data: any) => {
          this.router.navigate(['/thanks-registering']);
        },
        error: err => {
          this.params = params.params;
          this.loading = false;
          let message = '';

          if (err.error && err.error.message) {
            message = err.error.message;
          } else if (err.error && err.error.text) {
            message = err.error.text;
          } else {
            message = 'Sorry, something went wrong';
          }

          this.toast.error(message);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
