import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TokenService } from '../../services/token.service';
import { MessagesService } from '@app/services/messages.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

@Component({
  selector: 'mjs-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss'],
  providers: [TokenService],
  encapsulation: ViewEncapsulation.None
})
export class TokensComponent implements OnInit, OnDestroy {
  params: any;
  content: any;
  entity_id: number;
  loading: boolean;
  data: any;
  page = 1;
  messages: any;
  columns = ['id', 'created', 'status', 'type', 'tokens', 'paid', 'ref'];
  showActions: boolean = environment.paypalMethod === 'sdk';

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private messagesService: MessagesService,
    private toast: ToastrService) {
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.messages = this.messagesService.currentValue;

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
      this.page = parseInt(queryParams.get('page'), 10) || 1;

      if (isPlatformBrowser(this.platform)) {
        this.search();
      }
    });
  }

  search() {
    this.loading = true;

    this.tokenService.list(this.content.org.id, this.page)
    .pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.data = data;

        for (let i = 0; i < data.list.length; i += 1) {
          if (data.list[i].created) {
            data.list[i].created = parseInt(data.list[i].created, 10) * 1000;
          }
          if (data.list[i].status == 'Pending') {
            this.toast.warning(this.messages.value.pending_tokens.message);
          }
        }
      },
      error: _ => {
        this.loading = false;
      }
    });
  }

  toggleElementActions(element) {
    for (let i = 0; i < this.data.list.length; i += 1) {
      if (element.id === this.data.list[i].id) {
        this.data.list[i].active = !this.data.list[i].active;
      }
      else {
        this.data.list[i].active = false;
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
