<div class="form-group" *ngIf="element" [formGroup]="parentForm" [ngClass]="{ required: element.required }">
  <label [for]="element.field_name">{{ element.label }} <span *ngIf="element.required" class="required-flag">[Required]</span></label>
  <select
    type="select"
    [formControlName]="element.field_name"
    [id]="element.field_name"
    [ngClass]="{ 'is-invalid': submitted && errors }"
    class="form-control"
    [required]="element.required || null"
  >
    <option [value]="undefined">-- select --</option>
    <ng-container *ngFor="let option of element.settings.allowed_values | keyvalue">
      <option
        [disabled]="element.settings.hierarchial && option.value?.['children']"
        [value]="option.key">
        {{ option.value?.['label'] ? option.value['label'] : option.value }}
      </option>
      <ng-container *ngIf="option.value?.['children'] && option.value?.['children']">
        <option
          *ngFor="let subOption of option.value?.['children'] | keyvalue"
          [value]="subOption.key">
          {{ subOption.value?.['label'] ? subOption.value['label'] : subOption.value }}
        </option>
      </ng-container>
    </ng-container>
  </select>
  <div *ngIf="submitted && errors" class="invalid-feedback" role="alert">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div>
</div>

<div class="form-group" *ngIf="element && element.settings.child_values && parentForm.controls[element.field_name].value" [formGroup]="parentForm" [ngClass]="{ required: element.required }">
  <label [for]="element.field_name + '__child'">{{ element.settings.child_label }} <span *ngIf="element.required" class="required-flag">[Required]</span></label>
  <select
    type="select"
    [formControlName]="element.field_name + '__child'"
    [id]="element.field_name + '__child'"
    [ngClass]="{ 'is-invalid': submitted && errors }"
    class="form-control custom-select"
    [required]="element.required || null"
  >
    <option [value]="undefined">-- select --</option>
    <option
      *ngFor="let option of element.settings.child_values[parentForm.controls[element.field_name].value] | keyvalue"
      [value]="option.key">
      {{ option.value }}
    </option>
  </select>
  <!--<div *ngIf="submitted && errors" class="invalid-feedback" role="alert">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div> -->
</div>
