import {
  Component,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Menu, MenuItem } from '@app/models';

@Component({
  selector: 'mjs-post-navigation',
  templateUrl: './post-navigation.component.html',
  styleUrls: ['./post-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostNavigationComponent implements OnChanges {
  
  @Input('url') path: string;
  @Input() menu: Menu;

  prev: MenuItem;
  next: MenuItem;

  ngOnChanges(changes: SimpleChanges): void {
    this.findMenuItem(this.menu, 'prev');
    this.findMenuItem(this.menu, 'next');
  }

  private findMenuItem(items: Menu, direction: 'prev' | 'next') {
    for (let i = 0; i < items.length; i += 1) {
      if(items[i].url === this.path) {

        if (direction === 'prev' && (i - 1) > -1) {
          this.prev = items[i - 1];
        }

        if (direction === 'next' && (i + 1) <= (items.length - 1)) {
          this.next = items[i + 1];
        }
      }

      if (items[i].children) {
        this.findMenuItem(items[i].children, direction);
      }
    }
  }

}
