<div cdkTrapFocus role="dialog" aria-modal="true">
  <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close modal">
    <span aria-hidden="true">&times;</span>
  </button>
  <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
    {{ title }}
  </div>
  <div *ngIf="message && options.enableHtml"
    [class]="options.messageClass" [innerHTML]="message">
  </div>
  <div *ngIf="message && !options.enableHtml"
    [class]="options.messageClass" [attr.aria-label]="message">
    {{ message }}
  </div>
</div>
