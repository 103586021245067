import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(private oauthService: OAuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: any) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformServer(this.platform)) {
      return true;
    }

    let hasAccessToken = this.oauthService.hasValidAccessToken();

    if (hasAccessToken) {
      return true;
    } else {
      let refreshToken = this.oauthService.getRefreshToken();

      if (refreshToken) {
        return this.oauthService.refreshToken().then(res => {
          return true;
        })
        .catch(err => {
          return this.redirect(state);
        })
      }

      return this.redirect(state);
    }
  }

  redirect = state => this.router.navigate(['/user/login'], {
    queryParams: {
      redirect: state.url
    }
  })

}
