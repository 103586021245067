<form [formGroup]="form">
  <div class="form-group">
    <label for="search-sort" class="sr-only">Order search results</label>
    <select id="search-sort"
      class="form-control"
      formControlName="sort">
      <option *ngFor="let option of options | keyvalue" 
        [value]="option.key">
        {{ option.value.label }}
      </option>
    </select>
  </div>
</form>

<!-- (change)="onChange($event.target.value)" -->