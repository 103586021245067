<div class="form-group image-input" 
  *ngIf="element" 
  [formGroup]="parentForm" 
  [ngClass]="{
    required: element.required
  }">

  <label [for]="element.field_name">
    {{ element.label }} 
    <span *ngIf="element.required" class="required-flag">[Required]</span>
  </label>

  <div class="form-type-image-preview" 
    *ngIf="element.cardinality === 1 && (element?.value && element?.value?.preview || imagePreviewUrl) && !this.loading">
    <img [src]="element?.value?.preview ?? imagePreviewUrl" alt="">
  </div>
  
  <label [for]="element.field_name" *ngIf="!element?.value?.preview && !this.loading">
    <input
      [type]="element.value ? 'hidden' : 'file'"
      [id]="element.field_name"
      [ngClass]="{ 'is-invalid': submitted && errors }"
      (change)="onChange($event)"
      class="form-control"
    >
    <span>Choose a file…</span>
  </label>

  <mjs-loader *ngIf="this.loading"></mjs-loader>
  
  <div *ngIf="submitted && errors" class="invalid-feedback" role="alert">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  
  <div class="description d-flex" *ngIf="element.description || element?.value?.preview">
    
    <p class="mb-0">{{ element.description }}</p>
    
    <button class="btn btn-outline-primary" *ngIf="element?.value?.preview || imagePreviewUrl" type="button" (click)="removeItem(element)">
      Remove <span class="sr-only">image</span>
    </button>
  
  </div>

</div>


<!-- <div class="form-group image-input" *ngIf="element" [formGroup]="parentForm" [ngClass]="{ required: element.required }">
  <label [for]="element.field_name">{{ element.label }} <span *ngIf="element.required" class="required-flag">[Required]</span></label>
  <div class="form-type-image-preview" *ngIf="element.cardinality === 1 && element.preview && !this.loading">
    <img [src]="element.preview" alt="">
  </div>
  <label [for]="element.field_name" *ngIf="!element.preview && !this.loading">
    <input
      [type]="element.value ? 'hidden' : 'file'"
      [id]="element.field_name"
      [ngClass]="{ 'is-invalid': submitted && errors }"
      (change)="onChange($event)"
      class="form-control"
    >
    <span>Choose a file…</span>
  </label>
  <mjs-loader *ngIf="this.loading"></mjs-loader>
  <div *ngIf="submitted && errors" class="invalid-feedback">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description d-flex" *ngIf="element.description || element.preview">
    <p class="mb-0">{{ element.description }}</p>
    <button class="btn btn-outline-primary" *ngIf="element.preview" type="button" (click)="removeItem(element)">
      Remove <span class="sr-only">image</span>
    </button>
  </div>
</div>
   -->