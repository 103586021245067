import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareerHubComponent } from './career-hub.component';
import { CareerHubHomeComponent } from './components/career-hub-home/career-hub-home.component';
import { CareerHubPageComponent } from './components/career-hub-page/career-hub-page.component';
import { CareerHubArticlesComponent } from './components/career-hub-articles/career-hub-articles.component';
import { CareerHubArticleComponent } from './components/career-hub-article/career-hub-article.component';
import { CareerHubSearchComponent } from './components/career-hub-search/career-hub-search.component';
import { JourneysResolver } from './services/journeys.resolver';
import { ContentResolver } from '@app/services/content.resolver';

const routes: Routes = [{
  path: '',
  component: CareerHubPageComponent,
  resolve: { content: ContentResolver },
},{
  path: 'search',
  component: CareerHubSearchComponent,
  resolve: { content: ContentResolver }
},{
  path: 'blog',
  component: CareerHubArticlesComponent,
  resolve: { content: ContentResolver, journeys: JourneysResolver }
},{
  path: 'blog/:article',
  component: CareerHubArticleComponent,
  resolve: { content: ContentResolver }
},{
  path: ':page',
  component: CareerHubPageComponent,
  resolve: { content: ContentResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':page/:subPage',
  component: CareerHubPageComponent,
  resolve: { content: ContentResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},{
  path: ':page/:subPage/:subSubPage',
  component: CareerHubPageComponent,
  resolve: { content: ContentResolver },
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CareerHubRoutingModule { }
