<mjs-career-hub-header [content]="content"></mjs-career-hub-header>
<div class="container">
  <div class="full-width">
    <div class="career-hub-carousel" *ngFor="let journey of journeys; let i = index">
      <ng-container *ngIf="journey.articles && journey.articles.list.length > 0">
        <h2 [innerHtml]="journey.title" class="mjs-heading"></h2>
        <div class="career-hub-carousel-navigation">
          <button
            [disabled]="journey.articles.page == 1"
            class="career-hub-carousel-nav back"
            (click)="loadMore(i, 'back')">
            Back
          </button>
          <button
            [disabled]="journey.articles.page == journey.articles.pages || journey.articles.pages < 2"
            class="career-hub-carousel-nav next"
            (click)="loadMore(i, 'forward')">
            Forward
          </button>
        </div>
        <div class="career-hub-carousel--articles row">
          <article class="col-12 col-md-4" *ngFor="let item of journey.articles.list" @fadeIn>
            <div class="fade-in">
              <picture>
                <img [src]="item.header" alt="">
              </picture>
            </div>
            <h3 class="fade-in"><a [routerLink]="item.path" [innerHtml]="item.title"></a></h3>
            <p class="fade-in" [innerHtml]="item.summary"></p>
          </article>
        </div>
        <ul class="career-hub-journey-dots justify-content-center d-flex mb-5 pb-5" *ngIf="journey.articles.pages > 1">
          <li class="mx-1" *ngFor="let page of dotsPagination(journey)">
            <button (click)="loadPage(i, page)" [attr.data-page]="page"
                    [ngClass]="{ active: (page + 1) == journey.articles.page }">
            </button>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
