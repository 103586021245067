import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SitemapRoutingModule } from './sitemap-routing.module';
import { SitemapComponent } from './sitemap.component';

import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [SitemapComponent],
  imports: [
    CommonModule,
    SitemapRoutingModule,
    SharedModule
  ]
})
export class SitemapModule { }
