<mjs-page-header
  [align]="'text-center'"
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col text-center d-flex justify-content-center mx-auto">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body | safe"></div>
      <form
        *ngIf="!loading"
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        class="password-reset-form w-100">
        <div class="form-group">
          <label for="password" class="font-weight-bold text-left d-block">New Password</label>
          <div class="field-input-inner-wrap position-relative">
            <input
              placeholder="New Password"
              [type]="!hide ? 'password' : 'text'"
              [formControl]="f['password']"
              id="password"
              [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
              class="form-control"
            >
            <button class="show-hide-form-value" 
            (click)="hide = !hide" 
            [attr.aria-label]="'Hide password'" 
            [attr.aria-pressed]="hide"
            type="button">
            <svg *ngIf="hide" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 6a9.77 9.77 0 0 1 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5A9.77 9.77 0 0 1 12 6m0-2C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5a2.5 2.5 0 0 1 0 5a2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/></svg>
            <svg *ngIf="!hide" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 6a9.77 9.77 0 0 1 8.82 5.5a9.647 9.647 0 0 1-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68A11.738 11.738 0 0 0 1 11.5C2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42l1.41-1.41L3.42 2.45L2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 0 1-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75a4.6 4.6 0 0 0-.36 1.78a4.507 4.507 0 0 0 6.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 0 1-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53z"/></svg>
            </button>
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
              <div *ngIf="form.get('password').hasError('required')">Password is required</div>
              <div *ngIf="form.get('password').hasError('minlength')">Password must be at least 10 characters long</div>
              <div *ngIf="form.get('password').hasError('pattern')">Password does not meet the requirements below.</div>
            </div>
          </div>
          <!-- Help text -->
          <small id="passwordHelp" class="form-text text-muted text-left">
            Your password must contain at least 10 characters, be a mix of upper & lowercase letters, and contain at least one number or one special character. Allowed special characters: ! @ # $ % ^ & * ( ) _ +. For guidance on staying secure online, please visit <a href="https://www.ncsc.gov.uk/collection/top-tips-for-staying-secure-online/three-random-words" target="_blank" rel="noopener noreferrer" class="highlighted-link">NCSC</a>. 
          </small>
        </div>
        <div class="form-group">
          <label for="passwordConfirm" class="font-weight-bold text-left d-block">Confirm Password</label>
          <div class="field-input-inner-wrap position-relative">
            <input
              placeholder="Retype password"
              [type]="!hideConfirm ? 'password' : 'text'"
              [formControl]="f['passwordConfirm']"
              id="passwordConfirm"
              [ngClass]="{ 'is-invalid': submitted && f.passwordConfirm.errors }"
              class="form-control"
            >
            <button class="show-hide-form-value" 
            (click)="hideConfirm = !hideConfirm" 
            [attr.aria-label]="'Hide password'" 
            [attr.aria-pressed]="hideConfirm"
            type="button">
            <svg *ngIf="hideConfirm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 6a9.77 9.77 0 0 1 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5A9.77 9.77 0 0 1 12 6m0-2C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5a2.5 2.5 0 0 1 0 5a2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/></svg>
            <svg *ngIf="!hideConfirm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 6a9.77 9.77 0 0 1 8.82 5.5a9.647 9.647 0 0 1-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68A11.738 11.738 0 0 0 1 11.5C2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42l1.41-1.41L3.42 2.45L2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 0 1-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75a4.6 4.6 0 0 0-.36 1.78a4.507 4.507 0 0 0 6.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 0 1-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53z"/></svg>
            </button>
            <div *ngIf="submitted && f.passwordConfirm.errors || submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f['passwordConfirm'].hasError('required')">Confirm password is required</div>
              <div *ngIf="f['passwordConfirm'].hasError('mustMatch')">Passwords must match</div>
            </div>
          </div>
        </div>
        <div class="form-group my-0">
          <button class="btn btn-primary btn-block">Change password</button>
        </div>
      </form>
    </main>
</div>
