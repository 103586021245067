<ng-container *ngIf="data">
  <div
    class="career-hub-header"
    [errorImage]="data.header ? data.header.styles.header.url : ''"
    [lazyLoad]="webp.defaultImage(data.header ? data.header.styles.header.url : '')"
  >
    <div class="career-hub-navigation position-relative">
      <div class="container">
        <div class="row">
          <div class="col col-12 py-0 d-flex justify-content-between">
            <div class="breadcrumbs d-none d-sm-block">
              <mjs-breadcrumbs
                *ngIf="data.menu || data.field_menu"
                [menu]="data.menu || data.field_menu"
                [url]="data.path"
              ></mjs-breadcrumbs>
            </div>
            <div class="career-hub-search position-relative" *ngIf="!searchPage">
              <form [formGroup]="form" (submit)="onSubmit()">
                <div class="form-group m-0">
                  <mat-form-field appearance="outline">
                    <label for="searchTerm" class="sr-only">Keyword Search</label>
                    <input
                      type="text"
                      matInput
                      formControlName="searchTerm"
                      placeholder="Search"
                      [matAutocomplete]="auto">
                  </mat-form-field>
                  <mat-autocomplete
                    class="career-hub-autocomplete"
                    panelWidth="280"
                    #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let option of searchResults"
                      [value]="option.path"
                      (click)="selectSearchResult()"
                      [innerHtml]="option.title"
                    ></mat-option>
                  </mat-autocomplete>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col col-12">
          <a class="career-hub-logo" routerLink="/career-hub">
            <img src="/assets/img/branding/career-hub-logo.svg" alt="Career Hub">
          </a>
          <div class="row no-gutters">
            <div class="col col-md-8 offset-md-2">
              <h1 [innerHtml]="data.title"></h1>
              <p *ngIf="data.subtitle || data.journey" [innerHtml]="data.subtitle || data.journey.name"></p>
              <!-- <mjs-social-share
                [title]="data.meta.title"
                [image]="data.meta.image"
                [link]="data.meta.canonical_url"
                [summary]="data.meta.description">
              </mjs-social-share> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
