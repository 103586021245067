<ng-container *ngIf="!offline">
  <mjs-header [menus]="menus" [scrolled]="isScrolled"
    [mobile]="isMobile"></mjs-header>
  <mjs-site-messages></mjs-site-messages>
</ng-container>
<div role="main" id="main-content">
  <router-outlet></router-outlet>
</div>
<div>
  <div toastContainer></div>
</div>
<ng-container *ngIf="!offline">
  <mjs-footer [menus]="menus"></mjs-footer>
  <ngx-loading-bar [color]="'#308091'" [includeSpinner]="false"
    [height]="'3px'"></ngx-loading-bar>
</ng-container>
