<mjs-page-header
  align="center"
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body | safe"></div>
      <div class="col col-md-8 offset-md-2 bg-white shadow-sm">
        <nav class="tabs registration-tabs">
          <ul class="list-unstyled">
            <li class="w-50 float-left">
              <a
                queryParamsHandling="merge"
                routerLink="."
                fragment="register-candidate"
                class="btn btn-link btn-block"
                aria-controls="register-candidate"
                [attr.aria-current]="!organisation ? 'page' : undefined">
                For candidates
                <span class="d-block small">Click here if you are looking for a job</span>
              </a>
            </li>
            <li class="w-50 float-left">
              <a
                queryParamsHandling="merge"
                routerLink="."
                fragment="register-organisation"
                class="btn btn-link btn-block"
                aria-controls="register-organisation"
                [attr.aria-current]="organisation ? 'page' : undefined">
                For advertisers
                <span class="d-block small">Click here if you want to advertise a job</span>
              </a>
            </li>
          </ul>
        </nav>
        <ng-container *ngIf="hash">
          <div class="py-3" id="register-candidate" [hidden]="organisation">
            <p class="p-3 text-center" *ngIf="lumesseDisabled"><strong [innerHtml]="lumesseDisabled"></strong></p>
            <div *ngIf="!lumesseDisabled">
              <h2>Create your candidate account</h2>
              <mjs-loader *ngIf="loading"></mjs-loader>
              <mjs-entity-form
                entity="user"
                bundle="user"
                mode="register"
                [params]="params"
                *ngIf="!loading && !organisation"
                [recaptcha]="true"
                (submitForm)="submit($event)"
              ></mjs-entity-form>
            </div>
          </div>
          <div class="py-3" id="register-organisation" [hidden]="!organisation">
            <div class="">
              <h2>Create your advertiser account</h2>
              <mjs-loader *ngIf="loading"></mjs-loader>
              <mjs-entity-form
                entity="user"
                bundle="user"
                mode="register_organisation"
                [params]="params"
                *ngIf="!loading && organisation"
                [recaptcha]="true"
                (submitForm)="submit($event)"
              ></mjs-entity-form>
            </div>
          </div>
        </ng-container>
      </div>
    </main>
  </div>
</div>
