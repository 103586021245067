import { Component, Input, ViewEncapsulation } from '@angular/core';

interface Link {
  external: boolean;
  title: string;
  url: string;
}

@Component({
  selector: 'mjs-sidebar-links',
  templateUrl: './sidebar-links.component.html',
  styleUrls: ['./sidebar-links.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarLinksComponent {
  @Input() links: Link[];
}
