import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewEncapsulation,
  PLATFORM_ID,
  Renderer2,
  AfterViewChecked,
  Optional
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, DOCUMENT, isPlatformServer } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';
import { Content } from '@app/models';

@Component({
  selector: 'mjs-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentComponent implements OnInit, AfterViewChecked, OnDestroy {

  content: Content;
  survey: boolean;
  slug: string;
  covidInfo: string;
  window: any;
  zendesk: any;
  fragment?: string;
  scrolled: boolean;
  loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    @Inject(DOCUMENT) private doc: Document,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.route.fragment.subscribe(res => {
      this.fragment = res;
      this.scrolled = false;
    });

    this.route.data.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.content = data['content'];
      this.survey = this.content.path === '/survey' || false;

      // If this is a job shortlink silently redirect the user.
      if (this.content.hasOwnProperty('compensation_currency')) {
        this.router.navigate([`/shortlink/${this.content.id}`], {
          skipLocationChange: true,
          queryParams: this.route.snapshot.queryParams
        });
      }

      if (this.content.full_width) {
        this.renderer.addClass(this.doc.body, 'full-width');
      }

      if (this.content.gray) {
        this.renderer.addClass(this.doc.body, 'gray');
      }

      if (this?.content?.path === '/404') {
        if (isPlatformServer(this.platform)) {
          this.response.status(404);
        }
      }
    });
  }

  get displayFeaturedJobs(): boolean {
    if (this.content.org) return false;
    if (this.content.tid && this.content.org_type) return false;
    if (this.content.hasOwnProperty('hide_featured_jobs')) return false;

    return true;
  }

  public showCovidInfo(event) {
    this.covidInfo = this.content.views.covid_19__rest[event.target.value].info;
  }

  ngAfterViewChecked() {
    this.hashLinks();

    if (this.fragment && isPlatformBrowser(this.platform) && !this.scrolled) {
      this.doc.querySelector('#' + this.fragment).scrollIntoView({ behavior: "smooth" });
      this.scrolled = true;
    }
  }

  private hashLinks() {
    if (this.content && this.content.body && isPlatformBrowser(this.platform)) {
      const anchors = this.doc.getElementsByTagName('a');

      for (let i = 0; i < anchors.length; i++) {
        if (anchors[i].hash) {
          if (anchors[i].pathname == this.content.path) {
            const hash = anchors[i].hash.replace('#', '');
            anchors[i].setAttribute('href', `${this.content.path}#${hash}`);
            anchors[i].setAttribute('fragment', `${hash}`);
            anchors[i].setAttribute('ng-reflect-fragment', `${hash}`);
            anchors[i].setAttribute('routerlink', this.content.path);
            anchors[i].setAttribute('ng-reflect-router-link', this.content.path);
          }
        }
      }
    }
  }

  public ngOnDestroy():void {
    this.renderer.removeClass(this.doc.body, 'full-width');
    this.renderer.removeClass(this.doc.body, 'gray');
  }

}
