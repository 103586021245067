<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="this.content && this.content.org">
          <li>
            <a
              [routerLink]="this.content.org.path + '/orders'"
              routerLinkActive="active">
              Orders
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/purchase'"
              routerLinkActive="active">
              Purchase
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-tokens'"
              routerLinkActive="active">
              Job Tokens
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-extension-tokens'"
              routerLinkActive="active">
              Job Extension Tokens
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>

      <ng-container *ngIf="!loading && this.content && this.content.org">

        <!-- <p>
          <strong>
            You currently have {{ content.org.tokens > 0 ? content.org.tokens : 'no' }} job token{{ content.org.tokens !== 1 ? 's' : '' }}.
          </strong>
        </p> -->

        <div [innerHtml]="content.body" *ngIf="content.body"></div>

        <div *ngIf="!data || data.list.length == 0">
          <p>You have no orders.</p>
        </div>

        <div *ngIf="data && data.list.length > 0">
          <div class="table-container">
            <table mat-table [dataSource]="data.list">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>

              <ng-container matColumnDef="order_items">
                <th mat-header-cell *matHeaderCellDef> Order Items </th>
                <td mat-cell *matCellDef="let element">
                  
                  <div *ngIf="element.mjs_job_tokens && element.mjs_job_tokens.quantity > 0">
                    Job Tokens x {{ element.mjs_job_tokens.quantity }}
                  </div>
                  
                  <div *ngIf="element.mjs_job_extension_tokens && element.mjs_job_extension_tokens.quantity > 0">
                    Job Extension Tokens x {{ element.mjs_job_extension_tokens.quantity }}
                  </div>
                  
                </td>
              </ng-container>

              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.created|date:'d/M/yy h:mma'}} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> <span class="badge" [ngClass]="{
                  'badge-warning': !element.status,
                  'badge-success': element.status
                }">{{element.status ? 'Complete' : 'Pending'}}</span> </td>
              </ng-container>

              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef> Total </th>
                <td mat-cell *matCellDef="let element"> £{{element.total || 0}} </td>
              </ng-container>

              <!-- <ng-container matColumnDef="ref">
                <th mat-header-cell *matHeaderCellDef> Job No/Ref </th>
                <td mat-cell *matCellDef="let element"> {{element.ref}} </td>
              </ng-container> -->

              <ng-container matColumnDef="foo" sticky>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" sticky=”true” [ngStyle]="{ 'z-index': element.active ? 100 : 1 }">
                  <div class="btn-group">
                    <button
                      class="btn btn-outline-primary btn-sm dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      (click)="toggleElementActions(element)"
                      [attr.aria-expanded]="element.active || 'false'">
                      Actions
                    </button>
                    <div class="dropdown-menu" *ngIf="element.active">
                      
                      <a [routerLink]="content.org.path + '/orders/' + element.id">
                        View order
                      </a>
                      
                      <a [routerLink]="content.org.path + '/mjs-job-tokens'"
                        *ngIf="element.mjs_job_tokens > 0">
                        Job tokens
                      </a>
                      
                      <a [routerLink]="content.org.path + '/mjs-job-extension-tokens'"
                        *ngIf="element.mjs_job_extension_tokens > 0">
                        Extension tokens
                      </a>

                    </div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>

            </table>
            <!-- <div class="sticky-actions" *ngIf="showActions">
              <div class="btn-group" *ngFor="let element of data.list">
                <button
                  class="btn btn-outline-primary btn-sm dropdown-toggle"
                  type="button"
                  aria-haspopup="true"
                  (click)="toggleElementActions(element)"
                  [attr.aria-expanded]="element.active || 'false'">
                  Actions
                </button>
                <div class="dropdown-menu" *ngIf="element.active">
                  <a [routerLink]="content.org.path + '/orders/' + element.id">View order</a>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <mjs-pager
          *ngIf="data && data.pages > 1"
          [page]="data.page"
          [pages]="data.pages"
          [total]="data.total">
        </mjs-pager>

      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
