import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DrupalFileService } from '@app/services/drupal-file.service';

@Component({
  selector: '[app-image]',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  providers: [DrupalFileService],
  encapsulation: ViewEncapsulation.None
})
export class ImageComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;
  @Input() uploadUrl: string;
  @Input() entityType: string;
  @Input() entityBundle: string;

  loading: boolean;
  imagePreviewUrl: string | SafeUrl;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private domSanitizer: DomSanitizer,
    private toast: ToastrService,
    private drupalFileService: DrupalFileService) { }

  ngOnInit(): void {
    this.element.class = '';

    if (this.element.required) {
      this.element.class += ' required';
    }

    if (this.element.container_class) {
      this.element.class += ' ' + this.element.container_class;
    }
  }

  removeItem(item) {
    this.element.value = null;
    this.element.preview = null;
  }

  onChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = event => {
        this.imagePreviewUrl = this.domSanitizer.bypassSecurityTrustUrl((<string>reader.result));
      };

      this.loading = true;
      const file = event.target.files[0];
      let mimetype = file.type;

      this.drupalFileService.post(this.entityType,
        this.entityBundle,
        this.element.field_name,
        file.name,
        mimetype,
        event.target.files[0])
        .subscribe({
          next: (res: any) => {
            this.element.preview = res.url;
            this.loading = false;
            this.parentForm.controls[this.element.field_name].patchValue(res.fid.toString());
          },
          error: (err: any) => {
            this.toast.error(err.error.message);
            this.imagePreviewUrl = '';
            this.loading = false;
          }
        });
    }
  }

}

