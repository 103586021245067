import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AppleTokenService {

  constructor(private http:HttpClient) {}

  getToken(): Observable<string|null> {
    return this.http.get<string|null>(`${environment.apiUrl}/api/v1/mapkit-token`);
  }

}
