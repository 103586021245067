import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class MjsJobTokensService {

  constructor(private http: HttpClient) {}

  list(org: number, page: number) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/mjs-job-tokens?org=${org}&page=${page - 1}`);
  }

  patch(data: any) {
    this.http.patch(`${environment.apiUrl}/api/v1/mjs-job-tokens/${data.id}`, data);
  }

}
