import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private passwordExpiration = new BehaviorSubject<boolean>(false);

  constructor(private oauthService: OAuthService) {
    this.loadInitialData();
  }

  private loadInitialData() {
    const claims: any = this.oauthService.getIdentityClaims();  // Assuming this synchronously returns claims
    if (claims) {
      this.passwordExpiration.next(claims.field_password_expiration);
    }
  }

  resetPasswordExpiration() {
    this.passwordExpiration.next(false);
  }

  getPasswordExpirationStatus() {
    return this.passwordExpiration.asObservable();
  }
}
