import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SitemapService } from './sitemap.service';

@Injectable()
export class SitemapResolver implements Resolve<any> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private content: SitemapService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    const CONTENT_KEY = makeStateKey<any>(`sitemap`);

    if (this.transferState.hasKey(CONTENT_KEY)) {
      const content = this.transferState.get<any>(CONTENT_KEY, null);
      this.transferState.remove(CONTENT_KEY);
      return of(content);
    } else {
      return this.content.get()
      .pipe(
        tap(content => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(CONTENT_KEY, content);
          }
          return of(content);
        })
     );
    }
  }

}
