import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScrollService {

  private scrollSource = new BehaviorSubject<boolean>(false);
  currentValue = this.scrollSource.asObservable();

  constructor() { }

  changeValue(isScrolled) {
    this.scrollSource.next(isScrolled);
  }

}
