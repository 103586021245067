import { Component, OnInit, OnDestroy, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject, asyncScheduler } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MjsJobService } from '../../services/mjs-job.service';
import { DenormalizerService } from '@app/modules/drupal-entity/services/denormalizer.service';
import { environment } from '@env/environment';

@Component({
  selector: 'mjs-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  providers: [MjsJobService, DenormalizerService],
  encapsulation: ViewEncapsulation.None
})
export class JobComponent implements OnInit, OnDestroy {

  params: any = {};
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean = true;
  duplicating: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private jobService: MjsJobService,
    private renderer: Renderer2,
    private toast: ToastrService,
    private denoramlizer: DenormalizerService) {
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.loading = true;
        let job = res.get('job');

        if (job) {
          this.entity_id = parseInt(job, 10);
        } else {
          this.entity_id = null;
        }

        asyncScheduler.schedule(() => {
          this.loading = false;
        }, 750);
      });

    this.route.data
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        this.content = res['content'];

        // If organisation is adult-care then set the job category.
        // if (this.content?.org?.org_type === 'adult-care') {
        //   if (environment.adultCareCategory) {
        //     this.params.job__field_category = environment.adultCareCategory;
        //   }
        // }

        // const parsed = parseInt(this.route.pathFromRoot[2].snapshot.url[2].path, 10);
        // this.entity_id = parsed > 0 ? parsed : null;

        if (this.content.org && this.content.org.redirect) {
          this.toast.error(this.content.org.redirect.message);
          this.router.navigate([this.content.org.redirect.path]);
        }

        if (this.content && this.content.org && this.content.org.job_advert_id) {
          this.entity_id = this.content.org.job_advert_id;
        }
      });

    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
        let duplicate = parseInt(queryParams.get('duplicate'), 10);

        if (duplicate) {
          this.entity_id = duplicate;
          this.duplicating = true;
        }
      });
  }

  submit(data) {
    this.loading = true;
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();

    const params: any = this.denoramlizer.denormalize(data.structure, data.params);

    // Set default values for external advertiser.
    params.source = 'webform';
    params.organisation = [{ target_id: this.content.org.id }];

    let start_date, end_date;

    // Remove time values from start and end date as mjs does not currently support it.
    if (typeof params.start_date === 'string') {
      params.start_date = new Date(params.start_date);
    }

    params.start_date.setHours(1,0,0);
    start_date = params.start_date;
    params.start_date = this.ISODateString(start_date);

    if (typeof params.end_date === 'string') {
      params.end_date = new Date(params.end_date);
    }

    params.end_date.setHours(23,59,0);
    end_date = params.end_date;
    params.end_date = this.ISODateString(end_date);

    params.source = 'webform';

    if (this.entity_id && !this.duplicating) {
      this.jobService.patch(params, this.entity_id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (res: any) => {
            this.params = res.params;
            this.router.navigate([this.content.org.path + '/jobs'], {
              queryParams: {
                message: 'recruiter_job_updated'
              }
            });
          },
          error: err => this.onError(data, err)
        });
    } else {
      this.jobService.post(params)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (res: any) => {
            return this.router.navigate([this.content.org.path + '/jobs'], {
              queryParams: {
                message: 'recruiter_job_created'
              }
            })
          },
          error: err => this.onError(data, err)
        });
    }
  }

  ISODateString(d){
    function pad(n) {
      return n < 10 ? "0" + n : n;
    }

    function timezoneOffset(offset) {
      var sign;
      if (offset === 0) {
        return "Z";
      }
      sign = (offset > 0) ? "-" : "+";
      offset = Math.abs(offset);
      return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
    }

    return d.getFullYear() + "-" +
      pad(d.getMonth() + 1) + "-" +
      pad(d.getDate()) + "T" +
      pad(d.getHours()) + ":" +
      pad(d.getMinutes()) + ":" +
      pad(d.getSeconds()) +
      timezoneOffset(d.getTimezoneOffset());
  }

  onError(data, err) {
    this.params = data.params;
    this.loading = false;
    this.toast.error(err.error.message)
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
