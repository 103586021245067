import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class LumesseStatusService {

  constructor(private http: HttpClient) {}

  get(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/lumesse-status`);
  }

}
