<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <div *ngIf="!loading">
        <mjs-drupal-entity
          entity="mjs_job"
          bundle="mjs_job"
          mode="default"
          [entity_id]="entity_id"
          [params]="params"
          (submitForm)="submit($event)"
          [duplicatingJob]="duplicating">
        </mjs-drupal-entity>
      </div>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
