import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Location } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class LocationsService {

  constructor(private http:HttpClient) {}

  get(query: string): Observable<Location[]> {
    return this.http.get<Location[]>(`${environment.apiUrl}/api/v1/locations?_format=json&query=${query}`);
  }

}
