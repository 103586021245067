import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class DrupalFileService {

  constructor(private http: HttpClient) {}

  post(entity_type_id: string, bundle: string, field_name: string, filename: string, mimetype: string, body: any) {
    return this.http.post(`${environment.web1}/api/v2/files/${entity_type_id}/${bundle}/${field_name}`, body, {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Upload-Content-Type': mimetype,
        'Content-Disposition': 'file; filename="' + filename + '"'
      }
    });
  }

}
