import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {}

  list(org, page) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/users?_format=json&org=${org}&page=${page - 1}`)
  }

  post(data, org) {
    return this.http.post(`${environment.apiUrl}/api/v1/recruiter/user?org=${org}`, data);
  }

  patch(data, id) {
    return this.http.patch(`${environment.apiUrl}/api/v1/recruiter/user?id=${id}`, data);
  }

  get(org, id) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/user?org=${org}&id=${id}`);
  }

  delete(org, id) {
    return this.http.delete(`${environment.apiUrl}/api/v1/recruiter/user?org=${org}&id=${id}`);
  }
  
}
