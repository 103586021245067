import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClaimOrganisationService } from '@app/modules/recruiter/services/claim-organisation.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'mjs-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
  providers: [ClaimOrganisationService],
  encapsulation: ViewEncapsulation.None
})
export class ClaimComponent {
  
  form = new UntypedFormGroup({ });
  submitted: boolean;
  params: any;
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;
  suggestions: [];
  selected: any;
  focused: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private search: ClaimOrganisationService,
    private toast: ToastrService,
    private oauth: OAuthService) {

    this.form.addControl('autocomplete', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('field_service_provider_number', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('field_charity', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('field_charity_number', new UntypedFormControl(''));
  }

  onKeyUp(event): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.search.get(event.target.value).pipe(takeUntil(this.onDestroy$)).subscribe(
      (data: any) => {
        this.suggestions = data;
        this.focused = true;
      },
      (err: any) => {

      }
    )
  }

  get f(): any { return this.form.controls; }

  public onSubmit(): any {
    this.submitted = true;

    if (!this.selected) {
      return this.toast.error('You must select an organisation');
    }

    if (this.form.value.field_charity === '1' && !this.form.value.field_charity_number) {
      return this.toast.error('You must provide your registered charity number');
    }

    if (this.form.invalid) {
      return;
    }

    const data = this.form.value;
    delete data.autocomplete;
    data.organisation = this.selected.tid;
    this.loading = true;

    this.search.post(data).pipe(takeUntil(this.onDestroy$)).subscribe(
      (data: any) => {
        this.oauth.refreshToken().then(res => {
          this.oauth.loadUserProfile().then((res: any) => {
            this.loading = false;
            this.toast.success(data.message);
            window.location.href = data.redirect;
          })
        })
      },
      (err: any) => {
        this.toast.error(err.error && err.error.message ? err.error.message : 'Sorry, something went wrong');
        this.loading = false;
      }
    )
  }

  public selectSuggestion(item): void {
    this.selected = item;
    this.form.patchValue({autocomplete: item.title});
    this.focused = false;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
