<mjs-loader *ngIf="loading"></mjs-loader>
<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  *ngIf="data && !loading"
  class="entity-form">
  <div class="messages error bg-light" *ngIf="submitted && form.invalid">
    <p>Please correct the highlighted errors below before submitting</p>
  </div>
  <ng-template ngFor let-column [ngForOf]="renderArrays">
    <div class="row">
      <ng-template ngFor let-element [ngForOf]="column">
        <div
          app-text
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'string' || element.type === 'string_textfield'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-email
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'string_email' || element.type === 'email'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-link
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'link'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-password
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'pass'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <ng-container *ngIf="browser">
          <div
            app-textarea
            [ngClass]="element.container_class"
            [id]="'field-' + element.field_name"
            *ngIf="element.type === 'text_textfield' || element.type === 'text_format' || element.type == 'text_with_summary'"
            [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
            [fileUrl]="'/file/upload/' + entity + '/' + bundle + '/field_header?_format=json'"
          ></div>
        </ng-container>
        <div
          app-number
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'number' || element.type === 'decimal'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-select
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'options_select' || element.type === 'entity_reference' || element.type === 'list_integer' || element.type === 'list_string' || element.type === 'entity_reference_autocomplete'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-checkbox
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'checkbox'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-image
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'image_image' || element.type === 'image'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
          [entityType]="entity"
          [entityBundle]="bundle"
        ></div>
        <div
          app-date
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'datetime' || element.type === 'date'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-checkboxes
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'checkbox_group'"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
        <div
          app-file
          [ngClass]="element.container_class"
          [id]="'field-' + element.field_name"
          *ngIf="element.type === 'file' || element.type === 'file_default'"
          [entityType]="entity" [entityBundle]="bundle"
          [parentForm]="form" [element]="element" [submitted]="submitted" [errors]="f[element.field_name].errors"
        ></div>
      </ng-template>
    </div>
  </ng-template>
  <div class="form-group border-0" *ngIf="recaptcha">
    <re-captcha
      formControlName="recaptcha"
      class="form-control recaptcha-field"
      [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }"
      required></re-captcha>
    <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
      <div *ngIf="f.recaptcha.errors.required">Recaptcha is required</div>
    </div>
  </div>
  <div class="form-group my-0 border-0">
    <button class="btn btn-lg btn-primary pull-right">{{ submitLabel || 'Submit' }}</button>
  </div>
</form>
