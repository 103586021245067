<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="order-meta" *ngIf="order">
  <div class="container">
    <div class="row">
      <div class="col">
        <ul>
          <li>
            <span>Created</span>
            {{ order.created | date: 'd/M/yy HH:mm'}}
          </li>
          <li *ngIf="order.created !== order.changed">
            <span>Updated</span>
            {{ order.changed | date: 'd/M/yy HH:mm'}}
          </li>
          <li>
            <span>Status</span>
            <em class="badge badge-success" [ngClass]="{ 'badge-success': order.status, 'badge-warning': !order.status }">{{ order.status ? 'Complete' : 'Pending Payment' }}</em>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <!-- <p>Purchasing tokens is not possible due to a system upgrade, please bear with us.</p> -->
      <mjs-loader *ngIf="loading" [message]="loadingMessage"></mjs-loader>

      <div class="table-container order-items" *ngIf="order && order.paypal_order && !loading">
        <table mat-table [dataSource]="order.paypal_order[0].paypal_order.purchase_units[0].items">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Product </th>
            <td mat-cell *matCellDef="let element"> <span [innerHtml]="element.name"></span> </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let element"> <span [innerHtml]="element.quantity"></span> </td>
          </ng-container>

          <ng-container matColumnDef="unit_amount">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let element"> <span> {{ displayPrice(element.unit_amount) }}</span> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
      </div>

      <div class="table-container order-totals" *ngIf="order && order.paypal_order && !loading">
        <table mat-table [dataSource]="order.paypal_order[0].totals">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <th scope="row" mat-cell *matCellDef="let element"> <span [innerHtml]="element.name"></span> </th>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell *matCellDef="let element"> <span> {{ displayPrice(element.value) }}</span> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="footerColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: footerColumns;"></tr>
        </table>
      </div>

      <div *ngIf="payPalConfig" class="order-actions" [hidden]="!order || (order && order.status) || loading">
        <button class="btn btn-outline-primary float-left px-5" (click)="deleteOrder()">Cancel Order</button>
        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
      </div>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
