import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class EngageAnalyticsService {

  constructor(private http: HttpClient) {}

  post(body: any) {
    const data = {};

    for (let key in body.params) {
      if (body.default_params[key]) {
        data[key] = body.default_params[key];
        continue;
      }

      if (key === 'type' && body.params[key].value.length > 0) {
        data[key] = body.params[key].value;
        continue;
      }

      switch (body.params[key].type) {
        case 'numberArray':
          if (body.params[key].value.length > 0) {
            data[key] = body.params[key].value.join(' ');
          }
          break;
        default:
          if (body.params[key].value) {
            data[key] = body.params[key].value;
          }
      }
    }

    return this.http.post(`${environment.apiUrl}/api/v1/search_analytics`, { ...data });
  }

}
