<div *ngIf="content"
  class="recruiter-header d-flex flex-column justify-content-end"
  [ngStyle]="{ background: '#ddd url(' + (content.header ? content.header.styles.header.url : '/sites/default/files/banner/mike-smith-644465-unsplash.jpg') + ') no-repeat center center / cover' }">
  <div class="container">
    <div class="row">
      <div class="col-md-12 d-flex flex-column align-items-start">
        <div class="org-logo">
          <img [src]="content.logo || '/assets/img/branding/myjobscotland-icon.svg'" [alt]="content.title">
        </div>
      </div>
    </div>
  </div>

  <div class="organisation-navbar">
    <div class="container">
      <div class="row">
        <div class="col-md-12" #wrap>
          <h1 [innerHtml]="content.title" #title></h1>
          <!-- <nav class="priority-nav">
            <ul class="fluid-nav-ul" *ngIf="nav" #fluidNav>
              <li *ngFor="let item of nav">
                <a [routerLink]="item.path" routerLinkActive="active">
                  {{ item.title }}
                </a>
              </li>
            </ul>
            <button
              class="fluid-nav-toggle"
              (click)="toggleFluidNav()"
              *ngIf="hiddenNav.length > 0"
              aria-controls="user-menu"
              [attr.aria-expanded]="hiddenNavVisible || 'false'"
              tabindex="0"
              role="button"
              [hidden]="!nav_visible">
              <span class="sr-only">Toggle menu</span>
              <i></i><i></i><i></i>
            </button>
            <ul
              class="fluid-nav-hidden"
              *ngIf="hiddenNav.length > 0"
              [hidden]="!hiddenNavVisible"
              #fluidNav>
              <li *ngFor="let item of hiddenNav">
                <a [routerLink]="item.path" routerLinkActive="active">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </nav> -->
        </div>
      </div>
    </div>
  </div>
</div>