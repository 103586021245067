import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Inject,
  ViewEncapsulation,
  Renderer2, PLATFORM_ID
} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Content, PageLinks } from '@app/models';
import loadjs from "loadjs";
import {environment} from "@env/environment";

declare var Freshbots: any;

@Component({
  selector: 'mjs-career-hub-page',
  templateUrl: './career-hub-page.component.html',
  styleUrls: ['./career-hub-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CareerHubPageComponent implements OnInit, OnDestroy {

  @Input('mobile') isMobile: boolean;

  content: Content;
  hideMenu: boolean = false;
  pagelinks: PageLinks;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.content = data['content'];

      if (this.content.path == '/career-hub' && isPlatformBrowser(this.platformId)) {
        this.loadFreshDesk();
      }

      this.content.html = this.sanitizer.bypassSecurityTrustHtml(this.content.body);

      if (this.content.views) {
        for (let key in this.content.views) {
          if (key.split('__')[0] === 'career_hub_pages') {
            this.pagelinks = this.content.views[key];
          }
        }
      }

      if (this.content.full_width) {
        this.renderer.addClass(this.doc.body, 'full-width');
      } else {
        this.renderer.removeClass(this.doc.body, 'full-width');
      }

      if (this.content.gray) {
        this.renderer.addClass(this.doc.body, 'gray');
      } else {
        this.renderer.removeClass(this.doc.body, 'gray');
      }
    });
  }

  loadFreshDesk() {
    loadjs([
      `https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js`
    ], {
      before: (path, el) => {
        switch (path) {
          case `https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js`:
            el.setAttribute('data-self-init', 'false');
            el.setAttribute('data-init-type', 'opt');
            el.setAttribute('id', 'spd-busns-spt');
            el.setAttribute('async', 'async');
            el.setAttribute('data-client', '26fd47938488100260b10b4d1f870332580b71ee');
            el.setAttribute('data-bot-hash', '7e0ae75edd6036188cc4514b0f260873ec90d506');
            el.setAttribute('data-env', 'prod');
            el.setAttribute('data-region', 'euc');
            break;
        }
      },
      success: () => {
        Freshbots.initiateWidget({
          autoInitChat: false,
          getClientParams: function () {
            return;
          }
        });
      },
      error: (err: any) => {}
    });
  }

  public toggleMenu() {
    this.hideMenu = !this.hideMenu;
  }

  public ngOnDestroy():void {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    if (this.content.full_width) {
      this.renderer.removeClass(this.doc.body, 'full-width');
    }

    if (this.content.gray) {
      this.renderer.removeClass(this.doc.body, 'gray');
    }

    if (isPlatformBrowser(this.platformId) && Freshbots) {
      Freshbots.deActivate();
    }
  }

}
