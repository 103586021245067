<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <ng-container *ngIf="!loading">
        <div [innerHtml]="content.body" *ngIf="!loading && content.body"></div>
        <mjs-entity-form
          entity="user"
          bundle="user"
          mode="register_organisation"
          [entity_id]="this.entity_id"
          [params]="params"
          (submitForm)="submit($event)"
        ></mjs-entity-form>
      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>