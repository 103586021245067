import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { SidebarJobs } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class SidebarJobsService {

  constructor(private http: HttpClient) {}

  get(query: string): Observable<SidebarJobs> {
    return this.http.get<SidebarJobs>(`${environment.apiUrl}/api/v1/sidebar-jobs${query}`);
  }

}
