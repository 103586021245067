import {
  Component,
  AfterViewInit,
  Input,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'mjs-advert',
  templateUrl: './advert.component.html',
  styleUrls: ['./advert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvertComponent implements AfterViewInit {
  
  @Input() type: string;
  @Input('mobile') isMobile: boolean;
  
  advert = null;
  id = UUID.UUID();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private doc: Document) { }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
      const container = this.doc.getElementById(this.id);
      const advert: any = this.doc.createElement('iframe');
      advert.scrolling = 'no';
      advert.frameBorder = '0';

      switch (this.type) {
        case 'leaderboard':
          advert.height = this.isMobile ? '50' : '50';
          advert.width = this.isMobile ? '320' : '320';
          break;
        case 'mpu':
          advert.height = '250';
          advert.width = '300';
          break;
        case 'doublempu':
          advert.height = '600';
          advert.width = '300';
          break;
      }

      advert.src = `/assets/adverts/${this.type}.html`;

      this.renderer.appendChild(container, advert);
    }
  }
}
