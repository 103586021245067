/**
 * Convert raw URL search params to form ready values.
 */

import { ParamMap, Params } from "@angular/router";
import { MjsSearchParams } from "../models/mjs-search-params.model";

const searchParamsToParams = (params: MjsSearchParams, paramMap: ParamMap): MjsSearchParams => {
  for (let key in params) {
    switch (params[key].type) {
      case 'string':
        params[key].value = paramMap.get(key)?.trim() ?? '';
        break;

      case 'number':
        if (key === 'refresh_your_career' || key === 'term_time_only') {
          if (Number(paramMap.get(key)) === 1) {
            params[key].value = 1
          }
        } else {
          params[key].value = Number(paramMap.get(key) ?? 0);
        }
        break;

      case 'stringArray':
        params[key].value = paramMap.get(key)?.split(',').map(item => item.trim()) ?? [];
        break;

      case 'numberArray':
        params[key].value = paramMap.get(key)?.split(',').map(item => Number(item)) ?? [];
        break;
    }
  }

  if (!params.sort.value) {
    params.sort.value = 'pd';
  }

  return params;
}

export { searchParamsToParams }
