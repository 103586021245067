import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class OrganisationPageService {

  constructor(private http: HttpClient) { }

  patch(id: number|string, data: any) {
    return this.http.patch(`${environment.apiUrl}/api/v1/recruiter-page/${id}`, {
      data
    });
  }

}
