<mjs-page-header
  align="center"
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col">
      <div class="col col-md-8 offset-md-2 bg-white shadow-sm">
        <div class="p-3">
          <h3>Please complete your personal details</h3>
          <mjs-loader *ngIf="loading"></mjs-loader>
          <mjs-entity-form
            entity="user"
            bundle="user"
            mode="complete_registration"
            [params]="params"
            *ngIf="!loading"
            (submitForm)="submit($event)"
          ></mjs-entity-form>
        </div>
      </div>
    </main>
  </div>
</div>
