import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { JobAdvertService } from './job-advert.service';

@Injectable()
export class JobAdvertResolver implements Resolve<any> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private jobAdvertService: JobAdvertService) { }

  resolve(route: ActivatedRouteSnapshot) {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    return this.jobAdvertService.get(route.queryParams['jobId'].split('-').reverse()[0]);
  }

}
