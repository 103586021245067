import { Injectable } from '@angular/core';

@Injectable()
export class ParamsService {

  sortValues: any;
  distanceValues: any;

  constructor() {}

  format(params: any, structure: any, type?: string) {
    let body = <any>{};

    for (let i = 0; i < structure.fields.length; i += 1) {
      let field_name = structure.fields[i].field_name;

      if (!params[field_name]) {
        if (structure.fields[i].type === 'boolean_checkbox') {
          body[field_name] = 0;
        }
        else {
          body[field_name] = null;
        }
        continue;
      }

      if (type && type === 'organisation') {
        body[field_name] = [];
        let label = 'value';

        switch (structure.fields[i].field_type) {
          case 'image':
          case 'entity_reference':
          case 'file':
            label = 'target_id';
            break;
        }

        if (structure.fields[i].cardinality > 1) {
          if (params[field_name].length === 0) {
            body[field_name] = null;
            continue;
          }

          for (let e = 0; e < params[field_name].length; e += 1) {
            body[field_name].push({[label]: params[field_name][e]});
          }
        } else {
          if (structure.fields[i].type !== 'boolean_checkbox') {
            if (!params[field_name]) {
              body[field_name] = null;
              continue;
            }
          }

          if (params[field_name].length === 0) {

            body[field_name] = null;
            continue;
          }

          if (params[field_name]) {

            body[field_name].push({[label]: params[field_name]});
          }
          else {
            body[field_name] = null;
          }
        }

        if (body[field_name].length === 0) {
          body[field_name] = null;
        }
      } else {
        if (params[field_name]) {
          switch (structure.fields[i].field_type) {
            case 'entity_reference':
              body[field_name] = [{
                target_id: params[field_name]
              }];
              break;
            default:
              body[field_name] = params[field_name];
          }

          switch (structure.fields[i].type) {
            case 'datetime':
              if (typeof params[field_name] == 'string') {
                body[field_name] = params[field_name];
              }
              else {
                let date = params[field_name].toISOString();
                body[field_name] = date.substring(0, date.length - 5);
              }
              break;
          }
        }
      }
    }

    return body;
  }

}
