<form class="search-filter" [formGroup]="form"
  *ngIf="data && data.type === 'checkbox-group' && !this.hideChild">

  <fieldset *ngIf="!this.hideParent">

    <legend>{{ data.label }}</legend>

    <button
      type="button"
      class="toggle"
      *ngIf="!static"
      (click)="data.open = !data.open"
      [attr.aria-expanded]="data.open ? 'true' : 'false'"
      [attr.aria-controls]="data.key + '-checkboxes'"
      [ngClass]="{ open: data.open }">
      <span class="sr-only">
        {{ data.open ? 'Hide' : 'Show' }} {{ data.label }} filter list
      </span>

      <svg aria-hidden="true" focusable="false" width="22" height="22" fill="currentColor"
        class="svg-icon mjs-add position-absolute end-0 top-50 translate-middle-y me-2"
        *ngIf="!data.open">
        <use xlink:href="/assets/icons/symbol-defs.svg#mjs-add"></use>
      </svg>
      
      <svg aria-hidden="true" focusable="false" width="22" height="22" fill="currentColor"
        class="svg-icon mjs-remove position-absolute end-0 top-50 translate-middle-y me-2"
        *ngIf="data.open">
        <use xlink:href="/assets/icons/symbol-defs.svg#mjs-remove"></use>
      </svg>
    </button>

    <button type="button" class="clear"
      *ngIf="showReset" (click)="this.resetAll()">
      Reset <span class="sr-only">{{ data.label }} filters</span>
    </button>

    <button type="button" class="expand" [ngClass]="{ expanded: data.expanded }"
      (click)="data.expanded = !data.expanded" *ngIf="!static && data.open && data.show_count > 5">
      Show all fields
    </button>

    <div [id]="data.key + '-checkboxes'" class="scroll"
      [ngClass]="{ open: data.open || static, expanded: data.expanded }"
      [attr.aria-hidden]="data.open ? 'false' : 'true'">

      <ng-container *ngFor="let item of data.options | keyvalue">

        <div *ngIf="item.value['count'] > 0 || static">

          <input [id]="(item.value['children'] ? 'parent_' : '') + item.key" type="checkbox"
            [formControlName]="(item.value['children'] ? 'parent_' : '') + item.key"
            class="sr-only">

          <label [for]="(item.value['children'] ? 'parent_' : '') + item.key">
            {{ item.value['name'] }} <span *ngIf="!static">{{ item.value['count'] }}</span>
          </label>

        </div>

      </ng-container>
    </div>

  </fieldset>

  <fieldset *ngIf="data.has_children && data.child_visible">

    <legend>Sub {{ data.label }}</legend>

    <button type="button" class="toggle" *ngIf="!static"
      (click)="data.child_open = !data.child_open" 
      [attr.aria-expanded]="data.child_open ? 'true' : 'false'"
      [attr.aria-controls]="'sub-' + data.key + '-checkboxes'"
      [ngClass]="{ open: data.child_open }">
      <span class="sr-only">Show / Hide fields</span>

      <svg aria-hidden="true" focusable="false" width="22" height="22" fill="currentColor"
        class="svg-icon mjs-add position-absolute end-0 top-50 translate-middle-y me-2"
        *ngIf="!data.child_open">
        <use xlink:href="/assets/icons/symbol-defs.svg#mjs-add"></use>
      </svg>
      
      <svg aria-hidden="true" focusable="false" width="22" height="22" fill="currentColor"
        class="svg-icon mjs-remove position-absolute end-0 top-50 translate-middle-y me-2"
        *ngIf="data.child_open">
        <use xlink:href="/assets/icons/symbol-defs.svg#mjs-remove"></use>
      </svg>

    </button>

    <button type="button" class="clear"
      *ngIf="params[data.key].value.length > 0" (click)="this.resetChildren()">
      Reset
    </button>

    <button type="button" class="expand" [ngClass]="{ expanded: data.child_expanded }"
      (click)="data.child_expanded = !data.child_expanded" *ngIf="!static && data.child_open && data.child_show_count > 5">
      Show all fields
    </button>

    <div [id]="'sub-' + data.key + '-checkboxes'" class="scroll"
      [attr.aria-hidden]="data.child_open ? 'false' : 'true'"
      [ngClass]="{ open: data.child_open || static, expanded: data.child_expanded }">

      <ng-container *ngFor="let item of data.options | keyvalue">

        <ng-container *ngIf="item.value['children'] && showChildren(item.value)">
          <ng-container *ngFor="let subItem of item.value['children'] | keyvalue">

            <div *ngIf="subItem.value['count'] > 0 || (static && !subItem.value['hidden'])">

              <input [id]="subItem.key" type="checkbox" [formControlName]="subItem.key"
              class="sr-only">

              <label [for]="subItem.key">
                {{ subItem.value['name'] }}
                <span *ngIf="!static">{{ item.key.toString() === subItem.key.toString() ? parentTermCount(item.value['count'], item.value['children'], item.key) : subItem.value['count'] }}</span>
              </label>

            </div>

          </ng-container>
        </ng-container>
      </ng-container>

    </div>
  </fieldset>
</form>
