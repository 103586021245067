import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class MjsJobMetricsService {

  constructor(private http: HttpClient) { }

  click(id: number, featured: boolean) {
    return this.http.patch<any>(`${environment.apiUrl}/api/v2/metrics/click/${id}?featured=${featured}`, {
      body: { id, featured }
    });
  }

  apply(id: number, featured: boolean) {
    return this.http.patch<any>(`${environment.apiUrl}/api/v2/metrics/apply/${id}?featured=${featured}`, {
      body: { id, featured }
    });
  }

}
