import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class CompleteRegistrationService {

  constructor(private http: HttpClient) {}

  patch(data) {
    return this.http.patch(`${environment.apiUrl}/api/v1/complete-registration`, data);
  }

}
