import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Content } from '@app/models';

@Injectable()
export class ContentService {

  constructor(private http: HttpClient) { }

  getPage(url: string): Observable<Content> {
    return this.http.get<Content>(`${environment.apiUrl}/api/v1/content?_format=json&alias=${url ? url : '/'}`);
  }

}
