import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';
import { environment } from '@env/environment'
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CategoryListItem } from '@shared/models';

@Injectable()
export class CategoriesService {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private http: HttpClient) {
  }

  get(): Observable<CategoryListItem[]> {
    const STATE_KEY = makeStateKey<CategoryListItem[]>(`category-list`);

    if (this.transferState.hasKey(STATE_KEY)) {
      const content = this.transferState.get<CategoryListItem[]>(STATE_KEY, []);
      this.transferState.remove(STATE_KEY);
      return of(content);
    }

    return this.http.get<CategoryListItem[]>(`${environment.apiUrl}/api/v2/categories`)
    .pipe(
      tap(content => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(STATE_KEY, content);
        }
      })
    );
  }

}
