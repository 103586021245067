<div *ngIf="content" class="page-header"
  [ngClass]="classString">
  <figure *ngIf="headerImage()">
    <img [errorImage]="headerImage()" [lazyLoad]="webp.defaultImage(headerImage())" alt="">
  </figure>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="org-logo" *ngIf="isOrg">
          <img [src]="header.logo || header.logo" [alt]="header.title">
        </div>
        <p class="title-suffix" *ngIf="titlePrefix">{{ titlePrefix }}</p>
        <h1 *ngIf="isCat" [ngClass]="align">
          {{ categoryTitle() }}
        </h1>
        <h1 *ngIf="!isCat" [ngClass]="align">
          {{ isOrg && header.title ? header.title : content.title }}
        </h1>
        <p class="subtitle mt-2" *ngIf="content.field_subtitle || content.subtitle" [innerHtml]="content.field_subtitle || content.subtitle"></p>
        <a
          class="btn btn-outline-light mt-3"
          [href]="content.header_link"
          *ngIf="content.header_link && content.header_link_text">
          {{ content.header_link_text }}
        </a>
      </div>
    </div>
  </div>
  <div class="organisation-navbar" *ngIf="isOrg || isCat">
    <div class="container">
      <div class="row">
        <div class="col-md-12 d-flex flex-nowrap">
          <h2 [innerHtml]="categorySubtitle()" *ngIf="isCat && categorySubtitle()" #title></h2>
          <h2 [innerHtml]="isOrg && !header.title ? 'Home' : content.title" *ngIf="!isCat" #title></h2>
          <!-- <h2 [innerHtml]="(isOrg ? mapTitle() : '') + (isOrg && !header.title ? 'Home' : content.title)" *ngIf="!isCat" #title></h2> -->
          <mjs-fluid-menu *ngIf="nav" [nav]="nav" class="align-self-end"></mjs-fluid-menu>
        </div>
      </div>
    </div>
  </div>
</div>
