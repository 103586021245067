import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Banner } from '@app/models';

@Injectable()
export class BannerService {

  constructor(private http: HttpClient) { }

  get(): Observable<Banner> {
    return this.http.get<Banner>(`${environment.apiUrl}/api/v1/banner`);
  }

}
