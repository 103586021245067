<div class="form-group field-{{ element.field_name }}" *ngIf="element" [formGroup]="parentForm" [ngClass]="{ required: element.required }">
  <label [for]="element.field_name">{{ element.label }} <span *ngIf="element.required" class="required-flag">[Required]</span></label>
  <input
    [type]="'url'"
    [formControlName]="element.field_name"
    [id]="element.field_name"
    [ngClass]="{ 'is-invalid': submitted && errors }"
    class="form-control"
    [maxlength]="element.settings.max_length || null"
    [minlength]="element.settings.min_length || null"
  >
  <div *ngIf="submitted && errors" class="invalid-feedback" role="alert">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
    <p *ngIf="errors.pattern">{{ element.label }} must be a valid url</p>
  </div>
  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div>
</div>
