import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
// import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '@app/shared/shared.module';
import { DrupalEntityComponent } from './drupal-entity.component';
import { QuillModule } from 'ngx-quill';

import {
  CheckboxComponent,
  CheckboxesComponent,
  DateComponent,
  EmailComponent,
  FileComponent,
  ImageComponent,
  NumberComponent,
  PasswordComponent,
  RadioComponent,
  SelectComponent,
  TextComponent,
  TextareaComponent,
  UrlComponent
} from './components';

@NgModule({
  declarations: [
    DrupalEntityComponent,
    CheckboxComponent,
    CheckboxesComponent,
    DateComponent,
    EmailComponent,
    FileComponent,
    ImageComponent,
    NumberComponent,
    PasswordComponent,
    RadioComponent,
    SelectComponent,
    TextComponent,
    TextareaComponent,
    UrlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // MatPasswordStrengthModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    SharedModule,
    QuillModule
  ],
  exports: [
    DrupalEntityComponent
  ]
})
export class DrupalEntityModule { }
