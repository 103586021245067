import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class FormService {

  constructor(private http: HttpClient) {}

  getForm(entity, bundle, mode, id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/purest/entity-form?entity=${entity}&bundle=${bundle}&mode=${mode}${id ? '&entity_id=' + id : ''}`);
  }

}
