<div class="form-group file-input" *ngIf="element" [formGroup]="parentForm" [ngClass]="{ required: element.required }">
  <label [for]="element.field_name">{{ element.label }} <span *ngIf="element.required" class="required-flag">[Required]</span></label>
  <ul class="file-list list-style-none m-0">
    <li *ngFor="let item of element.value; let i = index">
      <p>
        <a [href]="item.url" target="_blank">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28" arai-hidden="true">
            <path d="M22.937 5.938c0.578 0.578 1.062 1.734 1.062 2.562v18c0 0.828-0.672 1.5-1.5 1.5h-21c-0.828 0-1.5-0.672-1.5-1.5v-25c0-0.828 0.672-1.5 1.5-1.5h14c0.828 0 1.984 0.484 2.562 1.062zM16 2.125v5.875h5.875c-0.094-0.266-0.234-0.531-0.344-0.641l-4.891-4.891c-0.109-0.109-0.375-0.25-0.641-0.344zM22 26v-16h-6.5c-0.828 0-1.5-0.672-1.5-1.5v-6.5h-12v24h20z"></path>
          </svg>
          <span [innerHtml]="item.filename + ' <small>[' + item.filesize + ']</small>'"></span>
        </a>
        <button class="remove-file" type="button" (click)="removeItem(i)">
          <svg aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <path d="M16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.961 12.209c0.244-0.244 0.244-0.641 0-0.885l-1.328-1.327c-0.244-0.244-0.641-0.244-0.885 0l-3.761 3.761-3.761-3.761c-0.244-0.244-0.641-0.244-0.885 0l-1.328 1.327c-0.244 0.244-0.244 0.641 0 0.885l3.762 3.762-3.762 3.76c-0.244 0.244-0.244 0.641 0 0.885l1.328 1.328c0.244 0.244 0.641 0.244 0.885 0l3.761-3.762 3.761 3.762c0.244 0.244 0.641 0.244 0.885 0l1.328-1.328c0.244-0.244 0.244-0.641 0-0.885l-3.762-3.76 3.762-3.762z"></path>
          </svg>
          <span class="sr-only">Remove file</span>
        </button>
      </p>
    </li>
  </ul>
  
  <label [for]="element.field_name" *ngIf="!this.loading">
    <input
      [type]="'file'"
      [id]="element.field_name"
      [ngClass]="{ 'is-invalid': submitted && errors }"
      (change)="onChange($event)"
      class="form-control"
      multiple
    >
    <span>Choose a file…</span>
  </label>
  <mjs-loader *ngIf="this.loading"></mjs-loader>
  <div *ngIf="submitted && errors" class="invalid-feedback">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description d-flex" *ngIf="element.description">
    <p class="mb-0">{{ element.description }}</p>
  </div>
</div>
  