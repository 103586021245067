import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { ParamsService } from '@app/modules/recruiter/services';

@Component({
  selector: 'mjs-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [UserService, ParamsService],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit, OnDestroy {

  content: any;
  profile: any;
  params: any;
  loading: boolean;
  recruiter: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private toast: ToastrService,
    private userService: UserService,
    private paramsService: ParamsService) {
    this.loading = true;
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    if (isPlatformBrowser(this.platform)) {
      this.loading = false;
      this.profile = <any>this.oauthService.getIdentityClaims();

      if (this.profile.admin_menu) {
        this.recruiter = true;
      }

      this.params = {
        field_security_question: this.profile.field_security_question,
        field_security_answer: this.profile.field_security_answer,
        existing: '',
        pass: ''
      };
    }
  }

  submit(params) {
    this.loading = true;

    let val = this.paramsService.format(params.params, params.structure);

    this.userService.patch(this.profile.uid, val)
    .pipe(takeUntil(this.onDestroy$)).subscribe(
      (data: any) => {
        this.oauthService.refreshToken().then(res => {
          this.oauthService.loadUserProfile().then((res: any) => {
            this.profile = res.info;

            this.params = {
              field_security_question: this.profile.field_security_question,
              field_security_answer: this.profile.field_security_answer,
              existing: '',
              pass: ''
            };

            this.toast.success('Your settings have been updated');
            this.loading = false;
          })
        });
      },
      err => {
        this.params = params.params;
        this.loading = false;
        this.toast.error(err.error.message);
      }
    )
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
