import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class MjsJobService {

  constructor(private http: HttpClient) {}

  list(org: number, page: number, open: '-1' | '0' | '1') {
    return this.http.get(`${environment.apiUrl}/api/v2/recruiter-jobs?org=${org}&page=${page}${open != '-1' ? '&open=' + open : ''}`)
  }


  post(data: any) {
    return this.http.post(`${environment.apiUrl}/api/v2/jobs`, data);
  }

  patch(data: any, id: number) {
    return this.http.patch(`${environment.apiUrl}/api/v2/jobs/${id}`, data);
  }

  get(org: number, id: number, prefix_fields?: boolean) {
    let url = `${environment.apiUrl}/api/v2/recruiter/job?org=${org}&id=${id}`;

    if (prefix_fields) {
      url = `${url}&prefix_fields=true`;
    }

    return this.http.get(url);
  }

  delete(org, id) {
    return this.http.delete(`${environment.apiUrl}/api/v2/recruiter/job?org=${org}&id=${id}`);
  }

  export(data: any) {
    let params = new HttpParams();

    for (let key in data) {
      if (data[key]) {
        params = params.append(key, data[key]);
      }
    }

    return this.http.get(`${environment.apiUrl}/api/v2/recruiter-jobs/export`, { params });
  }


}
