import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '../../services/user.service';
import { ParamsService } from '../../services/params.service';

@Component({
  selector: 'mjs-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [UserService, ParamsService],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit, OnDestroy {
  params: any;
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private toast: ToastrService,
    private paramsService: ParamsService,
    private oauthService: OAuthService) {
  }

  ngOnInit() {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    if (isPlatformBrowser(this.platform)) {

      let path = parseInt(this.route.pathFromRoot[2].snapshot.url[2].path, 10);
      this.profile = this.oauthService.getIdentityClaims();

      if (path) {
        this.entity_id = path;
        this.content.title = 'Edit User';
      }
      else {
        this.content.title = 'Create User';
      }

      const parsed = parseInt(this.route.pathFromRoot[2].snapshot.url[2].path, 10);
      this.entity_id = parsed > 0 ? parsed : null;

      this.loading = false;
    }
  }

  submit = (data) => {
    this.loading = true;
    const params = this.paramsService.format(data.params, data.structure);
    const uid = this.profile.uid;

    if (this.entity_id) {
      this.userService.patch(params, this.entity_id).pipe(takeUntil(this.onDestroy$)).subscribe(
        (res: any) => {
          if (res.uid === uid) {
            this.oauthService.refreshToken().then(data => {
              this.oauthService.loadUserProfile().then((data: any) => {
                this.onSuccess(res);
              })
            })
          }
          else {
            if (!this.entity_id) {
              this.router.navigate([this.content.org.path + '/users/' + res.uid]);
            }
            else {
              this.onSuccess(res);
            }
          }
        },
        err => this.onError(data, err)
      );
    } else {
      this.userService.post(params, this.content.org.id)
        .pipe(takeUntil(this.onDestroy$)).subscribe(
        (res: any) => this.router.navigate([this.content.org.path + '/users/' + res.uid]),
        err => this.onError(data, err)
      );
    }
  }

  onError(data, err) {
    this.params = data.params;
    this.loading = false;
    this.toast.error(err.error.message)
  }

  onSuccess(res) {
    this.params = res.params;
    this.toast.success('User has been updated');
    this.loading = false;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
