import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: '[app-date]',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;

  public ngOnInit(): void {
    this.element.class = '';

    if (this.element.required) {
      this.element.class += ' required';
    }

    if (this.element.container_class) {
      this.element.class += ' ' + this.element.container_class;
    }
  }
}
