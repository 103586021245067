<mjs-recruiter-header
*ngIf="content"
[content]="content">
</mjs-recruiter-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="this.content && this.content.org">
          <li>
            <a
              [routerLink]="this.content.org.path + '/orders'"
              routerLinkActive="active">
              Orders
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/purchase'"
              routerLinkActive="active">
              Purchase
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-tokens'"
              routerLinkActive="active">
              Job Tokens
            </a>
          </li>
          <li>
            <a
              [routerLink]="this.content.org.path + '/mjs-job-extension-tokens'"
              routerLinkActive="active">
              Job Extension Tokens
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
<div class="row">
  <main class="col">
    <ng-container *ngIf="!fragment">
      <p><strong>Please select a product below to get started.</strong></p>
      <div class="row">
        <div class="col-12 col-sm-6 mb-3">
          <a [routerLink]="'.'" fragment="mjs-job-tokens" 
            class="mjs-product-link d-flex flex-column justify-content-end pt-4 h-100 bg-white shadow border-bottom border-primary rounded">
            <span class="p-3 font-weight-bold">Job Tokens</span>
          </a>
        </div>
        <div class="col-12 col-sm-6 mb-3">
          <a [routerLink]="'.'" fragment="mjs-job-extension-tokens"
            class="mjs-product-link d-flex flex-column justify-content-end pt-4 h-100 bg-white shadow border-bottom border-primary rounded">
            <span class="p-3 font-weight-bold">Job Extension Tokens</span>
          </a>
        </div>
      </div>
    </ng-container>
    <!-- <p>Purchasing tokens is not possible due to a system upgrade, please bear with us.</p> -->
    <h2 *ngIf="purchaseTitle">{{ purchaseTitle }}</h2>
    <ng-container *ngIf="fragment">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <ng-container *ngIf="!loading && formData">
        <div [innerHtml]="content.body" *ngIf="content.body"></div>
        <div id="mjs-order-purchase-form">
          <mjs-entity-form
            [formData]="formData"
            [params]="params"
            (valueChanges)="valueChanges($event)"
            (submitForm)="submit($event)"
          ></mjs-entity-form>
        </div>
      </ng-container>
    </ng-container>
  </main>
  <div class="col sidebar">
    <mjs-recruiter-menu
      [profile]="content"
    ></mjs-recruiter-menu>
    <mjs-advert [type]="'doublempu'"></mjs-advert>
  </div>
</div>
</div>
