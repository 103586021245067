import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class ActivationService {

  constructor(private http: HttpClient) {}

  post(id: number, timestamp: number, token: string) {
    return this.http.patch(`${environment.apiUrl}/api/v1/user/activate`, {
      id, timestamp, token
    });
  }

}
