import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ChangeDetectorRef
} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { QuillService } from '@app/services/quill.service';

@Component({
  selector: '[app-textarea]',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextareaComponent implements OnInit, AfterViewInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;
  @Input() fileUrl: string;

  browser: boolean = false;
  loaded: boolean = false;
  formats = [
    'bold',
    'italic',
    'link',
    'strike',
    'underline',
    'header',
    'indent',
    'list',
    'align',
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private readonly document: Document,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private quill: QuillService) { }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const head = this.document.getElementsByTagName('head');
      const quillCss = this.document.getElementById('quill-core-css');
      const quillSnowCss = this.document.getElementById('quill-snow-css');

      if (!quillCss) {
        const quillcore = this.document.createElement('link');
        quillcore.id = 'quill-core-css';
        quillcore.rel = 'stylesheet';
        quillcore.href = 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.min.css';
        this.renderer.appendChild(head[0], quillcore);
      }

      if (!quillSnowCss) {
        const snow = this.document.createElement('link');
        snow.id = 'quill-snow-css';
        snow.rel = 'stylesheet';
        snow.href = 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.snow.min.css';
        this.renderer.appendChild(head[0], snow);
      }

      this.browser = true;
      this.loaded = true;
    }
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.browser = true;
      this.loaded = true;
    }
    this.cdRef.detectChanges();
  }
}
