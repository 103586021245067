import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileService } from '@app/shared/services/file.service';
import { DrupalFileService } from '@app/services/drupal-file.service';

@Component({
  selector: 'mjs-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  providers: [FileService, DrupalFileService],
  encapsulation: ViewEncapsulation.None
})
export class ImageComponent {

  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;
  @Input() uploadUrl: string;
  @Input() entityType: string;
  @Input() entityBundle: string;

  loading: boolean;
  imagePreviewUrl: string | ArrayBuffer;

  constructor(private toast: ToastrService,
    private fileService: FileService,
    private drupalFileService: DrupalFileService) { }

  removeItem(item) {
    this.element.value = null;
    this.element.preview = null;
  }

  onChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = event => {
        this.imagePreviewUrl = reader.result;
      };

      this.loading = true;
      const file = event.target.files[0];
      let mimetype = file.type;

      this.drupalFileService.post(this.entityType,
        this.entityBundle,
        this.element.field_name,
        file.name,
        mimetype,
        event.target.files[0])
        .subscribe({
          next: (res: any) => {
            this.element.preview = res.url;
            this.loading = false;
            this.parentForm.controls[this.element.field_name].patchValue(res.fid.toString());
          },
          error: (err: any) => {
            this.toast.error(err.error.message);
            this.loading = false;
          }
        });
    }
  }

}

