<div
  class="form-group date-picker-element"
  *ngIf="element"
  [formGroup]="parentForm"
  [ngClass]="{ required: element.required }">
  <label [for]="element.field_name">
    {{ element.label }}
    <span *ngIf="element.required" class="required-flag">[Required]</span>
  </label>
  <input
    [id]="element.field_name"
    [formControlName]="element.field_name"
    placeholder="Select"
    class="form-control"
    [disabled]="element.disabled"
    [ngClass]="{ 'is-invalid': submitted && errors }"
    matInput [matDatepicker]="picker"
  >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <div *ngIf="submitted && errors" class="invalid-feedback">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>
  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div>
</div>
