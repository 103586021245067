import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class SavedSearchService {

  constructor(private http: HttpClient) {}

  get(token: string) {
    return this.http.get(`${environment.apiUrl}/api/v1/saved_search?token=${token}`);
  }

}
