<div class="read-more">
  <span
    id="show-more"
    [innerHtml]="content"
    [ngClass]="{collapsed: !expanded}">
  </span>
  <a
    aria-controls="show-more"
    [attr.aria-expanded]="expanded || 'false'"
    (click)="toggle()">
    Read {{ expanded ? 'Less' : 'More' }} <span class="sr-only">about this page content</span>
  </a>
</div>