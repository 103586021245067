import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Renderer2,
  Inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  trigger,
  transition,
  style,
  animate,
  stagger,
  query
} from '@angular/animations';
import { throwError, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CareerHubService } from '../../services/career-hub.service';
import { Content } from '@app/models';
import { WebpService } from '@app/services';

@Component({
  selector: 'mjs-career-hub-articles',
  templateUrl: './career-hub-articles.component.html',
  styleUrls: ['./career-hub-articles.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        query('.fade-in', [
          style({
            opacity: '0'
          }),
          stagger(100, [
            animate('.5s ease-out', style({
              opacity: '1'
            }))
          ])
        ])
      ])
    ])
  ]
})
export class CareerHubArticlesComponent implements OnInit, OnDestroy {

  content: Content;
  journeys: any;
  loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private doc,
    private service: CareerHubService,
    public webp: WebpService) {
  }

  ngOnInit() {
    this.route.data.subscribe(res => {
      this.content = res['content'];
      this.journeys = res['journeys'];

      for (let i = 0; i < this.journeys.length; i += 1) {
        this.getArticles(i, 0);
      }
    });
  }

  getArticles(index, page): void {
    this.service.list(this.journeys[index].id, page)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: data => {
          this.journeys[index].articles = data;
          this.loading = false;
        },
        error: error => {
          this.loading = false;
          throwError(error);
        }
      });
  }

  dotsPagination(journey): number[] {
    if (journey.articles?.pages > 1) {
      return Array.from({ length: journey.articles.pages }, (v, i) => i);
    }

    return [];
  }

  loadMore(index, direction) {
    this.journeys[index].loading = true;

    let page = this.journeys[index].articles.page;

    if (direction == 'back') {
      page = page - 2;
    }

    this.service.list(this.journeys[index].id, page)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: data => {
          this.journeys[index].articles = data;
          this.journeys[index].loading = false;
        },
        error: error => {
          this.journeys[index].loading = false;
          throwError(error);
        }
      });
  }

  loadPage(index: number, page: number): void {
    this.journeys[index].loading = true;

    this.service.list(this.journeys[index].id, page)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: data => {
          this.journeys[index].articles = data;
          this.journeys[index].loading = false;
        },
        error: error => {
          this.journeys[index].loading = false;
          throwError(error);
        }
      });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.doc.body, 'full-width');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
