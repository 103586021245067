import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganisationFiltersService {

  constructor(private http: HttpClient) {}

  get = () => this.http.get(`${environment.apiUrl}/api/v1/organisation-filters`);

}
