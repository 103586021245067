import { Component, OnInit, ViewEncapsulation, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { CompleteRegistrationService } from '@modules/user/services/complete-registration.service';
import { ReCaptchaV3Service } from "@node_modules/ng-recaptcha";

declare var window: any;

@Component({
  selector: 'mjs-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
  providers: [CompleteRegistrationService],
  encapsulation: ViewEncapsulation.None
})
export class CompleteRegistrationComponent implements OnInit {

  content: any;
  loading: boolean;
  id: number;
  timestamp: number;
  token: string;
  submitted: boolean;
  params: any;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private toast: ToastrService,
    private analytics: Angulartics2GoogleAnalytics,
    private completeRegistrationService: CompleteRegistrationService) { }

  ngOnInit(): void {
    this.renderer.addClass(this.doc.body, 'full-width');
    this.renderer.addClass(this.doc.body, 'gray');

    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
        this.id = Number(paramMap.get('id'));
        this.timestamp = Number(paramMap.get('timestamp'));
        this.token = paramMap.get('token');

        if (!this.id && !this.timestamp && !this.token) {
          this.router.navigate(['/user/reset']);
        }
      });
  }

  submit(params: any): any {
    this.loading = true;

    params.id = this.id;
    params.timestamp = this.timestamp;
    params.token = this.token;

    const data = {
      ...params.params,
      id: this.id,
      timestamp: this.timestamp,
      token: this.token
    }

    delete data.confirm__pass;

    this.recaptchaV3Service
      .execute('create_alert')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (token) => {
          data.recaptcha = token;
          this.complete(data);
        },
        error: err => {
          this.loading = false;
          this.toast.error('Recaptcha validation failed');
        }
      });
  }

  complete(data): void {
    this.completeRegistrationService.patch(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data: any) => {
          this.analytics.eventTrack('submit', {
            category: 'Completed Registration',
            label: 'Email only account to full account'
          });

          if (isPlatformBrowser(this.platformId)) {
            // GA4 event.
            window.gtag('event', 'Completed Registration', {
              label: 'Email only account to full account',
            });
          }

          this.router.navigate(['/user/login']);
          this.toast.success('Your account is now complete. Please sign in using the details you provided.');
        },
        error: err => {
          this.loading = false;
          this.toast.error(err?.error?.message ?? 'Error');
        }
      });
  }

  ngOnDestroy(): void {
    this.renderer.addClass(this.doc.body, 'full-width');
    this.renderer.addClass(this.doc.body, 'gray');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
