import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class CareerHubService {

  constructor(private http: HttpClient) {}

  get() {
    let url = `${environment.apiUrl}/api/v1/career-hub-journeys?_format=json`;

    return this.http.get<any>(url);
  }

  list(id, page) {
    let url = `${environment.apiUrl}/api/v1/career-hub?_format=json&journey=${id}&page=${page || 0}`;

    return this.http.get<any>(url);
  }

}
