import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RacRoutingModule } from './rac-routing.module';
import { RacComponent } from './rac.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [
    RacComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    RacRoutingModule,
    SharedModule
  ]
})
export class RacModule { }
