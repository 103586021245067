import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class ResetService {

  constructor(private http: HttpClient) {}

  post(mail: string, recaptcha: string, completeRegistration?: boolean) {
    return this.http.post(`${environment.apiUrl}/api/v1/user/reset?recaptcha=${recaptcha}${completeRegistration ? '&complete-registration=true' : ''}`, {
      mail: mail
    })
  }
  
}
