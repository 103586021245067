import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { User } from '@app/models';

@Injectable()
export class ProfileService {
  private currentAuthSubject: BehaviorSubject<User>;
  public currentAuth: Observable<User>;

  constructor(private oauthService: OAuthService,
    @Inject(PLATFORM_ID) private platform: any) {

    if (isPlatformBrowser(this.platform)) {
      const profile = <User>this.oauthService.getIdentityClaims();
      this.currentAuthSubject = new BehaviorSubject<User>(profile);
      this.currentAuth = this.currentAuthSubject.asObservable();
    }
  }

  public get currentAuthValue(): User {
    return this.currentAuthSubject.value;
  }

  set() {
    return this.currentAuthSubject.next(<User>this.oauthService.getIdentityClaims());
  }

  delete() {
    return this.currentAuthSubject.next(null);
  }

}
