import { Component, OnInit ,Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DrupalFileService } from '@app/services/drupal-file.service';

@Component({
  selector: '[app-file]',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  providers: [DrupalFileService],
  encapsulation: ViewEncapsulation.None
})
export class FileComponent implements OnInit, OnDestroy {

  @Input() parentForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() element: any;
  @Input() errors: any;
  @Input() uploadUrl: string;
  @Input() entityType: string;
  @Input() entityBundle: string;

  loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private toast: ToastrService,
    private drupalFileService: DrupalFileService) { }

  fileName(file) {
    let filename = decodeURI(file.split('/').reverse()[0]);

    return filename;
  }

  ngOnInit(): void {
    this.element.class = '';

    if (this.element.required) {
      this.element.class += ' required';
    }

    if (this.element.container_class) {
      this.element.class += ' ' + this.element.container_class;
    }
  }

  removeItem(index) {
    let formValue = this.parentForm.controls[this.element.field_name].value;
    formValue.splice(index, 1);
    this.element.value = formValue;
    this.parentForm.controls[this.element.field_name].setValue(formValue);
  }

  onChange(event) {
    this.loading = true;

    for (let i = 0; i < event.target.files.length; i += 1) {
      let file = event.target.files[i];
      let mimetype = file.type;

      this.drupalFileService.post(this.entityType,
        this.entityBundle,
        this.element.field_name,
        file.name,
        mimetype,
        event.target.files[i])
        .subscribe({
          next: (res: any) => {
            if (!this.element.value) {
              this.element.value = [];
            }
            this.element.value.push(res);
            this.parentForm.controls[this.element.field_name].patchValue(this.element.value);
            this.loading = false;
          },
          error: (err) => {
            this.toast.error(err.error.message);
            this.loading = false;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
