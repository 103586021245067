import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { JobAdvert } from '@app/models';
import { Observable } from 'rxjs';

@Injectable()
export class JobAdvertService {

  constructor(private http: HttpClient) {}

  get(id: string): Observable<JobAdvert> {
    return this.http.get<JobAdvert>(`${environment.apiUrl}/api/v2/rac/job?job_id=${id}&_format=json`);
  }

}
