import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class WebpService {

  public defaultImage(img): string {
    if (!img) {
      return '';
    }

    // Add backend URL if not present to avoid proxying via express to see if it
    // helps performance.
    if (img.substring(0, 4) !== 'http' && img.substring(0, 2) !== '//') {
      return environment.apiUrl + img;
    }

    // @Todo bypass this till I make sure webp is working on the backend.
    return img;

    if (img.indexOf('myjobscotland-share') > -1) {
      // Only add www. if this is the live environment.
      if (environment.apiUrl === 'https://admin.myjobscotland.gov.uk') {
        return img.replace('/api.', '/www.').replace('/admin.', '/www.');
      } else {
        return img.replace('/api.', '/').replace('/admin.', '/');
      }
    }

    const types = ['jpg', 'jpeg', 'png'];
    let ext;
    let parts = img.split('.');
    ext = parts.pop();
    ext = ext.split('?');

    if (types.indexOf(ext[0]) > -1) {
      parts.push(`webp`);
    } else {
      parts.push(ext[0]);
    }

    if (environment.apiUrl === 'https://admin.myjobscotland.gov.uk') {
      return parts.join('.').replace('/api.', '/www.').replace('/admin.', '/www.');
    } else {
      return parts.join('.').replace('/api.', '/').replace('/admin.', '/');
    }
  }

}
