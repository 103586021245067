import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mjs-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReadMoreComponent {

  @Input() data: string;
  @Input() preview: number;

  expanded: boolean;
  html: string;

  constructor() {
    this.expanded = false;
    if (!this.preview) this.preview = 280;
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  get content() {
    if (this.expanded) {
      return this.data;
    }

    if (this.data.replace(/(<([^>]+)>)/ig, '').length < this.preview) {
      return this.data
    }

   return `<p>${this.data.replace(/(<([^>]+)>)/ig, '').substring(0, this.preview)}...</p>`;
  }

}
