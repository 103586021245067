import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Renderer2,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Content } from '@app/models';

@Component({
  selector: 'mjs-career-hub-article',
  templateUrl: './career-hub-article.component.html',
  styleUrls: ['./career-hub-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CareerHubArticleComponent implements OnInit, OnDestroy {
  
  content: Content;
  hideMenu: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    @Inject(DOCUMENT) private doc: Document,
    private route: ActivatedRoute,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.content = data['content'];

      if (this.content.full_width) {
        this.renderer.addClass(this.doc.body, 'full-width');
      } else {
        this.renderer.removeClass(this.doc.body, 'full-width');
      }

      if (this.content.gray) {
        this.renderer.addClass(this.doc.body, 'gray');
      } else {
        this.renderer.removeClass(this.doc.body, 'gray');
      }
    });
  }

  public toggleMenu() {
    this.hideMenu = !this.hideMenu;
  }

  public ngOnDestroy():void {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    if (this.content.full_width) {
      this.renderer.removeClass(this.doc.body, 'full-width');
    }

    if (this.content.gray) {
      this.renderer.removeClass(this.doc.body, 'gray');
    }
  }

}
