import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CareerHubSearchService } from '@app/modules/career-hub/services/career-hub-search.service';
import { Content } from '@app/models';

interface SearchResult {
  title: string;
  path: string;
  summary: string;
}

@Component({
  selector: 'mjs-career-hub-search',
  templateUrl: './career-hub-search.component.html',
  styleUrls: ['./career-hub-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CareerHubSearchService]
})
export class CareerHubSearchComponent implements OnInit, OnDestroy {
  
  content: Content;
  keywords: string;
  results: Array<SearchResult>;
  loading: boolean;
  hideMenu: boolean = false;
  form: UntypedFormGroup = new UntypedFormGroup({ });

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private searchService: CareerHubSearchService,
    private router: Router) {
  }

  ngOnInit() {
    this.route.data.subscribe(res => {
      this.content = res['content'];

      this.content.menu[0].children.push({
        title: this.content.title,
        url: this.content.path
      });
      
    });

    this.form.addControl('keywords', new UntypedFormControl(''));
    this.form.addControl('tag', new UntypedFormControl(''));

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
      const params = paramMap.keys;

      for (let i = 0; i < params.length; i += 1) {
        let key = params[i];
        const param = paramMap.get(key);

        if (key === 'keywords') {
          this.form.controls['keywords'].setValue(param);
        }

        if (key === 'tag') {
          this.form.controls['tag'].setValue(param);
        }
      }

      this.searchService.get(this.form.value).pipe(takeUntil(this.onDestroy$))
        .subscribe((results: Array<SearchResult>) => {
        this.results = results;
      });

    });
  }

  public toggleMenu() {
    this.hideMenu = !this.hideMenu;
  }

  public onSubmit() {
    const queryParams: any = {};

    if (this.form.value.keywords) {
      queryParams.keywords = this.form.value.keywords;
    }

    if (this.form.value.tag) {
      queryParams.tag = this.form.value.tag;
    }

    this.router.navigate(['/career-hub/search'], {
      queryParams: queryParams
    });
  }

  public ngOnDestroy():void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
