import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mjs-recruiter-header',
  templateUrl: './recruiter-header.component.html',
  styleUrls: ['./recruiter-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecruiterHeaderComponent {
  @Input('content') content: any;
}
