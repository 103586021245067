import {
  ApplicationRef,
  Component,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { ToastNoAnimation, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'mjs-toast',
  templateUrl: './mjs-toast.component.html',
  styleUrls: ['./mjs-toast.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MjsToastComponent extends ToastNoAnimation implements AfterViewInit, OnDestroy {

  private toastTimeout: any;

  constructor(protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    protected appRef: ApplicationRef) {
    super(toastrService, toastPackage, appRef);
  }

  ngAfterViewInit() {
    const focusableEls1 = document.getElementById('toast-container').querySelectorAll<any>(
      'a[href], button, textarea, input[type="text"],' +
      'input[type="radio"], input[type="checkbox"], select'
    );

    const focusableEls = Array.from(focusableEls1)
      .filter( (el: any) => !el.disabled);
    const firstFocusableEl: any = focusableEls[0];

    this.toastTimeout = setTimeout(function() {
      firstFocusableEl.focus();
    }, 0);
  }

  public ngOnDestroy(): void {
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
    }
  }
}
