<div class="circular-loader" role="status">
  <span class="sr-only">Loading...</span>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
<div class="loader-message text-center" *ngIf="message">
  <p role="status">
    <strong [innerHtml]="message + '...'"></strong>
  </p>
</div>