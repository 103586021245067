import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagesService } from '@app/services/messages.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';
import { MjsJobExtensionTokensService } from '../../services/mjs-job-extension-tokens.service';

@Component({
  selector: 'mjs-mjs-job-extension-tokens',
  templateUrl: './mjs-job-extension-tokens.component.html',
  styleUrls: ['./mjs-job-extension-tokens.component.scss'],
  providers: [MjsJobExtensionTokensService],
  encapsulation: ViewEncapsulation.None
})
export class MjsJobExtensionTokensComponent implements OnInit, OnDestroy {
  
  params: any;
  content: any;
  entity_id: number;
  loading: boolean;
  data: any;
  page = 1;
  messages: any;
  columns = ['id', 'job_advert', 'created', 'status', 'order_id'];
  showActions: boolean = environment.paypalMethod === 'sdk';

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private mjsJobExtensionTokensService: MjsJobExtensionTokensService,
    private messagesService: MessagesService,
    private toast: ToastrService) {
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });
    
    this.messages = this.messagesService.currentValue;

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
      this.page = parseInt(queryParams.get('page'), 10) || 1;

      if (isPlatformBrowser(this.platform)) {
        this.search();
      }
    });
  }

  search() {
    this.loading = true;

    this.mjsJobExtensionTokensService.list(this.content.org.id, this.page)
    .pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.data = data;
      },
      error: err => {
        this.loading = false;
      }
    });
  }

  toggleElementActions(element) {
    for (let i = 0; i < this.data.list.length; i += 1) {
      if (element.id === this.data.list[i].id) {
        this.data.list[i].active = !this.data.list[i].active;
      }
      else {
        this.data.list[i].active = false;
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
