import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValue'
})
export class KeyValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let keys = [];

    for (let key in value) {
        keys.push({ key, value: value[key] });
    }

    return keys;
  }

}
