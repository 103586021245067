<div class="education-hub-title">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 [innerHtml]="content.title"></h1>
      </div>
    </div>
  </div>
</div>
<div class="education-hub-home-header">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="intro" [innerHtml]="content.intro"></div>
        <div class="council-selector">
          <div class="form-group custom-select mb-4">
            <label for="council-select"><strong>Select a council</strong></label>
            <select id="council-select" class="form-control" (change)=selectCouncil($event)>
              <option value="">-- Select a council --</option>
              <option *ngFor="let council of content.views.councils_map__rest; let i = index" [value]="i" [innerHtml]="council.name">
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="council" class="education-hub-council-summary">
          <!-- <img [src]="council.logo" [alt]="council.display_name || council.name"> -->
          <h2 [innerHtml]="council.display_name || council.name"></h2>
          <p [innerHtml]="council.summary"></p>
          <a class="btn btn-outline-primary" [routerLink]="council.path + '/jobs'">View Jobs</a>
        </div>
      </div>
      <div class="col-md-5 d-none d-md-block d-lg-block">
        <div class="councils-map-wrap">
          <svg
            version="1.1"
            id="councils-map"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 633.8 952.2"
            style="enable-background:new 0 0 633.8 952.2;"
            xml:space="preserve">
            <g transform="scale(1,1.7)">
              <path
                *ngFor="let council of content.views.councils_map__rest; let i = index"
                [attr.d]="council.svg_path"
                [attr.opacity]="council.opacity"
                (click)=selectCouncil(i)
                fill="#308091"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main>
      <div [innerHtml]="content.body"></div>
    </main>
  </div>
</div>
