import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';

@Component({
  selector: 'mjs-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KeywordsComponent implements OnInit {
  
  @ViewChild('keywordInput', {static: true}) keywordInput: ElementRef;

  @Input() keywords: string;
  @Input() auto: boolean;
  @Input() searchRoute: string;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() unlockFacets: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  isSearching: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({ });
    this.form.addControl('keywords', new UntypedFormControl(this.keywords));

    fromEvent(this.keywordInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      ,filter(res => res.length > 2)
      ,debounceTime(1000)
      ,distinctUntilChanged()
      ).subscribe((text: string) => {
        this.isSearching = true;
        return this.search(text)
      });
  }

  search(text: string): void {
    this.keywords = text;
    this.valueChange.emit(this.keywords);
    this.navigate();
  }

  onReturn(event: any): void {
    if (event.charCode == 13) {
      this.unlockFacets.emit();
      this.keywords = event.target.value;
      
      this.router.navigate([this.searchUrl], {
        relativeTo: this.route,
        queryParams: {
          keywords: this.keywords,
          page: 1
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  clearKeywords(): void {
    this.keywords = '';
    this.form.controls['keywords'].setValue('');
    this.navigate();
  }

  navigate(): void {
    if (this.auto) {
      this.unlockFacets.emit();
      this.router.navigate([this.searchUrl], {
        relativeTo: this.route,
        queryParams: {
          keywords: this.keywords,
          page: 1
        },
        queryParamsHandling: 'merge'
      });
    } else {
      this.valueChange.emit(this.keywords);
    }
  }

  get searchUrl() {
    if (this.router.url.split('?')[0] === '/') {
      return '/search';
    }

    return this.router.url.split('?')[0];
  }

}
