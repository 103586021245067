import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { OAuthStorage, OAuthService } from 'angular-oauth2-oidc';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { authConfig } from '@app/auth.config';
import { environment } from '@env/environment';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class DefaultOAuth2Interceptor implements HttpInterceptor {

  constructor(private authStorage: OAuthStorage,
    @Inject(PLATFORM_ID) private platform: any,
    private router: Router,
    private route: ActivatedRoute,
    private storage: SessionStorageService,
    private oauth: OAuthService) { }

  private checkUrl(url: string): boolean {
    let found = environment.resourceUrls.find(u => url.startsWith(u));
    return !!found;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let url = req.url.toLowerCase();

    if (isPlatformBrowser(this.platform)) {
      // Add app version header to all requests.
      let headers = req
        .headers
        .set('App-Version', environment.version)
        .set('App-Environment', environment.production ? 'production' : 'development');

      req = req.clone({ headers });

      if (!authConfig) return next.handle(req);
      if (!environment.resourceUrls) return next.handle(req);
      if (!this.checkUrl(url)) return next.handle(req);

      let token = this.authStorage.getItem('access_token');

      if (token && url.indexOf('oauth/token') < 0) {
        let header = 'Bearer ' + token;
        let headers = req.headers.set('Authorization', header);

        req = req.clone({ headers });
      }
    }

    return next.handle(req).pipe(map(res => res),
      catchError(err => {
        if (err.headers.get('X-Offline')) {
          this.storage.store('offlineContent', err.error);
          this.router.navigate(['offline']);
        }

        // Handle failed requests due to invalid token.
        if (err.status === 401) {
          if (req.headers.get('Authorization')) {
            this.oauth.logOut(true);

            this.router.navigate(['/user/login'], {
              queryParams: {
                message: 'session_expired'
              }
            });
          }
        }

        if (err.status === 403 &&
          this.router.routerState.snapshot.url.substring(0,6) === '/user/') {
          this.oauth.logOut(true);

          this.router.navigate(['/user/login'], {
            queryParams: {
              message: 'session_expired'
            }
          });
        }

        return throwError(err);
      }));

  }

}
