import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class ParseRedirectService {

  constructor(@Inject(PLATFORM_ID) private platform: string) {}

  get(string: string, claims: any) {
    if (!string || string.length === 0) {
      if (!isPlatformServer(this.platform)) {
        if (claims.roles && claims.roles.length > 1) {
          if (claims.roles.indexOf('organisation') || claims.roles.indexOf('agency')) {
            return {
              redirect: '/my-organisations',
              params: {}
            };
          }
        }
      }

      if (claims.field_password_expiration) {
        return {
          redirect: '/user/settings',
          params: {}
        };
      }

      return {
        redirect: '/user/applications',
        params: {}
      };


    }

    let redirect = string;
    const params = <any>{};

    if (string.indexOf('?') > -1) {
      const parts = string.split('?');
      redirect = parts[0];

      const queryParams = parts[1].split('&');

      for (let i = 0; i < queryParams.length; i += 1) {
        const param = queryParams[i].split('=');
        params[param[0]] = param[1];
      }
    }

    return { redirect, params };
  }

}
