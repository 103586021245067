import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { response } from 'express';
import { AuthService } from './auth.service';
import { catchError, tap, throwError } from 'rxjs';

@Injectable()
export class UserService {

  constructor(private http: HttpClient, private authService: AuthService) {}

  async get() {
    return this.http.get(`${environment.apiUrl}/api/v1/user/profile`);
  }

  patch(id: number, params: any) {
    const body = { };

    for (const key in params) {
      if (key !== 'recaptcha') {
        body[key] = {value: params[key]};
      }
    }

    if (body['pass'] && body['existing']) {
      if (!body['pass']['value']) {
        delete body['pass'];
        delete body['existing'];
      } else {
        body['pass'].existing = body['existing'].value;
        delete body['existing'];
        body['pass'] = [body['pass']];
        body['field_password_expiration'] = false;
      }
    }

    return this.http.patch(`${environment.apiUrl}/api/v1/user/edit/${id}`, { ...body }).pipe(
      tap(response => {
        // Successful update and intention to change passwor, reset password expiration
        if (response && body['field_password_expiration'] === false) {
          this.authService.resetPasswordExpiration();
        }
      })
    );
  }

}
