import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { CouncilMapOrganisation } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class CouncilMapService {

  constructor(private http: HttpClient) {}

  get(): Observable<CouncilMapOrganisation[]> {
    return this.http.get<CouncilMapOrganisation[]>(`${environment.apiUrl}/api/v1/councils-map?_format=json`);
  }

}
