import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router, Resolve, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CareerHubService } from './career-hub.service';

@Injectable()
export class JourneysResolver implements Resolve<any> {

  constructor(private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private service: CareerHubService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    let content_key = 'career_hub_journeys';
    const CONTENT_KEY = makeStateKey<any>(content_key);

    if (this.transferState.hasKey(CONTENT_KEY)) {
      const content = this.transferState.get<any>(CONTENT_KEY, null);
      this.transferState.remove(CONTENT_KEY);
      return of(content);
    }
    else {
      return this.service.get()
      .pipe(
        tap(journeys => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(CONTENT_KEY, journeys);
          }
          return of(journeys);
        })
     );
    }
  }

}
