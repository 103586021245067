<nav class="priority-nav d-flex" id="fluid-menu">
  <ul aria-hidden="true" class="fluid-nav-ul" id="rendered-menu" [ngClass]="{
    'd-none': displayNone,
    'd-flex': !displayNone 
  }">
    <li *ngFor="let item of nav">
      <a [routerLink]="item.path" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}" [innerHtml]="item.title">
      </a>
    </li>
  </ul>
  <ul class="fluid-nav-ul d-flex">
    <li *ngFor="let item of visibleNav">
      <a [routerLink]="item.path" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}" [innerHtml]="item.title">
      </a>
    </li>
  </ul>
  <button mat-icon-button [matMenuTriggerFor]="menu"
    class="fluid-nav-toggle d-flex flex-column justify-content-center"
    aria-label="Toggle extra navigation" *ngIf="hiddenNav.length > 0">
    <span class="sr-only">Toggle menu</span>
    <i></i><i></i><i></i>
  </button>
  <mat-menu #menu="matMenu">
    <a *ngFor="let item of hiddenNav" mat-menu-item
      [routerLink]="item.path" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}" [innerHtml]="item.title">
    </a>
  </mat-menu>
</nav>
