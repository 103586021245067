import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { OAuthService } from 'angular-oauth2-oidc';
import { FavouritesService } from '@app/modules/user/services';

declare var window: any;

@Component({
  selector: 'mjs-likes',
  templateUrl: './likes.component.html',
  styleUrls: ['./likes.component.scss'],
  providers: [FavouritesService],
  encapsulation: ViewEncapsulation.None
})
export class LikesComponent implements OnInit, OnDestroy {

  content: any;
  list: any;
  loading: boolean;
  profile: any;
  recruiter: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private favourites: FavouritesService,
    private toast: ToastrService,
    private oauthService: OAuthService,
    private router: Router) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });
    this.loading = true;

    if (isPlatformBrowser(this.platform)) {
      this.profile = this.oauthService.getIdentityClaims();

      if (this.profile.admin_menu) {
        this.recruiter = true;
      }

      this.favourites.get().pipe(takeUntil(this.onDestroy$)).subscribe({
        next: data => {
          this.list = data
          this.loading = false;
        },
        error: _ => {
          this.loading = false;
        }
      });
    }

    if (this.content.gray) {
      this.renderer.addClass(this.doc.body, 'gray');
    }

  }

  deleteFavourite(id: number, index: number): void {
    if (confirm('Are you sure you want to delete this favourite job')) {
      this.renderer.selectRootElement('#main-content', true).scrollIntoView();
      this.loading = true;

      this.favourites.patch(id).pipe(takeUntil(this.onDestroy$)).subscribe({
        next: (data: any) => {
          this.list.splice(index, 1);
          this.toast.success(data.message);
          this.loading = false;
        },
        error: err => {
          this.toast.error(err.error.message);
          this.loading = false;
        }
      });
    }
  }

  apply(item): any {
    if (window.lumesse) {
      return window.location.href = `${item.apply_button.path}?${Object.keys(item.apply_button.queryParams).map(key => `${key}=${item.apply_button.queryParams[key]}`).join('&')}`;
    }

    return this.router.navigate(['/rac'], {
      queryParams: item.apply_button.queryParams
    });
  }

  ngOnDestroy(): void {
    if (this.content.gray) {
      this.renderer.removeClass(this.doc.body, 'gray');
    }

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
