<mjs-page-header
  [content]="content"
  [align]="'text-center'">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="text-center login-main">
      <div toastContainer></div>
      <mjs-loader *ngIf="loading"></mjs-loader>
      <mjs-login
        *ngIf="!loading"
        [username]="username"
        [password]="password"
        [submit]="submit">
      </mjs-login>
    </main>
  </div>
</div>