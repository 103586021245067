/**
 * Prepare HttpParams for the backend and create query string for google analytics.
 */

import { HttpParams } from "@angular/common/http";
import { MjsSearchParams } from "../models/mjs-search-params.model";
import { sortValues, distanceValues } from '../search-params';

const paramsToHttpParams = (params: MjsSearchParams, defaultParams): {
  httpParams: HttpParams;
  searchAnalytics: string;
} => {
  let searchAnalytics = [];
  let httpParams = new HttpParams().set('_format', 'json');

  // If no "type" value is present set it to external.
  if (params.type.value.length === 0) {
    params.type.value.push('external');
  }

  for (let key in params) {

    // If a default value exists for a parameter then use that.
    if (defaultParams[key]) {
      if (key.substring(0, 7) === 'parent_') {
        if (!params[key.substring(7)].value.length) {
          searchAnalytics.push(`${key}=${defaultParams[key]}`);

          httpParams = httpParams.append(key, defaultParams[key]);
        }
      } else {
        searchAnalytics.push(`${key}=${defaultParams[key]}`);

        httpParams = httpParams.append(key, defaultParams[key]);
      }

      continue;
    }

    if (params[key].value.length < 1) {
      continue;
    }

    switch (params[key].type) {
      case 'stringArray':
      case 'numberArray':
        if (params[key].arrayType === '+') {
          searchAnalytics.push(`${key}=${params[key].value.join('+')}`);

          httpParams = httpParams.append(key, params[key].value.join('+'));
        } else if (params[key].arrayType === '[]') {
          // Set type to external if no value is present.
          params[key].value.forEach(value => {
            searchAnalytics.push(`${key}[]=${value}`);

            httpParams = httpParams.append(`${key}[]`, value);
          });
        }
        break;

      case 'string':
        switch (key) {
          case 'sort':
            if (sortValues.hasOwnProperty(params[key].value)) {
              searchAnalytics.push(`sort_by=${sortValues[params[key].value].key}`);
              searchAnalytics.push(`sort_order=${sortValues[params[key].value].order}`);

              httpParams = httpParams.set('sort_by', sortValues[params[key].value].key);
              httpParams = httpParams.set('sort_order', sortValues[params[key].value].order);
            }
            break;

          case 'latlng':
            searchAnalytics.push('latlon[value]=' + params[key].value);
            httpParams = httpParams.set('latlon[value]', params[key].value);
            break;

          default:
            searchAnalytics.push(`${key}=${params[key].value}`);
            httpParams = httpParams.append(key, params[key].value);

        }
        break;

      case 'number':
        switch (key) {
          case 'page':
            // The drupal backend pagination starts from 0 but to the user it starts from 1.
            if (params[key].value > 1) {
              searchAnalytics.push(`page=${params[key].value - 1}`);

              httpParams = httpParams.append(key, params[key].value - 1);
            }
            break;

          case 'distance':
            // Only pass distance if a latlon is present.
            if (params['latlng'].value) {
              if (distanceValues.indexOf(params[key].value) > -1) {
                searchAnalytics.push(`latlon[distance][from]=${params[key].value}`);

                httpParams = httpParams.set('latlon[distance][from]', params[key].value);
              } else {
                searchAnalytics.push('latlon[distance][from]=10');

                httpParams = httpParams.set('latlon[distance][from]', 10);
              }
            }
            break;

          case 'refresh_your_career':
          case 'term_time_only':
            if (params[key].value === 1) {
              searchAnalytics.push(`${key}=1`);

              httpParams = httpParams.set(key, 1);
            }
            break;

          default:
            searchAnalytics.push(`${key}=${params[key].value}`);

            httpParams = httpParams.append(`${key}`, params[key].value);
        }
        break;
    }
  }

  if (defaultParams.hasOwnProperty('default_cat')) {
    defaultParams.default_cat.forEach((value, index) => {
      searchAnalytics.push(`default_cat[${index}][org]=${value['org']}`);
      searchAnalytics.push(`default_cat[${index}][cat]=${value['cat']}`);

      httpParams = httpParams.append(`default_cat[${index}][org]`, value['org']);
      httpParams = httpParams.append(`default_cat[${index}][cat]`, value['cat']);
    });
  }

  return {
    httpParams,
    searchAnalytics: searchAnalytics.join('&')
  }
}

export { paramsToHttpParams }
