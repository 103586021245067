import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { ContentRoutingModule } from './content-routing.module';
import { ContentComponent } from './content.component';
import { SearchModule } from '@app/modules/search/search.module';
import { CouncilMapComponent } from './council-map/council-map.component';
import { Angulartics2Module } from 'angulartics2';
// import { ngxZendeskWebwidgetModule, ngxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

/**
 * Zendesk Widget Configuration
 */
// export class ZendeskConfig extends ngxZendeskWebwidgetConfig {
//   accountUrl = 'myjobscotland.zendesk.com';
//   locale = 'en';
//   hide = true;
//   // beforePageLoad(zE) {
//   //   zE.setLocale('en');
//   //   zE.hide();
//   // }
// }

@NgModule({
  declarations: [
    ContentComponent,
    CouncilMapComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ContentRoutingModule,
    SearchModule,
    Angulartics2Module,
    // ngxZendeskWebwidgetModule.forRoot(ZendeskConfig)
  ]
})
export class ContentModule { }
