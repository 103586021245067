<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <ng-container *ngIf="!loading">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col col-12 col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold" for="open">Open / Closed</label>
                <select id="open"
                        class="form-control"
                        formControlName="open">
                  <option value="-1">-- Any --</option>
                  <option value="1">Open</option>
                  <option value="0">Closed</option>
                </select>
              </div>
            </div>
            <div class="col col-12 col-sm-8 text-right">
              <label class="d-none d-md-block">&nbsp;</label>
              <button type="button" class="btn btn-outline-primary" (click)="exportJobs()">Export to CSV</button>
            </div>
          </div>
        </form>

        <div [innerHtml]="content.body" *ngIf="content && content.body"></div>
        <p *ngIf="!data || data.list.length === 0">No jobs to display yet. If you have just posted a job and it isn't listed, please refresh the page or wait a couple of minutes.</p>
        <div class="sticky-table-container" *ngIf="data && data.list.length > 0">
          <div class="table-container">
            <table mat-table [dataSource]="data.list">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Job No/ID </th>
                <td mat-cell *matCellDef="let element"> <span [innerHtml]="element.job_number || element.id"></span> </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let element"> <a [routerLink]="element.path"> <span [innerHtml]="element.title"></span> </a> </td>
              </ng-container>

              <ng-container matColumnDef="published">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Published </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.status }} </td>
              </ng-container>

              <ng-container matColumnDef="allow_applications">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Allow Applications </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.allow_applications }} </td>
              </ng-container>

              <ng-container matColumnDef="search_status">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Show in Search </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.search_status }} </td>
              </ng-container>

              <ng-container matColumnDef="clicks">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Views <small>(Reg/Feat)</small> </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.clicks }} </td>
              </ng-container>

              <ng-container matColumnDef="applications">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Apply Button Clicks <small>(Reg/Feat)</small> </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.applications }} </td>
              </ng-container>

              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Categories </th>
                <td mat-cell *matCellDef="let element" [innerHtml]="element.category"></td>
              </ng-container>

              <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Opens </th>
                <td mat-cell *matCellDef="let element"> {{ element.start_date }} </td>
              </ng-container>

              <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Closes </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.end_date }} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>

            </table>
          </div>
          <div class="sticky-actions">
            <div class="btn-group" *ngFor="let element of data.list">
              <button
                [matMenuTriggerFor]="menu"
                class="btn btn-outline-primary btn-sm dropdown-toggle"
                type="button"
                aria-haspopup="true"
                [attr.aria-expanded]="element.active || 'false'">
                Actions
              </button>
              <mat-menu yPosition="above" class="dropdown-menu" #menu="matMenu">
                <a mat-menu-item
                  [routerLink]="content.org.path + '/jobs/' + element.id">
                  Edit
                </a>
                <a mat-menu-item
                  [routerLink]="content.org.path + '/jobs/create'"
                  [queryParams]="{duplicate: element.id}">
                  Duplicate
                </a>
              </mat-menu>
            </div>
          </div>
        </div>
        <mjs-pager
          *ngIf="data && data.pages > 1"
          [page]="data.page"
          [pages]="data.pages"
          [total]="data.total">
        </mjs-pager>
      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
