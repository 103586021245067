import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { WebpService } from '@app/services';
import { Promotion } from '@app/models';

@Component({
  selector: 'mjs-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromotionsComponent implements OnInit {
  
  @Input() list: Promotion[];
  
  queryParams: any;
  Math: Math = Math;
  Array = Array;

  constructor(public webp: WebpService) {}

  public ngOnInit(): void {
    for (let i = 0; i < this.list.length; i += 1) {
      this.list[i].external = this.list[i].url.substring(0, 4) === 'http';

      if (!this.list[i].external) {
        this.list[i].path = this.parseUrl(this.list[i].url, true);
        this.list[i].params = this.parseUrl(this.list[i].url, false);
      }
    }
  }

  parseUrl(url, provideUrl) {
    let queryParams = {};
    let parts = url.split('?');

    if (provideUrl) {
      return parts[0];
    }

    if (parts.length > 1) {
      let params = parts[1].split('&');

      for (let i = 0; i < params.length; i += 1) {
        let param = params[i].split('=');

        if (param.length === 2) {
          queryParams[`${param[0]}`] = param[1];
        }
      }
    }

    if (!provideUrl) {
      return queryParams;
    }

    return url;
  }

}
