import {
  Component,
  ViewEncapsulation,
  OnDestroy,
  Input,
  OnChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SidebarJobsService } from '@shared/services/sidebar-jobs.service';

@Component({
  selector: 'mjs-featured-jobs-widget',
  templateUrl: './featured-jobs-widget.component.html',
  styleUrls: ['./featured-jobs-widget.component.scss'],
  providers: [SidebarJobsService],
  encapsulation: ViewEncapsulation.None
})
export class FeaturedJobsWidgetComponent implements OnDestroy, OnChanges {

  @Input() categories?: any;

  jobs: any[];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private sidebarJobsService: SidebarJobsService) { }

  ngOnChanges() {
    this.fetchJobs();
  }

  fetchJobs() {
    let query = `?_format=json`;
    const cats = [];
    const parent_cats = [];

    if (this.categories) {
      for (let i = 0; i < this.categories.length; i += 1) {
        if (this.categories[i].parent && !this.categories[i].parent[0].hasOwnProperty('target_id')) {
          cats.push(this.categories[i].id);
        } else {
          parent_cats.push(this.categories[i].id);
        }
      }
    }

    if (cats.length > 0) {
      query += `&cat=${cats.join('+')}`;
    }

    if (parent_cats.length > 0) {
      query += `&parent_cat=${parent_cats.join('+')}`;
    }

    this.sidebarJobsService.get(query).pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
      this.jobs = data.list || null;
    });
  }

  ngOnDestroy():void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
