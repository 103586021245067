const defaultSettings = {
  required: true,
  type: 'string_textfield',
  settings: {
    min_length: 1,
    max_length: 255
  }
};

const billingDetails = [{
  field_name: 'forename',
  label: 'Forename',
  ...defaultSettings
}, {
  field_name: 'surname',
  label: 'Surname',
  ...defaultSettings
}, {
  field_name: 'address_line1',
  label: 'Billing Address Line 1',
  ...defaultSettings
}, {
  field_name: 'address_line2',
  label: 'Billing Address Line 2',
  ...defaultSettings,
  required: false
}, {
  field_name: 'locality',
  label: 'Billing Address City',
  prefix: 'row-start',
  container_class: 'col-12 col-md-6',
  ...defaultSettings
}, {
  field_name: 'postal_code',
  label: 'Billing Address Postcode',
  prefix: 'row-end',
  container_class: 'col-12 col-md-6',
  ...defaultSettings
}];

export default billingDetails;