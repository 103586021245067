import { Component, OnInit, ViewEncapsulation, Renderer2, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPayPalConfig } from '@app/modules/ngx-paypal/models/paypal-models';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { OrderService } from '../../services/order.service';
import { environment } from '@env/environment';

@Component({
  selector: 'mjs-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss'],
  providers: [OrderService],
  encapsulation: ViewEncapsulation.None
})
export class TokenComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public loadingMessage: string;
  public content: any;
  public order: any;
  public payPalConfig: IPayPalConfig;
  public columns: string[] = [
    'name',
    'quantity',
    'unit_amount'
  ];

  public footerColumns: string[] = [
    'name',
    'value'
  ];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2,
    private toast: ToastrService,
    private logger: NGXLogger,
    private orderService: OrderService) {
  }

  ngOnInit() {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });
    
    this.orderService.get(this.route.snapshot.params['token_id'])
      .pipe(takeUntil(this.onDestroy$)).subscribe(
      (data: any) => {
        data.created = new Date(data.created * 1000);
        data.changed = new Date(data.changed * 1000);
        this.order = data;
        this.loading = false;

        if (!data.status) {
          this.initPayPalConfig(data);
        }
      },
      err => {
        this.router.navigate([`${this.content.org.path}/job-tokens`], {
          queryParams: {
            message: 'order_not_found'
          }
        })
      })
  }

  private initPayPalConfig(order: any): void {
    if (!order.paypal_order) {
      return;
    }

    this.payPalConfig = {
      currency: 'GBP',
      clientId: environment.paypal_client_id,
      createOrderOnClient: (data) => order.paypal_order[0].paypal_order,
        advanced: {
          commit: 'true',
          extraQueryParams: [{
            name: 'intent',
            value: 'authorize'
          }]
        },
        style: {
          label: 'paypal',
          layout: 'vertical',
        },
        onApprove: (data, actions) => {
          this.logger.info('onApprove - transaction was approved, but not authorized', data, actions);
          this.loading = true;
          this.loadingMessage = 'Please wait while we process your payment';

          actions.order.authorize().then(details => {
            this.logger.info('onApprove - you can get full order details inside onApprove: ', details);

            this.orderService.patch({
              order_id: details.id,
              authorization_id: details.purchase_units[0].payments.authorizations[0].id,
              token_record: this.order.id
            }).pipe(takeUntil(this.onDestroy$)).subscribe(
              (data: any) => {
                this.order.status = 1;
                this.router.navigate([`${this.content.org.path}/job-tokens`], {
                  queryParams: {
                    message: 'order_payment_successful'
                  }
                });
              },
              err => {
                this.logger.error('authorize error: ', err);
                this.loadingMessage = '';
                this.toast.error(err.error.error);
                this.loading = false;
              }
            );
          });
        },
        onClientAuthorization: (data) => {
          this.loading = true;
          this.loadingMessage = '';
          this.logger.info('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        },
        onCancel: (data, actions) => {
          this.loading = false;
          this.loadingMessage = '';
          this.logger.info('onCancel: ', data, actions);
        },
        onError: err => {
          this.loading = false;
          this.loadingMessage = '';
          this.logger.error('onError: ', err);
          this.toast.error(err);
        },
        onClick: (data, actions) => {
          this.logger.info('onClick: ', data, actions);
        },
      };
  }

  public displayPrice(price: any) {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: price.currency_code }).format(price.value)
  }

  public deleteOrder(): void {
    if (confirm('Are you sure you want to cancel this order?')) {
      this.loading = true;
      this.orderService.delete(this.order.id)
        .pipe(takeUntil(this.onDestroy$)).subscribe(
        data => {
          this.router.navigate([`${this.content.org.path}/job-tokens`], {
            queryParams: {
              message: 'order_cancelled'
            }
          })
        },
        err => {
          this.loading = false;
          this.toast.error(err.error.error)
        }
      );
    }
  }

  public ngOnDestroy():void {
    this.renderer.removeClass(this.doc.body, 'gray');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
