import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { EducationHubRoutingModule } from './education-hub-routing.module';
import { EducationHubHomeComponent } from './components/education-hub-home/education-hub-home.component';
import { EducationHubPageComponent } from './components/education-hub-page/education-hub-page.component';

@NgModule({
  declarations: [EducationHubHomeComponent, EducationHubPageComponent],
  imports: [
    CommonModule,
    EducationHubRoutingModule,
    SharedModule
  ]
})
export class EducationHubModule { }
