<!-- <ng-container *ngIf="content && content.title === 'Recruiter'">
  <mjs-recruiter></mjs-recruiter>
</ng-container> -->
<ng-container *ngIf="content && content.search_meta && content.title !== 'Recruiter' && (!content.parent || (content.parent && content.parent[0].org_type !== 'partnerships'))">
  <mjs-search></mjs-search>
</ng-container>
<ng-container *ngIf="content && (!content.search_meta || (content.parent && (content.parent[0].org_type === 'partnerships'))) && content.title !== 'Recruiter' ">
  <mjs-page-header *ngIf="!content || !content.job" [content]="content">
  </mjs-page-header>
  <div class="container" *ngIf="!content || !content.job">
    <div class="row">
      <div class="col"
        [ngClass]="[
          content.align_content ? 'text-' + content.align_content : '',
          content.content_width && content.content_width == 'narrow' ? 'col-md-8 offset-md-2' : ''
        ]">
        <div class="content-intro" *ngIf="content.intro" [innerHtml]="content.intro | safe"></div>
        <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
        <div class="wysiwyg-content"
          *ngIf="content.body && !content.read_more && !loading"
          [innerHtml]="content.body | safe">
        </div>

        <div class="survey-container" *ngIf="survey">
          <iframe src="https://docs.google.com/forms/d/1iKGJou7JFf0wLYO31ooVAO_49eYKcTlfCL4AD07pv_w/viewform?embedded=true"></iframe>
        </div>

        <div *ngIf="content.field_covid_19_information">
          <h2>Covid 19 Update</h2>
          <div [innerHtml]="content.field_covid_19_information"></div>
        </div>

        <div *ngIf="content && content.views && content.views.covid_19__rest">
          <p><strong>Select an organisation below to view their Covid 19 statement:</strong></p>
          <div class="select-wrap" style="margin-bottom: 20px;">
            <select (change)="showCovidInfo($event)" class="form-control">
              <option>-- Please select --</option>
              <option *ngFor="let item of content.views.covid_19__rest; index as i;" [value]="i" [innerHtml]="item.name"></option>
            </select>
          </div>

          <div *ngIf="covidInfo" [innerHtml]="covidInfo"></div>
        </div>
      </div>
      <aside class="col sidebar col-sm-12" *ngIf="!content.full_width">
        <mjs-menu
          *ngIf="content && content.menu"
          [menu]="content.menu"
          [class]="'sidebar-menu'">
        </mjs-menu>
        <mjs-featured-jobs-widget *ngIf="displayFeaturedJobs" [categories]="content.sidebar_job_cats || null"></mjs-featured-jobs-widget>
        <mjs-sidebar-links *ngIf="content.sidebar_links" [links]="content.sidebar_links"></mjs-sidebar-links>
        <mjs-advert [type]="'doublempu'"></mjs-advert>
      </aside>
    </div>
  </div>
</ng-container>
<router-outlet></router-outlet>
