import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Content } from '@app/models';

@Component({
  selector: 'mjs-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SitemapComponent implements OnInit {
  
  content: Content;
  sitemap: any;

  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private doc: Document,
    private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.renderer.addClass(this.doc.body, 'gray');
    this.route.data.subscribe(res => {
      this.content = res['content'];
      this.sitemap = res['sitemap'];
    });
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(this.doc.body, 'gray');
  }

}
