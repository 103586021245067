import { Component, Inject, OnInit, Optional, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';

@Component({
  selector: 'mjs-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['../page-header/page-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent implements OnInit {
  
  content = { title: '404 Not Found' }

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,) { }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.response.statusCode = 404;
    }
  }

}
