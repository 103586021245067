import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  
  transform(value: any, args?: any): any {
    if (value.length >= 36) {
      return `${value.substring(0, 36)}...`;
    }

    return value;
  }

}
