import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WebpService } from '@app/services';
import { PageLinks } from '@app/models';

@Component({
  selector: 'mjs-page-grid',
  templateUrl: './page-grid.component.html',
  styleUrls: ['./page-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input('list') list: PageLinks;

  Math: Math = Math;
  Array = Array;
  data: PageLinks;

  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private doc,
    public webp: WebpService) {}

  ngOnInit(): void {
    this.renderer.addClass(this.doc.body, 'full-width');
    this.renderer.addClass(this.doc.body, 'gray');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['list']) {
      this.data = this.list;
    } else {
      this.data = null;
    }
  }

  imageSrcSet(item): string {
    return `${item.header_small} 860w, ${item.header} 1500w`;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.doc.body, 'full-width');
    this.renderer.removeClass(this.doc.body, 'gray');
  }

}
