import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  SimpleChanges,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import {
  Router,
  NavigationStart,
  ActivatedRoute,
  NavigationEnd,
  ParamMap
} from '@angular/router';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { User } from '@app/models';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '@app/services/profile.service';
import { ParseRedirectService } from '@app/services/parse-redirect.service';
import {asyncScheduler} from "@node_modules/rxjs";

@Component({
  selector: 'mjs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ParseRedirectService],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {

  currentAuth: User;

  @Input('scrolled') isScrolled: boolean;
  @Input('mobile') isMobile: boolean;
  @Input() menus: object;

  userMenuActive: boolean;
  menuActive: boolean;
  username: string;
  password: string;
  loading: boolean;
  params: any;
  redirect: string;
  recruiter: boolean;
  isBrowser: boolean;
  currentRoute: any;
  fixedNav: boolean;
  trapFocusTabIndexFixed: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: string,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private toast: ToastrService,
    private oauthService: OAuthService,
    private profileService: ProfileService,
    private parseRedirect: ParseRedirectService,
    private renderer: Renderer2) {
    this.userMenuActive = false;
    this.isBrowser = false;
    this.menuActive = true;
    this.trapFocusTabIndexFixed = false;
  }

  public ngOnInit(): void {
    this.route.queryParamMap
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((queryParams: ParamMap) => {
        let redirect = queryParams.get('redirect');

        if (redirect) {
          this.redirect = decodeURI(redirect);
        }
      });

    this.router.events
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.menuActive = false;
          this.userMenuActive = false;
        }

        if (event instanceof NavigationEnd) {
          let path = event.url.toString().split('#')[0];
          this.currentRoute = `${path}#main-content`;
        }
      });

    if (isPlatformBrowser(this.platform)) {
      this.isBrowser = true;
      this.menuActive = false;

      this.profileService.currentAuth
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(auth => {
          this.currentAuth = auth;

          if (auth && auth.admin_menu) {
            this.recruiter = true;
          }
        });

      fromEvent(window, 'scroll', { passive: false })
        .pipe(takeUntil(this.onDestroy$),)
        .subscribe(() => {
          let scrollTop = this.doc.documentElement.scrollTop || this.doc.body.scrollTop;

          let scrollPoint = 168;

          if (this.doc.getElementById('banner-ad')) {
            const bannerAd = this.renderer.selectRootElement('.banner-ad', true);
            // Check if banner-ad is visible and if so adjust scrollPoint.
            if (!!(bannerAd.offsetWidth || bannerAd.offsetHeight || bannerAd.getClientRects().length)) {
              scrollPoint = scrollPoint + 90;
            }
          }

          this.fixedNav = scrollTop >= scrollPoint;
        });

      this.fixFocusTrap();
    }
  }

  fixFocusTrap() {
    if (!this.trapFocusTabIndexFixed) {
      const elems = this.doc.querySelectorAll('.cdk-focus-trap-anchor');

      if (elems.length > 0) {
        for (let i = 0; i < elems.length; i++) {
          elems[i].setAttribute('tabindex', '-1');

          if (i == elems.length - 1) {
            this.trapFocusTabIndexFixed = true;
          }
        }
      } else {
        asyncScheduler.schedule(() => this.fixFocusTrap(), 500);
      }
    }
  }

  setFocus(id: string) {
    this.renderer.selectRootElement(id, true).focus();
  }

  onClick(elementId: string): void {
    this.viewportScroller.setOffset([100, 0]);
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isMobile']) {
      this.menuActive = !changes['isMobile'].currentValue;
    }
  }

  toggleMainMenu(): void {
    this.userMenuActive = false;
    this.menuActive = !this.menuActive;
  }

  toggleUserMenu(): void {
    this.menuActive = false;
    this.userMenuActive = !this.userMenuActive;

    if (this.userMenuActive) {
      const elems = this.doc.querySelectorAll('.cdk-focus-trap-anchor[tabindex="-1"]');

      if (elems) {
        for (let i = 0; i < elems.length; i++) {
          elems[i].setAttribute('tabindex', '0');
        }
      }
    } else {
      const elems = this.doc.querySelectorAll('.cdk-focus-trap-anchor[tabindex="0"]');

      if (elems) {
        for (let i = 0; i < elems.length; i++) {
          elems[i].setAttribute('tabindex', '-1');
        }
      }
    }
  }

  submit = (params: any): void => {
    let redirect = '';

    if (window.location.pathname.includes('/jobs/')) {
      let parts = window.location.pathname.split('/');
      let last = parts.reverse()[0].split('-');

      if (last.length > 1) {
        if (parseInt(last.reverse()[0], 10) > 0) {
          this.redirect = window.location.pathname;
        }
      }
    }

    this.loading = true;
    this.username = params.username;
    this.password = params.password;

    this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.username, this.password).then(() => {
      this.profileService.set();
      this.userMenuActive = false;
      let { redirect, params } = this.parseRedirect.get(this.redirect, this.oauthService.getIdentityClaims());

      if (Object.keys(params).length > 0) {
        let queries = [];

        for (let key in params) {
          queries.push(`${key}=${params[key]}`);
        }

        redirect += `?${queries.join('&')}`;
      }

      window.location.href = redirect;
    })
    .catch(err => {
      this.userMenuActive = false;
      this.toast.error(err?.error?.message ?? 'Sorry, something went wrong');
      this.loading = false;
    });
  }

  logout(): void {
    this.oauthService.logOut();
    window.location.href = '/user/login';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
