import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'priceDisplay'
})
export class PriceDisplayPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(price: any, args?: any): SafeHtml {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: price.currency_code || 'GBP' }).format(price.value || price);
  }

}