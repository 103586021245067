<mjs-page-header
  [align]="'text-center'"
  [content]="content">
</mjs-page-header>
<div class="container">
  <div class="row">
    <main class="col col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 text-center d-flex flex-column justify-content-center login-main">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <mjs-read-more [data]="content.body" *ngIf="!loading && content.body && content.read_more"></mjs-read-more>
      <div *ngIf="content.body && !content.read_more && !loading" [innerHtml]="content.body | safe"></div>
      <form
        *ngIf="!loading"
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        class="password-reset-form">
        <div class="form-group">
          <label for="email" class="sr-only">Email address</label>
          <input
            placeholder="Email address"
            type="email"
            [formControl]="f['email']"
            id="email"
            [ngClass]="{
              'is-invalid': submitted && f['email'].errors
            }"
            class="form-control"
          >
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email'].errors.required">Email is required</div>
            <div *ngIf="f['email'].errors.email">Email must be a valid email address</div>
          </div>
        </div>
<!--        <div class="form-group">-->
<!--          <re-captcha-->
<!--            [formControl]="f['recaptchaReactive']"-->
<!--            class="form-control recaptcha-field d-flex justify-content-center"-->
<!--            [ngClass]="{-->
<!--              'is-invalid': submitted && f['recaptchaReactive'].errors-->
<!--            }"-->
<!--            required></re-captcha>-->
<!--          <div *ngIf="submitted && f['recaptchaReactive'].errors" class="invalid-feedback">-->
<!--            <div *ngIf="f['recaptchaReactive'].errors.required">Recaptcha is required</div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group my-0">
          <button class="btn btn-primary btn-block">Confirm email address</button>
        </div>
        <div class="mt-3">
          <a routerLink="/user/login">Sign in</a> | <a routerLink="/user/register">Register</a>
        </div>
      </form>
    </main>
</div>
