import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Recruiter, Content } from '@app/models';

@Injectable()
export class RecruiterService {

  constructor(private http: HttpClient,
    @Inject(PLATFORM_ID) private platform: any) {}

  get(alias: string): Observable<Recruiter|Content> {
    if (isPlatformServer(this.platform)) {
      return this.http.get<Content>(`${environment.apiUrl}/api/v1/content?alias=/my-organisations`);
    }

    return this.http.get<Recruiter>(`${environment.apiUrl}/api/v1/recruiter?alias=${alias}`);
  }

}
