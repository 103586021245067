import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, throwError, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ApplicationsService, DocumentService } from '@app/modules/user/services';
import { Auth } from '@app/models';
import { OAuthService } from 'angular-oauth2-oidc';
import { MessagesService } from '@app/services/messages.service';
import { LumesseService } from "@app/services/lumesse.service";
import {Form, FormControl, FormGroup} from "@angular/forms";
declare var require: any;

@Component({
  selector: 'mjs-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  providers: [ApplicationsService, DocumentService],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationsComponent implements OnInit, OnDestroy {

  currentAuth: Auth;
  currentAuthSubscription: Subscription;
  currentMessages: any;
  currentMessagesSubscription: Subscription;

  content: any;
  applications: any;
  loading: boolean;
  incomplete: boolean;
  message: string;
  id: number;
  actions = <any>{};
  profile: any;
  recruiter: boolean;
  lumesseStatus: any;
  statuses: string[];
  filteredApplications: any;
  formGroup: FormGroup;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    @Inject(DOCUMENT) private doc: Document,
    private route: ActivatedRoute,
    private applicationsService: ApplicationsService,
    private toast: ToastrService,
    private docService: DocumentService,
    private oauthService: OAuthService,
    private messagesService: MessagesService,
    private renderer: Renderer2,
    private lumesse: LumesseService) {

    this.formGroup = new FormGroup({
      status: new FormControl<string>('', []),
    })
  }

  ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];

      if (this.content.gray) {
        this.renderer.addClass(this.doc.body, 'gray');
      }
    });

    if (isPlatformBrowser(this.platform)) {
      this.lumesse.currentValue.subscribe(res => this.lumesseStatus = res);
      this.profile = this.oauthService.getIdentityClaims();

      if (this.profile.admin_menu) {
        this.recruiter = true;
      }
    }

    this.currentMessagesSubscription = this.messagesService
      .currentMessages.pipe(takeUntil(this.onDestroy$)).subscribe(list => {
      this.currentMessages= list;
    });

    this.loading = true;

    this.route.url.pipe(takeUntil(this.onDestroy$)).subscribe(
      data => {
        if (data[0].path !== 'applications') {
          this.incomplete = true;
        }
      }
    );

    if (isPlatformBrowser(this.platform)) {
      this.route.queryParamMap
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((paramMap: ParamMap) => {
        const params = paramMap.keys;

        for (let i = 0; i < params.length; i += 1) {
          switch (params[i]) {
            case 'message':
            case 'id':
              this.actions[params[i]] = params;
              break;
          }
        }
      });

      this.getApplications();
    }

    this.formGroup.get('status').valueChanges.subscribe(res => {
      if (res) {
        this.filteredApplications = this.applications.applications.filter(a => a.status === res);
      } else {
        this.filteredApplications = this.applications.applications;
      }
    });
  }

  get lumesseDisabled(): string {
    if (!this.lumesseStatus || this.lumesseStatus.lumesse_disable) {
      return 'Applications are unavailable at the moment due to problems communicating with the lumesse server.';
    }

    return '';
  }

  getApplications() {
    this.applicationsService.get().pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.applyActions();

        if (data.error) {
          this.toast.error(data.error);
        } else {
          this.applications = data;

          this.statuses = [];
          this.filteredApplications = [];
          if (this.applications.applications) {
            this.filteredApplications = this.applications.applications;

            // this.statuses = this.applications.applications.map(a => a.status).filter((value, index, array) => array.indexOf(value) === index);
          }
        }
      },
      error: error => {
        this.loading = false;
        throwError(error)
      }
    });
  }

  applyActions = () => {
    if (this.message && this.id) {
      if (this.message == 'application_already_applied' || this.id) {
        this.toast.info('You already have an application in progress for the position');
      }
    }
  }

  viewDocuments = item => {
    item.viewDocs = !item.viewDocs;
  }

  viewDocument(item) {
    this.loading = true;

    this.docService.get(item.id).pipe(takeUntil(this.onDestroy$)).subscribe({
      next: data => {
        let saveAs = require('file-saver');
        saveAs(data, item.name || item.description);

        this.loading = false;
      },
      error: err => {
        this.loading = false;
        this.toast.error('Sorry, something went wrong. Please refresh the page and try again.')
      }
    });
  }

  displayStatus = application => {
    if (application.status == 'New' || application.status == 'Open') {
      return 'Submitted';
    } else if (application.status == 'Rejected') {
      return 'Unsuccessful';
    } else {
      return application.status;
    }
  }

  getIncompleteActions = (item) => {
    let resume = '';

    switch (item.postingStatus) {
      case 'Unposted':
        resume = '<button (click)="unpostedAlert()">Information</button>';
        break;
      case 'Available':
        resume = `<a href="/resume/${item.id}" target="_self" class="btn btn-sm btn-primary">Continue</a>`;
        resume = '<button (click)="unpostedAlert()">Information</button>';
        break;
      default:
        resume = '<span class="mjs-application-btn disabled">Unavailable</span>';
    }

    return resume;
  }

  unpostedAlert() {
    this.toast.info('This posting may have been withdrawn for updates. Please try searching for it, and applying for it again.');
  }

  resumePath(item) {
    if (item.source == 'rac') {
      return `/rac?jobId=${item.id}&resumeToken=${item.resume}`;
    }

    return `/resume/${item.id}?token=${this.oauthService.getAccessToken()}`;
  }

  deleteApplication(item) {
    if (confirm('Are you sure you want to delete this application')) {
      this.loading = true;
      this.applicationsService
        .delete(item.id, this.incomplete ? 'saved_applications' : 'applications', item.delete)
        .pipe(takeUntil(this.onDestroy$)).subscribe({
          next: _ => {
            this.toast.success('Your application has been deleted');
            this.getApplications();
          },
          error: error => {
            this.loading = false;
            this.toast.error(error)
          }
        });
    }
  }

  showResume(job: any) {
    const today = new Date();
    const closing = new Date(job.closing_date);

    closing.setHours(23);
    closing.setMinutes(59);
    closing.setSeconds(59);

    if (today.getTime() > closing.getTime()) {
      return false;
    }

    if (!job.allow_applications) {
      return false;
    }

    return true;
  }

  ngOnDestroy(): void {
    if (this.content.gray) {
      this.renderer.removeClass(this.doc.body, 'gray');
    }

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
