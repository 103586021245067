import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class FileService {

  constructor(private http: HttpClient) {}

  post(
    filename: string,
    mime: string,
    data: any,
    directory: string,
    entityType: string,
    entityBundle: string,
    fieldName: string
  ) {
    const payload = {
      'uri': `public://${directory}`,
      'filename': filename,
      'filemime': mime,
      'data': data,
      'entity_type_id': entityType,
      'entity_bundle': entityBundle,
      'field': fieldName
    };

    return this.http.post(`${environment.web1}/api/v1/recruiter/file`, payload);
  }

}
