import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaseStudiesComponent } from './case-studies.component';

const ROUTES: Routes = [{
  path: '',
  component: CaseStudiesComponent
}];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class CaseStudiesRoutingModule { }
