import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { FacetService } from '../../services/facet.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MjsSearchFilter, MjsSearchParams } from '../../models';

@Component({
  selector: 'mjs-checkboxes',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxesComponent implements OnInit, OnDestroy {

  @Input() data: MjsSearchFilter;
  @Input() params: MjsSearchParams;
  @Input() defaultParams: any;
  @Input() static: boolean;
  @Input() org: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() unlockFacets: EventEmitter<any> = new EventEmitter();

  hideParent: boolean;
  hideChild: boolean;
  subscription: Subscription;
  form: FormGroup;
  facets: any;
  changing: string = '';
  showReset: boolean = false;
  initialRender: boolean;
  sectorValues: any;
  typeValues: any;

  filterType: 'numberArray' | 'stringArray';

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private facetService: FacetService,
    private route: ActivatedRoute,
    private router: Router) {
    this.form = new FormGroup({ });

    if (!this.static) {
      this.subscription = this.facetService.getFacets()
        .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.facets = res.facets;
        this.changing = res.changing;
        this.applyFacets();
      });
    }
  }

  public ngOnInit(): void {
    this.initialRender = true;
    const { key } = this.data;

    if (key == 'sector') {
      this.filterType = this.params.employee_sector.type;
    } else if (this.params.hasOwnProperty(key)) {
      this.filterType = this.params[key].type;
    }

    if (this.defaultParams.hasOwnProperty('default_cat') && key === 'cat') {
      this.hideChild = true;
    }
    else if (this.defaultParams.hasOwnProperty(key)) {
      this.hideChild = true;
    }
    else if (this.data.has_children && this.defaultParams.hasOwnProperty(`parent_${key}`)) {
      this.hideParent = true;
    }
    else if (this.data.key === 'org_type' || this.data.key === 'employee_sector') {
      if (this.defaultParams.hasOwnProperty('parent_org') || this.defaultParams.hasOwnProperty('org')) {
        if (this.org && this.org.org_type !== 'partnerships') {
          this.hideChild = true;
        }
      }
    }

    if (!this.hideChild) {
      this.init();
    }
  }

  private init(): void {
    const { options, key } = this.data;
    const { snapshot } = this.route;
    let { queryParams } = snapshot;

    if (this.static) {
      queryParams = {};

      for (let key in this.params) {
        switch (this.params[key].type) {
          case 'stringArray':
          case 'numberArray':
            queryParams[key] = this.params[key].value.join(',');
            break;

          default:
            queryParams[key] = this.params[key].value
        }
      }
    }

    Object.values(options).map(item => {
      if (item.children) {
        let parentVal = queryParams.hasOwnProperty(`parent_${key}`) ? queryParams[`parent_${key}`].indexOf(item.id) > -1 : false;
        let childrenChecked = false;

        Object.values(item.children).map(child => {
          let childChecked = queryParams.hasOwnProperty(key) ? queryParams[key].indexOf(child.id) > -1 : false;

          this.form.addControl(`${child.id}`, new FormControl(childChecked));

          if (childChecked) childrenChecked = true;
        });

        if (childrenChecked) parentVal = true;

        if (!this.hideParent) {
          this.form.addControl(`parent_${item.id}`, new FormControl(parentVal));
        }
      } else {
        let selector = key;

        if (key == 'sector') {
          selector = 'employee_sector';
        }

        this.form.addControl(`${item.id}`, new FormControl(queryParams[selector] && queryParams[selector].indexOf(item.id) > -1));
      }
    });

    if (this.data.has_children && !this.static) {
      this.showHideSubOptions(
        this.params[key].value,
        this.params[`parent_${key}`].value
      );
    }

    if (this.data.has_children && this.static) {
      this.showHideStaticSubOptions(
        this.params[key].value,
        this.params[`parent_${key}`].value
      );
    }

    this.valuesChanged();
  }

  toggleOpenState(): void {
    this.data.open = !this.data.open;
    this.data.expanded = false;
  }

  toggleExpandedState(): void {
    this.data.expanded = !this.data.expanded;
  }

  resetAll(): void {
    this.form.reset();
    this.unlockFacets.emit();
  }

  resetChildren(): void {
    const keys: any = Object.keys(this.data.options);
    const values: any = Object.values(this.data.options);

    for (let i = 0; i < keys.length; i += 1) {
      if (values[i].children) {
        for (let subKey in values[i].children) {
          if (this.form.value[subKey]){
            this.form.controls[subKey].setValue(false);
          }
        }
      }
    }
  }

  private selectAll(): void {
    for (let key in this.data.options) {
      if(this.data.options[key].checked == false){
        this.form.controls[key].setValue(true);
      }
    }
  }

  parentTermCount(count: number, items: any, skip: any): number {
    for (let key in items) {
      if (skip.toString() !== key) {
        count = count - items[key].count;
      }
    }

    return count < 0 ? 0 : count;
  }

  private valuesChanged(): any {
    this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(500)
      )
      .subscribe(values => {
        const { options } = this.data;
        let parents = [];
        let selected = [];

        for (let key in values) {
          if (key.substring(0, 7) != 'parent_') {
            if (values[key]) {
              selected.push(this.filterType == 'stringArray' ? key.trim() : parseInt(key, 10));
            }
          } else {
            if (values[key]) {
              parents.push(parseInt(key.replace('parent_', ''), 10));
            }
          }
        }

        if (parents.length > 0) {
          parents = parents.filter(item => {
            for (let child in options[item].children) {
              if (this.form.get(child).value) {
                return false;
              }
            }
            return true;
          });
        }

        if (this.static) {
          this.showHideStaticSubOptions(selected, parents);
        }

        return this.valueChange.emit({
          selected,
          parents,
          key: this.data.key == 'sector' ? 'employee_sector' : this.data.key
        });
      });
  }

  private showHideSubOptions(selected: any[], parents: any[]): void {
    const { options } = this.data;

    // Hide sub options if no values selected.
    this.data.child_visible = false;
    let childrenSelected = [];

    if (selected) {
      for (let i = 0; i < selected.length; i += 1) {
        if (options[selected[i]]) {
          // this.data.child_visible = true;
        } else {
          for (let key in options) {
            if (!options[key].children) {
              continue;
            }

            if (Object.values(options[key].children).length > 1 && options[key].children[selected[i]]) {
              this.data.child_visible = true;
            }
          }
        }
      }
    }

    if (parents) {
      for (let i = 0; i < parents.length; i += 1) {
        if (options[parents[i]]) {
          if ([...Object.keys(options[parents[i]].children)].length > 1) {
            for (let key in options[parents[i]].children) {
              if (options[parents[i]].children[key].count && options[parents[i]].children[key].count > 1) {
                this.data.child_visible = true;
              }
            }
          }
        }
      }
    }
  }

  private showHideStaticSubOptions(selected, parents): void {
    const { options } = this.data;
    this.data.child_visible = false;

    for (let key in options) {
      if (options[key].children) {
        let hidden = true;

        if (selected.indexOf(parseInt(key, 10)) > -1 || parents.indexOf(parseInt(key, 10)) > -1) {
          hidden = false;
          this.data.child_visible = true;
        }

        for (let subKey in options[key].children) {
          if (selected.indexOf(parseInt(subKey, 10)) > -1) {
            this.data.child_visible = true;
            hidden = false;
          }

          this.data.options[key].children[subKey].hidden = hidden;
        }

        if (!hidden) {
          for (let subKey in options[key].children) {
            this.data.options[key].children[subKey].hidden = false;
          }
        }
      }
    }
  }

  public showChildren(item): boolean {
    for (let key in item.children) {
      if (this.params[this.data.key].value.includes(parseInt(key, 10))) {
        return true;
      }

      if (this.params[`parent_${this.data.key}`].value.includes(parseInt(key, 10))) {
        return true;
      }
    }

    return false;
  }

  private applyFacets(): void {
    const { key } = this.data;
    const { snapshot } = this.route;
    const { queryParams } = snapshot;

    let skipParents = false;
    let skipChildren = false;

    let selector = this.data.key

    if (selector == 'sector') {
      selector = 'employee_sector';
    }

    let values = queryParams[selector]
      ? queryParams[selector].split(',').map(item => {
        if (this.filterType == 'stringArray') {
          return item.trim();
        } else {
          return parseInt(item, 10);
        }
      }) : [];

    let parentValues = queryParams[`parent_${selector}`]
      ? queryParams[`parent_${selector}`].split(',').map(item => {
        if (this.filterType == 'stringArray') {
          return item.trim();
        } else {
          return parseInt(item, 10);
        }
      }) : [];

    // Show / hide the reset button.
    this.showReset = false;
    if (values.length > 0 || parentValues.length > 0) {
      this.showReset = true;
    }

    if ((this.changing == 'employee_sector' && this.data.key == 'sector' || this.changing === this.data.key || this.changing === `parent_${this.data.key}`)
        && (values.length > 0 || parentValues.length > 0)) {

      if (this.data.has_children) {
        this.showHideSubOptions(values, parentValues);
      }

      skipParents = true;

      if (this.changing == 'employee_sector' && this.data.key == 'sector' || this.changing === this.data.key) {
        skipChildren = true;
      }
    }

    for (let key in this.data.options) {
      if (!skipParents) {
        this.data.options[key].count = this.facets[this.data.key] && this.facets[this.data.key][key]
          ? this.facets[this.data.key][key]
          : 0;
      }

      // Only apply child facets if parent is checked or any children are checked.
      if (this.data.options[key].children && !skipChildren) {
        let otherCount = this.data.options[key].count || 0;
        let showChildren = false;

        // Show children if parent or taxonomy value includes key.
        if (parentValues.indexOf(key) > -1 || values.indexOf(key) > -1) {
          showChildren = true;
        }

        // Show children if any child is selected.
        for (let subKey in this.data.options[key].children) {
          let parent = this.data.options[key].children[subKey].parent;

          if (parentValues.indexOf(key) > -1 || values.indexOf(key) > -1) {
            continue;
          }

          if (values.indexOf(subKey)) {
            showChildren = true;
          }
        }

        if (!skipParents) {
          for (let subKey in this.data.options[key].children) {
            if (this.facets[this.data.key] && this.facets[this.data.key][subKey]) {
              this.data.options[key].children[subKey].count = this.facets[this.data.key][subKey];
            } else {
              this.data.options[key].children[subKey].count = 0;
            }

            if (subKey !== key) {
              otherCount -= this.data.options[key].children[subKey].count;
            }

            if (showChildren) {
              this.data.options[key].children[subKey].count = this.facets[this.data.key] && this.facets[this.data.key][subKey]
                ? this.facets[this.data.key][subKey]
                : 0;
            } else {
              this.data.options[key].children[subKey].count = 0;
            }
          }
        }

        if (this.data.options[key].children[key]) {
          this.data.options[key].children[key].count = otherCount;
        }
      }
    }

    if (this.data.has_children) {
      this.showHideSubOptions(values, parentValues);
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
