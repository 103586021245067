<ul
  *ngIf="breadcrumbs"
  class="breadcrumb bg-transparent m-0 p-0 pt-1">
  <li
    *ngFor="let item of breadcrumbs"
    class="breadcrumb-item">
    <a
      class="text-white"
      [routerLink]="item.url"
      [innerHtml]="item.title"></a>
  </li>
</ul>
