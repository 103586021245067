import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  Router,
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobSearchService } from '@modules/search/services';
import { paramsToHttpParams } from '@modules/search/helpers/params-to-http-params';
import { MjsSearchParams } from '@modules/search/models/mjs-search-params.model';
import { searchParams } from '@modules/search/search-params';
import { searchParamsToParams } from '@modules/search/helpers/search-params-to-params';

@Injectable()
export class SearchResolver implements Resolve<any> {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
    private route: ActivatedRoute,
    private transferState: TransferState,
    private jobSearchService: JobSearchService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    const url = this.getFullUrl(route);
    const SEARCH_RESULTS_KEY = makeStateKey<any>(`search_results__${url}`);

    if (this.transferState.hasKey(SEARCH_RESULTS_KEY)) {
      const searchResults = this.transferState.get<any>(SEARCH_RESULTS_KEY, null);
      this.transferState.remove(SEARCH_RESULTS_KEY);
      return of(searchResults);
    }

    let params = JSON.parse(JSON.stringify(searchParams));

    // Convert the raw URL search params to form ready values.
    params = searchParamsToParams(params, route.queryParamMap);
    
    // Default params.
    let defaultParams = {};
    if (route.data?.['content']?.['org']?.['search_meta']?.['query']) {
      defaultParams = route.data['content']['org']['search_meta']['query'];
    } else if (route.data?.['content']?.['search_meta']?.['query']) {
      defaultParams = route.data['content']['search_meta']['query'];
    }

    const httpParams = paramsToHttpParams(params, defaultParams);

    return this.jobSearchService.get(httpParams.httpParams)
      .pipe(tap(searchResults => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(SEARCH_RESULTS_KEY, {
            results: searchResults,
            params: params,
            defaultParams: { }
          });
        }

        return of(searchResults);
      }));
  }

  /**
   * Create safe string for path and query parameters
   *
   * @param route ActivatedRouteSnapshop
   *
   * @returns string
   */
  private getFullUrl(route: ActivatedRouteSnapshot): string {
    let url = this.route.snapshot.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    const queryParam = this.route.snapshot.queryParamMap;

    if (queryParam.keys.length > 0) {
      url += '?' + queryParam.keys.map(key => queryParam.getAll(key).map(value => key + '=' + value).join('&')).join('&');
    }

    return url.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }
}
