<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="content && content.org">
          <li>
            <a
              [routerLink]="content.org.path + '/users'"
              routerLinkActive="active">
              Users
            </a>
          </li>
          <li>
            <a
              [routerLink]="content.org.path + '/users/create'"
              routerLinkActive="active">
              Create User
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <div [innerHtml]="content.body" *ngIf="content && content.body && !loading"></div>

      <div class="sticky-table-container" *ngIf="data && data.list.length > 0">
        <div class="table-container">
          <table mat-table [dataSource]="data.list">

            <ng-container matColumnDef="surname">
              <th mat-header-cell *matHeaderCellDef> Surname </th>
              <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
            </ng-container>

            <ng-container matColumnDef="forename">
              <th mat-header-cell *matHeaderCellDef> Forename </th>
              <td mat-cell *matCellDef="let element"> {{element.forename}} </td>
            </ng-container>

            <ng-container matColumnDef="mail">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.mail}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <ng-container matColumnDef="roles">
              <th mat-header-cell *matHeaderCellDef> Roles </th>
              <td mat-cell *matCellDef="let element" class="mat-column-categories" [innerHtml]="'<span>' + element.roles.split(',').join('</span><span>') + '</span>'"></td>
            </ng-container>

            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef> Created </th>
              <td mat-cell *matCellDef="let element"> {{element.created}} </td>
            </ng-container>

            <ng-container matColumnDef="access">
              <th mat-header-cell *matHeaderCellDef> Last Access </th>
              <td mat-cell *matCellDef="let element"> {{element.access}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>

          </table>
        </div>

        <div class="sticky-actions">
          <div class="btn-group" *ngFor="let element of data.list">
            <button
              [matMenuTriggerFor]="menu"
              class="btn btn-outline-primary btn-sm dropdown-toggle"
              type="button"
              aria-haspopup="true"
              [attr.aria-expanded]="element.active || 'false'">
              Actions
            </button>
            <mat-menu yPosition="above" #menu="matMenu">
              <a mat-menu-item [routerLink]="content.org.path + '/users/' + element.actions">Edit</a>
            </mat-menu>
          </div>
        </div>
      </div>
      <mjs-pager
        *ngIf="data && data.pages > 1"
        [page]="data.page"
        [pages]="data.pages"
        [total]="data.total">
      </mjs-pager>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
