import { Inject, Component, OnInit, OnDestroy, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../services';

@Component({
  selector: 'mjs-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UserService],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, OnDestroy {
  params: any;
  content: any;
  profile: any;
  entity_id: number;
  loading: boolean;
  data: any;
  page = 1;
  columns = ['surname', 'forename', 'mail', 'status', 'roles', 'created', 'access'];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private userService: UserService) {
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });
    
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe(queryParams => {
      this.page = parseInt(queryParams.get('page'), 10) || 1;

      if (isPlatformBrowser(this.platform)) {
        this.search();
      }
    });
  }

  search() {
    this.loading = true;

    this.userService.list(this.content.org.id, this.page)
      .pipe(takeUntil(this.onDestroy$)).subscribe(
      (data: any) => {
        this.data = data;
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  toggleElementActions(element) {
    for (let i = 0; i < this.data.list.length; i += 1) {
      if (element.actions === this.data.list[i].actions) {
        this.data.list[i].active = !this.data.list[i].active;
      }
      else {
        this.data.list[i].active = false;
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
