import {
  Component,
  OnDestroy,
  AfterViewInit,
  Input,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AppleTokenService } from '@app/shared/services/apple-token.service';
import loadjs from 'loadjs';
import { JobPreview } from '@app/models';
import { MjsJob } from '@app/models/mjs-job.model';

declare var mapkit: any;

@Component({
  selector: 'mjs-apple-map',
  templateUrl: './apple-map.component.html',
  styleUrls: ['./apple-map.component.scss'],
  providers: [AppleTokenService],
  encapsulation: ViewEncapsulation.None
})
export class AppleMapComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() location: string;
  @Input() list: MjsJob[];

  locations = [];
  map: any;
  markerList: any;
  currentMarker: any;
  annotations = [];
  points = [];
  current: any;
  token: string;
  isBrowser: boolean;
  markerClickTimeout: any;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: string,
    private tokenService: AppleTokenService,
    private logger: NGXLogger) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map && changes['list']) {
      if (JSON.stringify(changes['list'].currentValue) !== JSON.stringify(changes['list'].previousValue)) {
        this.locations = changes['list'].currentValue;
        this.populateMap();
      }
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {

      if (window.hasOwnProperty('mapkit')) {
        return this.setUp();
      }

      loadjs([`https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js`], {
        before: (path, el) => {},
        success: () => {
          const tokenService = this.tokenService;
          const setUp = this.setUp;

          mapkit.init({
            authorizationCallback: done => {
              tokenService.getToken().pipe(takeUntil(this.onDestroy$)).subscribe(
                data => {
                  done(data.toString());
                  setUp();
                },
                err => {
                  this.logger.debug(`Failed to get mapkt token from MJS makit token service.`);
                }
              );
            }
          })
        },
        error: (err: any) => {
          this.logger.debug(`Apple mapkit.js failed to load.`);
        }
      });
    }
  }

  setUp = (): void => {
    this.map = new mapkit.Map('map', {
      center: new mapkit.Coordinate(56.606641, -4.017971),
      tintColor: '#338395',
      showsCompass: false
    });

    this.map.addEventListener('select', this.markerClick);

    if (this.list) {
      this.locations = this.list;
    }

    this.populateMap();
  }

  markerClick = (event: any): void => {
    const data = event.annotation.memberAnnotations || event.annotation.data;

    /**
     * Could be single annotation or annotation group.
     */
    if (event.annotation.memberAnnotations) {
      this.markerClickTimeout = setTimeout(() => {
        this.markerList = data;
        event.annotation.selected = false;
      });
    } else {
      this.markerClickTimeout = setTimeout(() => {
        this.currentMarker = data;
        event.annotation.selected = false;
      });
    }
  }

  selectMarker(marker: any): void {
    this.markerList = null;
    this.currentMarker = marker;
  }

  populateMap(): void {
    this.map.removeAnnotations(this.annotations);
    this.annotations = [];

    // Old locations handling.
    if (this.locations && this.locations.length > 0) {
      for (let i = 0; i < this.locations.length; i += 1) {
        if (!this.locations[i].coordinates) {
          continue;

        }

        let loc = this.locations[i].coordinates.split(',');

        if (loc.length == 2) {
          let coords = new mapkit.Coordinate(parseFloat(loc[0]), parseFloat(loc[1]));
          let marker = new mapkit.MarkerAnnotation(coords, {
            data: this.locations[i],
            color: '#338395',
            clusteringIdentifier: this.locations[i].id ? this.locations[i].id.toString() : this.locations[i].job_id.toString()
          });

          // Clustering setup.
          marker.clusteringIdentifier = "DetailedCollectionCluster";
          marker.collisionMode = mapkit.Annotation.CollisionMode.Circle;
          marker.displayPriority = mapkit.Annotation.DisplayPriority.High;

          this.annotations.push(marker);
          this.map.addAnnotation(marker);
        }
      }

      this.map.showItems(this.annotations);
    }

    // New locations handling.
    // if (this.list && this.list.length > 0) {
    //   for (let i = 0; i < this.list.length; i += 1) {
    //     if (this.list[i].hasOwnProperty('locations')) {
    //       for (let e = 0; e < this.list[i].locations.length; e += 1) {
    //         let loc = this.list[i].locations[e].coordinates.split(',');
    //
    //         if (loc.length == 2) {
    //           let co = new mapkit.Coordinate(parseFloat(loc[0]), parseFloat(loc[1]));
    //           let marker = new mapkit.MarkerAnnotation(co, {
    //             data: this.list[i],
    //             color: '#338395',
    //             clusteringIdentifier: this.list[i].id ? this.list[i].id.toString() : this.list[i].talentlink_job_id.toString()
    //           });
    //
    //           // Clustering setup.
    //           marker.clusteringIdentifier = "DetailedCollectionCluster";
    //           marker.collisionMode = mapkit.Annotation.CollisionMode.Circle;
    //           marker.displayPriority = mapkit.Annotation.DisplayPriority.High;
    //
    //           this.annotations.push(marker);
    //           this.map.addAnnotation(marker);
    //         }
    //       }
    //     } else if (this.list[i].hasOwnProperty('locations_coords')) {
    //       const coords = this.list[i].locations_coords.split('---');
    //
    //       if (coords) {
    //         for (let e = 0; e < coords.length; e += 1) {
    //           let loc = coords[e].split(',');
    //
    //           if (loc.length == 2) {
    //             let co = new mapkit.Coordinate(parseFloat(loc[0]), parseFloat(loc[1]));
    //             let marker = new mapkit.MarkerAnnotation(co, {
    //               data: this.list[i],
    //               color: '#338395',
    //               clusteringIdentifier: this.list[i].id ? this.list[i].id.toString() : this.list[i].talentlink_job_id.toString()
    //             });
    //
    //             // Clustering setup.
    //             marker.clusteringIdentifier = "DetailedCollectionCluster";
    //             marker.collisionMode = mapkit.Annotation.CollisionMode.Circle;
    //             marker.displayPriority = mapkit.Annotation.DisplayPriority.High;
    //
    //             this.annotations.push(marker);
    //             this.map.addAnnotation(marker);
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  closeMarker(): void {
    this.markerList = null;
    this.currentMarker = null;
  }

  ngOnDestroy(): void {
    if (this.markerClickTimeout) {
      clearTimeout(this.markerClickTimeout);
    }

    this.map.removeEventListener('select', this.markerClick);
    this.map.destroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
