import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class EntityService {

  constructor(private http: HttpClient) {}

  create(
    entity_type: string,
    entity_id: number,
    data: any
  ) {
    return this.http.post(`${environment.apiUrl}/api/v1/recruiter/entity/${entity_type}/${entity_id}`, data);
  }

  patch(entity_type: string, entity_id: number, data: any) {
    return this.http.patch(`${environment.apiUrl}/api/v1/recruiter/entity/${entity_type}/${entity_id}`, {
      data
    });
  }

  get(org: number, id: number) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/job?org=${org}&id=${id}`);
  }

  delete(org, id) {
    return this.http.delete(`${environment.apiUrl}/api/v1/recruiter/job?org=${org}&id=${id}`);
  }

}
