<div class="form-group"
  [formGroup]="parentForm"
  [ngClass]="{
    required: element.required
  }">

  <label [for]="element.field_name">
    {{ element.label }}
    <span *ngIf="element.required" class="required-flag">[Required]</span>
  </label>

  <mat-form-field class="w-100" [ngClass]="{
    'is-invalid': submitted && errors,
    'form-control': submitted && errors,
  }">

    <ng-container *ngIf="element.cardinality !== 1" >
      <mat-select [formControlName]="element.field_name" #singleSelect multiple>
        <ng-container *ngIf="element.settings.allowed_values.length > 20">
          <mat-option>
            <ngx-mat-select-search
              [clearSearchInput]="clearSearchInput"
              placeholderLabel="Search"
              noEntriesFoundLabel="No options found"
              [formControl]="filterValuesCtrl">
              <span ngxMatSelectSearchClear></span>
            </ngx-mat-select-search>
          </mat-option>
        </ng-container>
        <ng-container *ngFor="let option of filteredValues | async">
          <ng-container *ngIf="!option.filteredChildren">
            <mat-option [value]="option.id">
              {{option.label}}
            </mat-option>
          </ng-container>
          <ng-container *ngIf="option.filteredChildren">
            <mat-optgroup [label]="option.label" class="sticky">
              <span>{{ option.filteredChildren.length }}</span>
              <mat-option [value]="child.id" *ngFor="let child of option.filteredChildren">
                {{child.label}}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </ng-container>
      </mat-select>
    </ng-container>

    <ng-container *ngIf="element.cardinality === 1">
      <mat-select [formControlName]="element.field_name" #singleSelect>
        <mat-option *ngIf="element.settings.allowed_values.length > 20">
          <ngx-mat-select-search
            [clearSearchInput]="clearSearchInput"
            placeholderLabel="Search"
            noEntriesFoundLabel="No options found"
            [formControl]="filterValuesCtrl">
            <span ngxMatSelectSearchClear></span>
          </ngx-mat-select-search>
        </mat-option>
        <ng-container *ngFor="let option of filteredValues | async">
          <ng-container *ngIf="!option.filteredChildren">
            <mat-option [value]="option.id">
              {{option.label}}
            </mat-option>
          </ng-container>
          <ng-container *ngIf="option.filteredChildren">
            <mat-optgroup [label]="option.label" class="sticky">
              <!-- <span>{{ option.filteredChildren.length }}</span> -->
              <mat-option [value]="child.id" *ngFor="let child of option.filteredChildren">
                {{child.label}}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </ng-container>
      </mat-select>
    </ng-container>

  </mat-form-field>

  <div *ngIf="submitted && errors" class="invalid-feedback">
    <p *ngIf="errors.required">{{ element.label }} is required</p>
  </div>

  <div class="description" *ngIf="element.description"><p>{{ element.description }}</p></div>

</div>
