import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class ChangeService {

  constructor(private http: HttpClient) {}

  patch(id: number,
    timestamp: number,
    token: string,
    password: string,
    recaptcha: string
  ) {
    return this.http.patch(`${environment.apiUrl}/api/v1/user/reset?_format=json&recaptcha=${recaptcha}`, {
      id, timestamp, token, password
    });
  }

}
