import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject,
  Renderer2
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { throwError, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormService } from './services/form.service';

@Component({
  selector: 'mjs-drupal-entity',
  templateUrl: './drupal-entity.component.html',
  styleUrls: ['./drupal-entity.component.scss'],
  providers: [FormService],
  encapsulation: ViewEncapsulation.None
})
export class DrupalEntityComponent implements OnInit, OnDestroy {

  @Input('entity') entity: string;
  @Input('bundle') bundle: string;
  @Input('mode') mode: string;
  @Input('entity_id') entity_id: number;
  @Input('params') params: any;
  @Input('submit') submit: any;
  @Input('recaptcha') recaptcha: boolean;
  @Input('formData') formData: any;
  @Input() duplicatingJob: boolean;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();
  @Output() submitForm: EventEmitter<any> = new EventEmitter();

  form: FormGroup = new FormGroup({ });
  data: any;
  submitted: boolean;
  browser: boolean;
  loading: boolean;
  renderArrays: any[] = [];
  submitLabel?: string;

  appNumber: string = 'number';

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private formService: FormService,
    @Inject(PLATFORM_ID) private platform: string,
    private renderer: Renderer2) {
    this.browser = false;

    if (isPlatformBrowser(this.platform)) {
      this.browser = true;
    }
  }

  ngOnInit(): void {
    if (!this.formData) {
      this.getForm();
    } else {
      this.data = {fields: this.formData};
      this.init();
    }
  }

  private getForm(): void {
    this.loading = true;

    this.formService.get(
      this.entity,
      this.bundle,
      this.entity_id,
      this.entity == 'entities' ? 'job_advert' : ''
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: data => {
          this.data = data;
          this.init();
          this.loading = false;
        },
        error: error => {
          this.loading = false;
        }
      });
  }

  init = (): void => {
    for (let i = 0; i < this.data.length; i += 1) {
      if (this.duplicatingJob && this.data[i].field_name === 'end_date') {
        if (this.data[i].disabled) {
          this.data[i].disabled = false;
        }
      }

      let validators = [];
      let value: any = '';

      if (this.data[i].type == 'number') {
        this.data[i].element = 'number';

        if (!this.data[i].value) {
          this.data[i].value = 0;
        }
      }

      if (this.data[i].required) {
        validators.push(Validators.required);
      }

      if (this.data[i].default_value) {
        value = this.data[i].default_value;
      }

      if (this.data[i].hasOwnProperty('value')) {
        if (this.data[i].type && this.data[i].type === 'boolean') {
          value = this.data[i].value ? 'checked' : '';
        }
        else {
          value = this.data[i].value;
        }

        if (this.data[i].type == 'list_integer') {
          value = parseInt(this.data[i].value, 10);
        }
      }

      if (this.params && this.params[this.data[i].field_name]) {
        value = this.params[this.data[i].field_name];
      }

      if (value && value.hasOwnProperty('fid')) {
        this.form.addControl(this.data[i].field_name, new FormControl(value.fid, validators));
      } else {
        this.form.addControl(this.data[i].field_name, new FormControl(value, validators));
      }

      if (this.data[i].type === 'pass') {
        this.form.addControl(`confirm__${this.data[i].field_name}`, new FormControl(value, validators));
      }

      if (this.data[i].type === 'options_select' && this.data[i].settings.child_values) {
        this.form.addControl(this.data[i].field_name + '__child', new FormControl(this.data[i].child_default_value || '', validators));
      }
    }

    if (this.recaptcha) {
      this.form.addControl('recaptcha', new FormControl(null, Validators.required));
    }

    // this.form.valueChanges.subscribe(val => {
    //   this.valueChanges.emit(val);
    // });

    let currentArray = [];

    for (let i = 0; i < this.data.length; i += 1) {
      currentArray.push(this.data[i]);

      if (this.data[i].suffix && this.data[i].suffix === 'row-end') {
        this.renderArrays.push(currentArray);
        currentArray = [];
      }
    }

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        for (let i = 0; i < this.data.length; i += 1) {
          if (this.data[i].hasOwnProperty('conditions')) {
            if (this.data[i].conditions.hasOwnProperty('required')) {
              let control = this.form.get(this.data[i].field_name);

              if (val[this.data[i].conditions.required.field] === this.data[i].conditions.required.value) {
                if (!this.data[i].required) {
                  this.data[i].required = true;
                  control.setValidators([Validators.required]);
                  control.updateValueAndValidity();
                }
              } else {
                if (this.data[i].required) {
                  this.data[i].required = false;
                  control.clearValidators();
                  control.updateValueAndValidity();
                }
              }
            }
          }
        }
      });
  }

  get f(): any { return this.form.controls; }

  onSubmit(): any {
    this.submitted = true;

    if (this.form.invalid) {
      for (let key in this.form.controls) {
        if (!this.form.controls[key].valid) {
          return this.renderer.selectRootElement(`#field-${key}`, true).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
      }

      return;
    }

    this.renderer.selectRootElement('#main-content', true).scrollIntoView();

    this.submitForm.emit({
      params: this.form.value,
      structure: this.data
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
