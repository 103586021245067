import { Component, Input, ViewEncapsulation, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

interface PageLink {
  page: number;
  link: boolean;
}

@Component({
  selector: 'mjs-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagerComponent implements OnChanges {
  
  @Input() page: number;
  @Input() pages: number;
  @Input() total: number;

  currentPath = this.router.url.split('?')[0];

  values: {
    start: {
      first: number;
      back: number;
    },
    middle: PageLink[],
    end: {
      next: number;
      last: number;
    }
  };

  constructor(private router: Router) {}

  public ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  private init() {
    this.values = {
      start: {
        first: 0,
        back: 0
      },
      middle: [],
      end: {
        next: 0,
        last: 0
      }
    };

    this.values.start.first = this.page > 1 ? 1 : 0;
    this.values.start.back = this.page > 1 ? this.page - 1 : 0;

    let temp = {
      first: Math.max(1, (this.page - 2)),
      last: Math.min((this.page + 2 ), this.pages),
      current: 0
    };

    if (this.page < 3) {
      temp.last = Math.min(this.pages, 5);
    } else if(this.page > (this.pages - 3)) {
      temp.first = Math.max(1, (this.pages - 4));
    }

    for (temp.current = temp.first; temp.current <= temp.last; temp.current += 1) {
      this.values.middle.push({
        page: temp.current,
        link: temp.current == this.page ? false : true
      });
    }

    this.values.end.next = this.page < this.pages ? this.page + 1 : 0;
    this.values.end.last = this.page < this.pages ? this.pages : 0;
  }
  currentParams() {

  }
}
