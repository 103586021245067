import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '@env/environment';
import {map} from "rxjs/operators";

@Injectable()
export class MjsPositionsService {

  constructor(private http: HttpClient) {}

  list(org: number, page: number, keywords?: string) {
    return this.http.get(`${environment.apiUrl}/api/v2/recruiter-positions?_format=json&org=${org}&page=${page}${keywords ? '&keywords=' + keywords : ''}`);
  }

  post(data: any) {
    return this.http.post(`${environment.apiUrl}/api/v2/mjs-positions?_format=json`, data);
  }

  patch(data: any, id: number) {
    return this.http.patch(`${environment.apiUrl}/api/v2/mjs-positions/${id}?_format=json`, data);
  }

  get(org: number, id: number, prefix_fields?: boolean) {
    let url = `${environment.apiUrl}/api/v2/recruiter/positions?_format=json&org=${org}&id=${id}`;

    if (prefix_fields) {
      url = `${url}&prefix_fields=true`;
    }

    return this.http.get(url);
  }

  csv(org_id) {
    return this.http.get(`${environment.apiUrl}/api/v2/recruiter-positions/csv?org=${org_id}&_format=csv`, {
      headers: {
        'Accept': 'text/csv',
        'Content-Type': 'text/csv'
      },
      responseType: 'text'
    });
  }

  delete(id) {
    return this.http.delete(`${environment.apiUrl}/api/v2/mjs-positions/${id}`);
  }

  export(data: any) {
    let params = new HttpParams();

    for (let key in data) {
      if (data[key]) {
        params = params.append(key, data[key]);
      }
    }

    return this.http.get(`${environment.apiUrl}/api/v2/positions/export`, { params });
  }

  uploadCsv(data: any, org: any) {
    // /api/v2/mjs-positions-csv
    return this.http.post(`${environment.apiUrl}/mjs_positions_csv_rest?_format=json&org=${org}`, data, {
      // headers: {
      //   'Content-Type': 'application/octet-stream',
      //   'Upload-Content-Type': data.mimetype,
      //   'Content-Disposition': 'file; filename="' + data.filename + '"'
      // }
    });
  }

}
