<mjs-page-header [titlePrefix]="'Application for'" [content]="job_advert"
  *ngIf="job_advert">
</mjs-page-header>
<!-- <div class="rac-import-links-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-12" id="rac-import-links">
      </div>
    </div>
  </div>
</div> -->
<div class="rac-wrap">
  <nav class="rac-mobile-nav" [ngClass]="{ 'fixed-top-nav': fixedTopNav}">
    <button aria-controls="rac-nav"
      [attr.aria-expanded]="racMenuActive || 'false'" (click)="toggleRacMenu()"
      [ngClass]="{
          'toggle-menu': true,
          'active': racMenuActive || false
        }">
      <span role="presentation" class="mjs-menu-toggle">
        <i></i><i></i><i></i>
      </span>
      <span class="element-invisible">
        {{ racMenuActive ? 'Close' : 'Open' }} application menu
      </span>
    </button>
    <div id="rac-mobile-nav-wrap" [hidden]="!racMenuActive"></div>
  </nav>
  <div class="container">
    <div class="row">
      <div class="col col-12 rac-inner-wrap">
        <nav data-talentlink-apply-menu-container [ngClass]="{ 'fixed-nav': fixedNav, 'scroll-max': scrollMax }"></nav>
        <main data-talentlink-apply-root-container></main>
      </div>
    </div>
  </div>
</div>
<mjs-loader *ngIf="loading"></mjs-loader>

<!--
<nav data-talentlink-apply-menu-container></nav>
<main data-talentlink-apply-root-container></main> -->
