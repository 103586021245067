import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPayPalModule } from '@app/modules/ngx-paypal/ngx-paypal.module';
import { SharedModule } from '@app/shared/shared.module';
import { RecruiterRoutingModule } from './recruiter-routing.module';
import { DrupalEntityModule} from '@app/modules/drupal-entity/drupal-entity.module';
import { RecruiterComponent } from './recruiter.component';
import {
  CreateComponent,
  ClaimComponent,
  EditComponent,
  JobsComponent,
  UsersComponent,
  UserComponent,
  JobComponent,
  TokensComponent,
  BuyComponent,
  RecruiterHeaderComponent,
  RecruiterMenuComponent,
} from './components';
import { TokenComponent } from './components/token/token.component';
import { MjsOrdersComponent } from './components/mjs-orders/mjs-orders.component';
import { MjsJobTokensComponent } from './components/mjs-job-tokens/mjs-job-tokens.component';
import { MjsJobExtensionTokensComponent } from './components/mjs-job-extension-tokens/mjs-job-extension-tokens.component';
import { MjsOrderComponent } from './components/mjs-order/mjs-order.component';
import { MjsPurchaseComponent } from './components/mjs-purchase/mjs-purchase.component';
import { MjsPositionsComponent } from "@modules/recruiter/components/positions/mjs-positions.component";
import { MjsPositionComponent } from "@modules/recruiter/components/position/mjs-position.component";
import { UploadPositionsCSVDialogComponent } from './components/upload-positions-csvdialog/upload-positions-csvdialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { LazyLoadImageModule } from 'ng-lazyload-image';

/**
 * Recruiter Module
 */
@NgModule({
  declarations: [
    RecruiterComponent,
    CreateComponent,
    ClaimComponent,
    EditComponent,
    JobsComponent,
    UsersComponent,
    UserComponent,
    JobComponent,
    TokensComponent,
    BuyComponent,
    RecruiterHeaderComponent,
    RecruiterMenuComponent,
    TokenComponent,
    MjsPositionsComponent,
    MjsPositionComponent,

    MjsOrdersComponent,
    MjsJobTokensComponent,
    MjsJobExtensionTokensComponent,
    MjsOrderComponent,
    MjsPurchaseComponent,
    UploadPositionsCSVDialogComponent
  ],
  imports: [
    CommonModule,
    RecruiterRoutingModule,
    SharedModule,
    MatTableModule,
    MatMenuModule,
    NgxPayPalModule,
    ReactiveFormsModule,
    DrupalEntityModule,
    MatDialogModule,
    LazyLoadImageModule
  ]
})
export class RecruiterModule { }
