<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <ng-container *ngIf="content">
        <div [innerHtml]="content.body" *ngIf="content.body && !loading"></div>
      </ng-container>
      <ng-container *ngIf="renderForm">
        <mjs-entity-form
          [entity]="entityType"
          [bundle]="entityBundle"
          [entity_id]="entityId"
          [mode]="entityMode"
          [params]="params"
          (submitForm)="submit($event)"
        ></mjs-entity-form>
      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content">
      </mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
