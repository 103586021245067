import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class SearchesService {

  constructor(private http: HttpClient) {}

  get(page?: number) {
    return this.http.get(`${environment.apiUrl}/api/v1/searches${page ? '?page=' + (page - 1) : ''}`);
  }

  patch(data: any, id: string) {
    return this.http.patch(`${environment.apiUrl}/api/v1/saved_search?token=${id}`, {
      ...data
    })
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/v1/saved_search?token=${id}`);
  }

  post(data: any, email?: string) {
    return this.http.post(`${environment.apiUrl}/api/v1/saved_search/create${email ? '?email=' + email : ''}`, {
      ...data
    })
  }

}
