import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
  ViewChild,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivationService } from '@app/modules/user/services';
import { ProfileService } from '@app/services/profile.service';
import { ParseRedirectService } from '@app/services/parse-redirect.service';

@Component({
  selector: 'mjs-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [ActivationService, ParseRedirectService],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit, OnDestroy {
  
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  content: any;
  username: string;
  password: string;
  loading: boolean;
  id: number;
  timestamp: number;
  token: string;
  redirect: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  // Example of activation query parameters.
  // ?id=1&timestamp=1556841733&token=iafl5v4zlqJOVcFGi3OEaX80isGErF2GOihbEj0I_rk
  constructor(@Inject(PLATFORM_ID) private platform: any,
    private route: ActivatedRoute,
    private activate: ActivationService,
    private toast: ToastrService,
    private oauthService: OAuthService,
    private profileService: ProfileService,
    private renderer: Renderer2,
    private parseRedirect: ParseRedirectService) {
    this.loading = false;
  }

  public ngOnInit(): void {
    this.route.data.subscribe(res => {
      this.content = res['content'];
    });

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$))
      .subscribe((paramMap: ParamMap) => {
      const params = paramMap.keys;

      for (let i = 0; i < params.length; i += 1) {
        let key = params[i];
        const param = paramMap.get(key);

        switch (key) {
          case 'id':
          case 'timestamp':
          case 'token':
          case 'redirect':
          case 'action':
            this[key] = param;
            break;
          case 'message':

            break;
          default:

            break;
        }
      }

      if (this.id && this.timestamp && this.token && !this.loading && isPlatformBrowser(this.platform)) {
        this.loading = true;

        this.activate.post(this.id, this.timestamp, this.token)
          .pipe(takeUntil(this.onDestroy$)).subscribe(
          (data: any) => {
            this.toast.success('Your account has been activated, please login.');
            this.loading = false;
          },
          err => {
            this.loading = false;
            this.toast.error(err.error.message);
          }
        );
      }
    });
  }

  // If the user is successfully logged in they will be redirected
  // hence the abscence of any action here on success.
  submit = (params: any) => {
    this.renderer.selectRootElement('#main-content', true).scrollIntoView();
    this.loading = true;
    this.username = params.username;
    this.password = params.password;

    this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.username, this.password).then(() => {
      this.profileService.set();
      let {
        redirect,
        params
      } = this.parseRedirect.get(this.redirect, this.oauthService.getIdentityClaims());

      if (redirect.substring(0,6) === 'proxy/') {
        window.location.href = `${redirect.substring(6)}?token=${this.oauthService.getAccessToken()}`;
      } else {
        if (Object.keys(params).length > 0) {
          let queries = [];

          for (let key in params) {
            queries.push(`${key}=${params[key]}`);
          }

          redirect += `?${queries.join('&')}`;
        }

        window.location.href = redirect;
      } 
    })
    .catch(err => {
      if (err.error && err.error.message) {
        this.toast.error(err.error.message);
      } else {
        this.toast.error('Sorry, something went wrong');
      }
      this.loading = false;
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
