import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class OrganisationService {

  constructor(private http: HttpClient) {}

  post(data: any) {
    return this.http.post(`${environment.apiUrl}/api/v1/recruiter/organisation`, data);
  }

  patch(org, data) {
    return this.http.patch(`${environment.apiUrl}/api/v1/recruiter/organisation/${org}`, {
      ...data
    });
  }

  get(org, id) {
    return this.http.get(`${environment.apiUrl}/api/v1/recruiter/job?org=${org}&id=${id}`);
  }

  delete(org, id) {
    return this.http.delete(`${environment.apiUrl}/api/v1/recruiter/job?org=${org}&id=${id}`);
  }
  
}
