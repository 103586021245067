import { Pipe, PipeTransform } from '@angular/core';
import { MjsJob } from '@app/models/mjs-job.model';

@Pipe({
  name: 'mjsJobSalaryDisplay'
})
export class MjsJobSalaryDisplayPipe implements PipeTransform {

  transform(mjsJob: MjsJob, ...args: { length: number }[]): string {
    const {
      compensation_currency,
      compensation_maximum,
      compensation_minimum,
      compensation_override,
      compensation_period,
      compensation_post
    } = mjsJob;

    if (compensation_override) {
      if (!args?.[0]?.length) {
        return compensation_override;
      }

      return this.ellipsis(compensation_override, args[0]?.length || 1000);
    }

    let output = `${new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: compensation_currency
    }).format(parseFloat((compensation_minimum || '0').replace(',', ''))).replace(/(\.|,)00$/g, '')}`;

    if (parseFloat((compensation_minimum || '0').replace(',', '')) !== parseFloat((compensation_maximum || '0').replace(',', ''))) {
      output += ` - ${new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: compensation_currency
      }).format(parseFloat((compensation_maximum || '0').replace(',', ''))).replace(/(\.|,)00$/g, '')} per ${compensation_period.toLowerCase()}`;
    } else {
      output += ` per ${compensation_period}`;
    }

    if (compensation_post) {
      output += ` ${compensation_post}`;
    }

    if (!args?.[0]?.length) {
      return output.toLowerCase();
    }

    return this.ellipsis(output.toLowerCase(), args?.[0].length || 40);
  }

  ellipsis(input: string, length: number = 40): string {
    if (input.length >= length) {
      return `${input.substring(0, length)}...`;
    }

    return input;
  }

}
