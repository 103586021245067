import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class RecruiterGuard implements CanActivate {

  constructor(private oauthService: OAuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: string) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformServer(this.platform)) {
      return true;
    }

    let hasAccessToken = this.oauthService.hasValidAccessToken();
    let claims: any = this.oauthService.getIdentityClaims();

    if (hasAccessToken) {
      return this.checkRoles(claims, state);
    } else {
      let refreshToken = this.oauthService.getRefreshToken();

      if (refreshToken) {
        return this.oauthService.refreshToken().then(res => {
          return true;
        })
        .catch(err => {
          return this.redirect(state);
        })
      }

      return this.redirect(state);
    }
  }

  checkRoles = (claims: any, state: any) => {
    if (claims.roles.indexOf('organisation') > -1
      || claims.roles.indexOf('agency') > -1
      || claims.roles.indexOf('admin') > -1) {
      return true;
    } else {
      return this.redirect(state);
    }
  }

  refresh = (state: any) => {
    let refreshToken = this.oauthService.getRefreshToken();

    if (refreshToken) {
      return this.oauthService.refreshToken().then(res => {
        return this.checkRoles(this.oauthService.getIdentityClaims(), state);
      })
      .catch(err => {
        return this.redirect(state);
      })
    }

    return this.redirect(state);
  }

  redirect = (state: any) => this.router.navigate(['/user/login'], {
    queryParams: {
      redirect: state.url
    }
  })

}
