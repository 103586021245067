import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RecruiterService } from './recruiter.service';

@Injectable()
export class RecruiterResolver implements Resolve<any> {

  constructor(private content: RecruiterService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    let url = '';

    if (rstate.url.indexOf('%3F') > -1) {
      url = rstate.url.split('%3F')[0];
    } else {
      url = rstate.url.split('?')[0];
    }

    return this.content.get(url);
  }

}
