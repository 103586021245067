<mjs-recruiter-header
  *ngIf="content"
  [content]="content">
</mjs-recruiter-header>
<div class="tab-navigation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul *ngIf="this.content && this.content.org">
          <li>
            <a
              [routerLink]="this.content.org.path + '/positions'"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              Positions
            </a>
          </li>
          <li>
            <a

              [routerLink]="this.content.org.path + '/positions/add'"
              routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              Add Position
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <main class="col">
      <mjs-loader *ngIf="loading"></mjs-loader>
      <ng-container *ngIf="!loading">
        <div class="d-block">
          <button class="btn btn-sm btn-outline-primary mb-3 float-left"
            (click)="uploadCsv()">
            Upload CSV
          </button>
          <button *ngIf="data && data.list.length > 0"
            (click)="csvLink()"
            class="btn btn-sm btn-outline-primary mb-3 float-right">
            Download as CSV
          </button>
        </div>
        <div [innerHtml]="content.body" *ngIf="content && content.body"></div>
        <form [formGroup]="formGroup" (submit)="submit()">
          <div class="row no-gutters">
            <div class="col col-8 col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold" for="keywords">Keywords</label>
                <input class="form-control" id="keywords" type="text" formControlName="keywords">
              </div>
            </div>
            <div class="col">
              <div class="form-group ml-2">
                <label class="d-block">&nbsp;</label>
                <button class="btn btn-primary btn-sm mt-1">Search</button>
              </div>
            </div>
          </div>
        </form>
        <p *ngIf="!data || data.list.length === 0">No jobs to display yet. If you have just posted a job and it isn't listed, please refresh the page or wait a couple of minutes.</p>
        <div class="sticky-table-container" *ngIf="data && data.list.length > 0">
          <div class="table-container">
            <table mat-table [dataSource]="data.list">

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> <span [innerHtml]="element.id"></span> </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let element"> <a [routerLink]="element.path"> <span [innerHtml]="element.title"></span> </a> </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Published </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.status ? 'Yes' : 'No' }} </td>
              </ng-container>

              <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Closing Date </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.end_date }} </td>
              </ng-container>

              <ng-container matColumnDef="area">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Area </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.area }} </td>
              </ng-container>

              <ng-container matColumnDef="postcode">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Postcode </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.postcode }} </td>
              </ng-container>

              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Rate </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.rate }} </td>
              </ng-container>

              <ng-container matColumnDef="hours">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Hours </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.hours }} </td>
              </ng-container>

              <ng-container matColumnDef="clicks">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Views </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{ element.clicks }} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>

            </table>
          </div>
          <div class="sticky-actions">
            <div class="btn-group" *ngFor="let element of data.list">
              <button
                [matMenuTriggerFor]="menu"
                class="btn btn-outline-primary btn-sm dropdown-toggle"
                type="button"
                aria-haspopup="true"
                [attr.aria-expanded]="element.active || 'false'">
                Actions
              </button>
              <mat-menu yPosition="above" class="dropdown-menu" #menu="matMenu">
                <a mat-menu-item
                  [routerLink]="content.org.path + '/positions/' + element.id">
                  Edit
                </a>
              </mat-menu>
            </div>
          </div>
        </div>
        <mjs-pager
          *ngIf="data && data.pages > 1"
          [page]="data.page"
          [pages]="data.pages"
          [total]="data.total">
        </mjs-pager>
      </ng-container>
    </main>
    <div class="col sidebar">
      <mjs-recruiter-menu
        [profile]="content"
      ></mjs-recruiter-menu>
      <mjs-advert [type]="'doublempu'"></mjs-advert>
    </div>
  </div>
</div>
